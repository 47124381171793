import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import {ROUTE_TICKETS} from '../../constants/routes';
import Header from '../../components/Header';
import TicketDetail from '../../components/TicketDetail';

class TicketEdit extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      match,
      user,
      token,
    } = props;

    this.state = {
      history,
      match,
      user,
      token,
    };

    this.authService = new AuthService();
  };

  render() {
    const { token, history, match, user } = this.state;

    return (
      <React.Fragment>
        <Header name='Consultas' from='ticket' user={user} token={token} />
        <div className='app-messages'>
          <div className='container container-mini'>
            <div className='app-back'>
              <a href={ROUTE_TICKETS} className='app-back__link'>
                <i className='icon-arrow'>{''}</i>
                <p>Volver atrás</p>
              </a>
            </div>
            <TicketDetail history={history} match={match} token={token} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TicketEdit.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  TicketEdit,
  ['gf_plan_pro']
);
