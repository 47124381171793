import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ROUTE_HOME,
  ROUTE_RECOVER_PASSWORD,
} from '../../constants/routes';
import AuthService from '../../services/AuthService';

class Login extends Component {
  constructor(props) {
    super(props);

    const { history } = this.props;

    this.state = {
      history,
      email: '',
      password: '',
      send: false,
      error: false,
      display: false,
    };

    this.authService = new AuthService();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    const { history, email, password } = this.state;

    if (email === '' || password === '') {
      return;
    }

    this.setState({
      send: true,
      error: false,
    });

    this.authService.login(email, password)
      .then(() => {
        history.replace(ROUTE_HOME);
      })
      .catch(() => {
        setTimeout(() => {
          this.setState({
            send: false,
            error: true,
          });
        }, 1000);
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handleClickDisplay = (e) => {
    e.preventDefault();
    const { display } = this.state;

    this.setState({
      display: display === false,
    });
  }

  componentDidMount = () => {
    if (this.authService.isLoggedIn() === true) {
      const { history } = this.state;
      history.replace(ROUTE_HOME);
    }
  }

  render = () => {
    const web = process.env.REACT_APP_URL_WORDPRESS;
    const { send, error, display } = this.state;

    return (
      <div className='app-login'>
        <div className='container'>
          <div className='app-login__info'>
            <div className='app-login__logo'>
              <img src='/assets/img/logo-horizontal.svg' alt='GFIT Coach APP'/>
            </div>
            <div className='app-login__form'>
              { error === true ?
                <div className='app-login__form-message error'>
                  <p className='strong'>Usuario o contraseña invalida</p>
                  <p>Ups! El usuario y/o contraseña no es correcta. Vuelve a intentarlo o revisa el estado de tu suscripción y método de pago.</p>
                </div>
                :
                ''
              }
              { send === true ?
                <div className='app-login__form-message'>
                  <p className='strong'>Autenticando</p>
                  <p>Iniciando sesión en GFit Coach... Por favor, espere.</p>
                </div>
                :
                ''
              }
              <form onSubmit={this.handleFormSubmit}>
                <div className='input__icon'>
                  <input
                    className='form-control'
                    placeholder='Email'
                    name='email'
                    type='text'
                    onChange={this.handleChange}
                  />
                  <i className='icon-user'>{''}</i>
                </div>
                <div className='input__icon psw'>
                  <input
                    className='form-control'
                    placeholder='Contraseña'
                    type={display === true ? 'text' : 'password'}
                    name='password'
                    onChange={this.handleChange}
                  />
                  <i className='icon-lock'>{''}</i>
                  <i className={`icon-not-see${display === true ? ' open' : ''}`}
                     onClick={this.handleClickDisplay}
                  >
                    {''}
                  </i>
                </div>
                <Link
                  to={{
                    pathname: ROUTE_RECOVER_PASSWORD,
                    state: {from: window.location.pathname}}
                  }
                  className='a app-login__form-forgot'
                >
                  He olvidado mi contraseña
                </Link>
                <input
                  type='submit'
                  value='Iniciar sesión'
                  className='app-login__form-login'
                  disabled={send}
                />
                <a
                  href={web}
                  className='app-login__form-new btn btn-outline'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Registrarse
                </a>
              </form>
            </div>
          </div>
          <div className='shadow'>{''}</div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
};

export default Login;
