import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ROUTE_SESSION} from '../../../constants/routes';
import Media from '../Media';

class ContentFinished extends Component {
  constructor(props) {
    super(props);

    const {
      number,
      session,
      trainingSession,
    } = props;

    this.state = {
      number,
      session,
      trainingSession
    };
  }

  printSeries = () => {
    const { trainingSession } = this.state;
    const { series } = trainingSession;
    let print = [];

    for (let i in series) {
      if (series.hasOwnProperty(i)) {
        const { id, repetitions } = series[i];
        const number = parseInt(i) + 1;

        print.push(
          <div className='app-exercise__userform-input' key={id}>
            <p>Número de repeticiones que has hecho en la serie {number}</p>
            <div className='input-label'>
              <input type='number' defaultValue={repetitions}/>
              <label>Repeticiones serie {number}</label>
              <i className='icon-mark-full'>{''}</i>
            </div>
          </div>
        );
      }
    }

    return print;
  }

  printRealWeight() {
    const { trainingSession } = this.state;
    const { realWeight } = trainingSession;

    return (
      <div  className='
        app-exercise__userform
        app-exercise__userform--isolated
        app-exercise__userform--isolated--top
      '>
        <div key="realWeight" className={`app-exercise__userform-input${ realWeight === 0 ? '--hidden' : '' }`}>
          <p>Carga real con la que has realizado el ejercicio</p>
          <div className='input-label'>
            <input type='text'
                   defaultValue={realWeight}
                   className='readonly'
            />
            <label>Carga real</label>
            <i className='icon-mark-full'>{''}</i>
          </div>
        </div>
      </div>
    );
  }

  printComment = () => {
    const { trainingSession } = this.state;
    const { comment } = trainingSession;

    return (
      <div  className='
        app-exercise__userform
        app-exercise__userform--isolated
        app-exercise__userform--isolated--bottom
      '>
        <div key="comment" className='app-exercise__userform-input '>
          <p>Comentario</p>
          <div className='input-label'>
            <textarea id="comment"
                      maxLength="500" className='readonly'
                      value={comment !== ''? comment : ' ... '}
                      readOnly={true}
            />
            <label className="special">Tu comentario sobre el ejercicio</label>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { number, session, trainingSession } = this.state;

    // Session assets
    const sessionId = session.id;

    // Training session assets
    const trainingSessionRating = trainingSession.rating;
    const trainingSessionWeight = trainingSession.weight;
    const trainingSessionSeriesCount = trainingSession.series.length;

    // Movement assets
    const { movement } = trainingSession;
    const movementName = movement.name;

    // Practice assets
    const { practice } = trainingSession;
    const practiceName = practice.name;
    const practiceImage = practice.image;
    const practiceVideo = practice.video;

    // Maximum repetitions
    const maximumRepetition = practice.maximum_repetition;
    const maximumRepetitionValue = maximumRepetition.value;

    return (
      <div className='app-exercise'>
        <div className='app-exercise__intro'>
          <div className='container-app container-app-medium'>
            <div className='app-back'>
              <a href={`${ROUTE_SESSION}/${sessionId}`} className='app-back__link'>
                <i className='icon-arrow'>{''}</i>
                <p>Volver atrás</p>
              </a>
            </div>
            <div className='app-exercise__nav'>
              <p>Entrenamiento</p>
              <a href={`${ROUTE_SESSION}/${sessionId}`}>Ver ejercicio anterior</a>
            </div>
            <div className='app-exercise__title'>
              <h2 className='title-condensed'>{number} {movementName}</h2>
            </div>
          </div>
        </div>
        <div className='container-app container-app-medium'>
          <div className='app-exercise__form completed'>
            <div className='app-exercise__form-inputs'>
              <div className='app-exercise__form-input selected'>
                <p>{practiceName}</p>
              </div>
              <div className='app-exercise__form-input selected'>
                <p>Feedback</p>
                <p className='number'>{trainingSessionRating}</p>
              </div>
              <div className='app-exercise__form-input selected'>
                <p>10RM</p>
                <p className='number'>{maximumRepetitionValue}</p>
              </div>
            </div>
            <Media image={practiceImage} video={practiceVideo} name={practiceName} />
            <div className='app-exercise__indications'>
              <div className='app-exercise__indications-serie'>
                <p className='type'>Series realizadas</p>
                <p className='number'>{trainingSessionSeriesCount}</p>
              </div>
              <div className='app-exercise__indications-weight'>
                <p className='type'>Carga</p>
                <p className='number'>{trainingSessionWeight === 0 ? '--' : trainingSessionWeight}</p>
              </div>
            </div>
          </div>
          <form className='app-exercise__userform'>
            { this.printRealWeight() }
            { this.printSeries() }
            { this.printComment() }
          </form>
        </div>
      </div>
    );
  }
}

ContentFinished.propTypes = {
  number: PropTypes.string.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    machine_name: PropTypes.string.isRequired,
    number_week: PropTypes.number.isRequired,
    number_day: PropTypes.number.isRequired,
    training_cycle: PropTypes.number.isRequired,
    training_cycle_day: PropTypes.number.isRequired,
    is_relax: PropTypes.bool.isRequired,
    is_finished: PropTypes.bool.isRequired,
  }).isRequired,
  trainingSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
    series: PropTypes.array.isRequired,
    practice: PropTypes.shape({
      id: PropTypes.string.isRequired,
      machine_name: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string,
      video: PropTypes.string,
      maximum_repetition: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired
    }).isRequired,
    movement: PropTypes.shape({
      id: PropTypes.string.isRequired,
      machine_name: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ContentFinished;
