import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {ROUTE_LOGIN} from '../../constants/routes';
import {printMessage} from '../../constants/common';

class LoginRecoverPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      send: false,
      success: false,
      error: false,
      isDisabled: true,
      email: '',
      title: '',
      message: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isEmailValid = (str) => {
    const re = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(str);
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      isDisabled: this.isEmailValid(value.toLowerCase()) === false,
      [name]: value.toLowerCase(),
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;

    if (this.isEmailValid(email) === false) {
      return;
    }

    this.setState({
      send: true,
      success: false,
      error: false,
      isDisabled: true,
      title: 'Enviando solicitud',
      message: 'Estamos procesando su petición... Por favor, espere.',
    });

    const url = process.env.REACT_APP_URL;
    let config = {
      method: 'post',
      url: `${url}/recover-password/`,
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        email
      },
    };

    axios(config)
      .then(response => {
        if (response.status === 201) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(() => {
        this.setState({
          send: false,
          success: true,
          error: false,
          isDisabled: true,
          email: '',
          title: 'Por favor, revise su correo electrónico',
          message: `Hemos enviado un correo electrónico a ${email}, siga las instrucciones para restablecer su contraseña.`
        });
      }).catch(() => {
        setTimeout(() => {
          this.setState({
            send: false,
            success: false,
            error: true,
            isDisabled: true,
            email: '',
            title: 'Ups! Algo no ha ido bien',
            message: 'Ha ocurrido algo inesperado. ¡Vuelva a intentarlo!',
          });
        }, 1000);
      });
  }

  render = () => {
    const {
      send,
      success,
      error,
      email,
      isDisabled,
      title,
      message
    } = this.state;

    return (
      <div className='app-login'>
        <div className='container'>
          <div className='app-login__info'>
            <div className='app-login__logo'>
              <img src='/assets/img/logo-horizontal.svg' alt='GFit Coach Logo' />
            </div>
            <div className='app-login__form'>
              {printMessage(send, success, error, title, message)}
              <form onSubmit={this.handleSubmit}>
                <p>Añade tu correo electrónico y le enviaremos un enlace para restablecer su contraseña.</p>
                <div className='input__icon'>
                  <input type='text'
                         name='email'
                         placeholder='Email'
                         value={email}
                         onChange={this.handleChange}
                  />
                  <i className='icon-user'>{''}</i>
                </div>
                <input
                  type='submit'
                  value='Restaurar contraseña'
                  className='app-login__form-login'
                  disabled={isDisabled}
                />
                <Link
                  to={{
                    pathname: ROUTE_LOGIN,
                    state: {from: window.location.pathname}
                  }}
                  className='a app-login__form-forgot'
                >
                  Iniciar sesión
                </Link>
              </form>
            </div>
          </div>
          <div className='shadow'>{''}</div>
        </div>
      </div>
    );
  }
}

export default LoginRecoverPassword;
