import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Graph from '../../components/Graph';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

class StatisticsWeight extends Component {

  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      data: '',
      options: '',
      start_date: '',
      end_date: '',
      weight: '',
      firstData: false,
      firstLoad: true,
      state: false,
      loading: true,
    };

    this.insertWeight =this.insertWeight.bind(this);
    this.onChangeWeight =this.onChangeWeight.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.authService = new AuthService();

  };

  render() {
    const { loading } = this.state;

    return loading === true ? this.renderProgress() : this.renderComponent();
  }
  componentDidMount(){
    this.getWeights();
  }

  renderProgress = () => {
    const { user, token } = this.state;

    return (
      <React.Fragment>
      <Header name='Evolución del peso con IMC' from='statistics' user={user} token={token} />
      <Menu from='statistics' user={user} />
      <div className='app-statistics'>
        <div className='app-statistic__intro'>
          <div className='container-app container-app-medium'>
            <div className='app-back'>
              <Link to='/statistics' className='app-back__link'>
                <i className='icon-arrow'></i>
                <p>Volver atrás</p>
              </Link>
            </div>

            <div className='app-statistic__intro-title'>
              <h2 className='title-condensed'>Evolución del peso con imc</h2>
              <p>En este apartado llevarás un control de tu peso e IMC corporal. Para ello, es muy importante que todos los días introduzcas tu peso corporal. El IMC se calculará gracias a la unión entre perímetros y peso corporal, por lo que es muy importante que introduzcas correctamente todos los datos y en la frecuencia recomendada.</p>
            </div>
          </div>
        </div>

        <div className='container-app container-app-medium'>
            <div className='app-statistic__filter'>
              <div className='app-statistic__filter-item'>
                <p>Fecha Inicio</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>
              <div className='app-statistic__filter-item'>
                <p>Fecha Fin</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>

            </div>

            <div className='placeholder__graphic'>
              <CircularProgress className='loading__progress' />
            </div>
          </div>
      </div>
      </React.Fragment>
    );
  };

  renderComponent = () => {
    const { user, token } = this.state;

    return (
      <React.Fragment>
      <Header name='Evolución del peso con IMC' from='statistics' user={user} token={token} />
      <Menu from='statistics' user={user} />
      <div className='app-statistics'>
        <div className='app-statistic__intro'>
          <div className='container-app container-app-medium'>
            <div className='app-back'>
              <Link to='/statistics' className='app-back__link'>
                <i className='icon-arrow'></i>
                <p>Volver atrás</p>
              </Link>
            </div>

            <div className='app-statistic__intro-title'>
              <h2 className='title-condensed'>Evolución del peso con imc</h2>
              <p>En este apartado llevarás un control de tu peso e IMC corporal. Para ello, es muy importante que todos los días introduzcas tu peso corporal. El IMC se calculará gracias a la unión entre perímetros y peso corporal, por lo que es muy importante que introduzcas correctamente todos los datos y en la frecuencia recomendada.</p>
            </div>
          </div>
        </div>

        <div className='container-app container-app-medium'>
          {(this.state.firstData === true)?
            <React.Fragment>
            <div className='app-statistic__filter'>
              <div className='app-statistic__filter-item'>
                <p>Fecha Inicio</p>
                <div className='input-black'>
                  <input type='date' name='start_date' id='' onChange={this.handleFilter} />
                </div>
              </div>
              <div className='app-statistic__filter-item'>
                <p>Fecha Fin</p>
                <div className='input-black'>
                  <input type='date' name='end_date' id='' onChange={this.handleFilter} />
                </div>
              </div>
            </div>

            <div className='app-statistic__graphic'>
              {(this.state.data !== '' && this.state.state === true)?
                <Graph data={this.state.data} options={this.state.options}/>
              :
              <div className='placeholder__empty full'>
                <p className='placeholder__empty-pretitle'>Oops!</p>
                <p className='placeholder__empty-title'>La búsqueda que has realizado no se puede visualizar debido a una falta de datos.</p>
                <p className='placeholder__empty-text'>¡Prueba a seleccionar unas <span> fechas diferentes!</span></p>
              </div>
              }
            </div>
            </React.Fragment>
          :
          <div className='placeholder__empty full'>
            <p className='placeholder__empty-pretitle'>Oops!</p>
            <p className='placeholder__empty-title'> Todavía o hay contenido disponible en esta gráfica</p>
            <p className='placeholder__empty-text'>Conforme vayas subiendo información podrás visualizar aquí la evolución de tu peso a lo largo del entrenamiento.</p>
          </div>
          }

          <div className='app-statistic__form'>
            <form onSubmit={this.insertWeight}>
              <div className='app-statistic__form-item'>
                <div className='app-statistic__form-title'>
                  <div className='title-section'>
                    <h2 className='title-caps'><i className='icon-weight'></i> Introducir peso</h2>
                    <span className='line'></span>
                  </div>
                  <p>Para registrar el progreso desde que comienzas a entrenar, has de introducir diariamente tu peso corporal.</p>
                </div>
                <div className='app-statistic__form-input'>
                  <div className='input-label'>
                    <input type='float' id='field' name='weight' value={this.state.weight} onChange={this.onChangeWeight} required/>
                    <label id='label'>Peso corporal </label>
                    <i className='icon-weight-full'></i>
                  </div>
                </div>
                <div className='app-statistic__form-btn input-50'>
                  <input type='submit' className='' value='Enviar datos'></input>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }

  insertWeight = (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_URL_API;
    const { token, user } = this.state;
    const { username } = user;

    const formData = new FormData();
    formData.append('weight',this.state.weight);
    formData.append('username',username);

    axios.post(url+'/insert_user_weight',formData,{
      headers: {
        Authorization: 'Bearer ' + token,
        'content-type': 'multipart/form-data'
      }
    })
    .then((res) => {
      if(res.status === 200){
        this.setState({
          weight: '',
        })
        this.getWeights();
      }else{
        console.error('ha ocurrido un error');
      }
    }, error => {
      console.error(error);
    });
  }

  onChangeWeight = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  getWeights = (data) =>{
    const url = process.env.REACT_APP_URL_API;
    const { user, token } = this.state;
    const { username } = user;

    let days = [];
    let results = [];
    let start_date = '';
    let end_date = '';

    if(data && data.start_date !== undefined && data.start_date !== ''){
      start_date = data.start_date;
    }else if(this.state.start_date !== undefined && this.state.start_date !== ''){
      start_date = this.state.start_date;
    }else{
      start_date = 'no';
    }

    if(data && data.end_date !== undefined && data.end_date !== ''){
      end_date = data.end_date;
    }else if(this.state.end_date !== undefined && this.state.end_date !== ''){
      end_date = this.state.end_date;
    }else{
      end_date = 'no';
    }

    axios.get(url+'/get_weights/u/'+username+'/sd/'+start_date+'/ed/'+end_date,{
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((response) => {
      if(response.status === 200){
        for (let key in response.data.weights) {
          days.push(key);
          results.push(response.data.weights[key][0]);
        }

        this.setState({
          data: {
            labels: days,
            datasets: [
              {
                label: 'Peso',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'green',
                borderColor: 'green',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'green',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'green',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: results
              }
            ]
          },
          options: {
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Peso',
                }
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Días'
                }
              }]
            }
          },
          state: (response.data.state === 'no_data') ? false : true,
          firstData: (this.state.firstLoad === true && response.data.state === 'no_data')? false : true,
          firstLoad: false,
          loading: false
        })
      }else{
        this.setState({
          data: '',
          options: '',
          state: false,
          firstData: (this.state.firstLoad === true)? false : true,
          firstLoad: false,
          loading: false,
        })
      }
    }, error => {
      console.error(error);
    });
  }

  handleFilter = (event) => {
    event.preventDefault();
    let value = '';
    if(event.target.name === 'start_date' || event.target.name === 'end_date'){
      if(event.target.value === ''){
        value = 'no';
      }else{
        value = event.target.value;
      }
    }else{
      value = event.target.value;
    }
    const data = {[event.target.name]: value};
    this.setState({
      [event.target.name]: value,
    })
    this.getWeights(data);
  }
}

StatisticsWeight.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  StatisticsWeight,
  ['gf_plan_premium', 'gf_plan_pro']
);
