import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  ROUTE_HOME,
  ROUTE_MY_ACCOUNT,
  ROUTE_NOTIFICATIONS,
  ROUTE_TICKETS,
} from '../../constants/routes';
import NotificationNotice from '../NotificationNotice';

class Header extends Component {
  constructor(props) {
    super(props);

    const {
      name,
      from,
      user,
      token,
    } = props;

    this.state = {
      name,
      from,
      user,
      token,
    };
  }

  render() {
    const { name, from, user, token } = this.state;
    const { p } = user;

    return (
      <div className='header-app'>
        <div className='container'>
          <div className='header-app__flex'>
            <div className='header-app__logo'>
              <a title='Inicio' href={ROUTE_HOME}>
                <picture>
                  <source media='(min-width: 992px)' srcSet='/assets/img/logo-horizontal.svg'/>
                  <img src='/assets/img/logo-movil.svg' alt='GFit Coach' />
                </picture>
              </a>
            </div>
            <div className='header-app__page'>
              <p>{ name }</p>
            </div>
            <div className='header-app__nav'>
              {(() => {
                if (from === 'home' || from === 'withyou' || from === 'ticket' || from === 'notification' || from === 'macrocycle') {
                  return (
                    <a
                      title='Mi cuenta'
                      href={ROUTE_MY_ACCOUNT}
                    >
                      <i className='icon-settings'>{''}</i>
                      <span>Mi Cuenta</span>
                    </a>
                  );
                }
              })()}
              <React.Fragment>
                <a
                  title='Notificaciones'
                  href={ROUTE_NOTIFICATIONS}
                  className={from === 'notification' ? 'active' : ''}
                >
                  <div className='notice'>
                    <i className='icon-notification'>{''}</i>
                    <NotificationNotice token={token} />
                  </div>
                  <span>Notificaciones</span>
                </a>
              </React.Fragment>
              {
                p === 'gf_plan_pro' ?
                  <React.Fragment>
                    <a title='Consultas'
                       href={ROUTE_TICKETS}
                       className={from === 'ticket' ? 'active' : ''}
                    >
                      <i className='icon-chat'>{''}</i>
                      <span>Consultas</span>
                    </a>
                  </React.Fragment>
                  :
                  ''
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  name: PropTypes.string.isRequired,
  from: PropTypes.string,
  user: PropTypes.shape({
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default Header;
