import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  ROUTE_HOME,
  ROUTE_ACADEMY,
  ROUTE_ACADEMY_POST,
  ROUTE_FEEDING,
  ROUTE_LOGIN,
  ROUTE_RECOVER_PASSWORD,
  ROUTE_RESTORE_PASSWORD,
  ROUTE_MACROCYCLE,
  ROUTE_MAXIMUM_REPETITIONS_10,
  ROUTE_MY_ACCOUNT,
  ROUTE_NOTIFICATIONS,
  ROUTE_RM_CALCULATOR,
  ROUTE_SESSION,
  ROUTE_STATISTICS,
  ROUTE_STATISTICS_BRAND,
  ROUTE_STATISTICS_EFFORT_CONTROL,
  ROUTE_STATISTICS_PERIMETER,
  ROUTE_STATISTICS_VOLUME_CONTROL,
  ROUTE_STATISTICS_WEIGHT,
  ROUTE_TICKETS,
  ROUTE_TICKETS_EDIT,
  ROUTE_TICKETS_NEW,
  ROUTE_TRAINING,
  ROUTE_WITH_YOU,
} from './constants/routes';
import Academy from './pages/Academy';
import AcademyPost from './pages/AcademyPost';
import Feeding from './pages/Feeding';
import Home from './pages/Home';
import Login from './pages/Login';
import LoginRecoverPassword from './pages/LoginRecoverPassword';
import LoginRestorePassword from './pages/LoginRestorePassword';
import Macrocycle from './pages/Macrocycle';
import MaximumRepetitions10 from './pages/MaximumRepetitions10';
import MyAccount from './pages/MyAccount';
import NotFound from './pages/NotFound';
import Notification from './pages/Notification';
import RMCalculator from './pages/RMCalculator';
import Session from './pages/Session';
import Statistics from './pages/Statistics';
import StatisticsBrand from './pages/StatisticsBrand';
import StatisticsEffortControl from './pages/StatisticsEffortControl';
import StatisticsPerimeter from './pages/StatisticsPerimeter';
import StatisticsVolumeControl from './pages/StatisticsVolumeControl';
import StatisticsWeight from './pages/StatisticsWeight';
import TicketEdit from './pages/TicketEdit';
import TicketNew from './pages/TicketNew';
import Ticket from './pages/Ticket';
import Training from './pages/Training';
import TrainingDetail from './pages/TrainingDetail';
import WithYou from './pages/WithYou';

class App extends Component
{
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={ROUTE_HOME} component={Home} />
          <Route exact path={ROUTE_ACADEMY} component={Academy} />
          <Route exact path={`${ROUTE_ACADEMY_POST}/:slugPost`} component={AcademyPost} />
          <Route exact path={ROUTE_FEEDING} component={Feeding} />
          <Route exact path={ROUTE_LOGIN} component={Login} />
          <Route exact path={ROUTE_RECOVER_PASSWORD} component={LoginRecoverPassword} />
          <Route exact path={`${ROUTE_RESTORE_PASSWORD}/:hash/e/:email`} component={LoginRestorePassword} />
          <Route exact path={ROUTE_MACROCYCLE} component={Macrocycle} />
          <Route exact path={ROUTE_MAXIMUM_REPETITIONS_10} component={MaximumRepetitions10} />
          <Route exact path={ROUTE_MY_ACCOUNT} component={MyAccount} />
          <Route exact path={ROUTE_NOTIFICATIONS} component={Notification} />
          <Route exact path={ROUTE_RM_CALCULATOR} component={RMCalculator} />
          <Route exact path={`${ROUTE_SESSION}/:id`} component={Session} />
          <Route exact path={ROUTE_STATISTICS} component={Statistics} />
          <Route exact path={ROUTE_STATISTICS_BRAND} component={StatisticsBrand} />
          <Route exact path={ROUTE_STATISTICS_EFFORT_CONTROL} component={StatisticsEffortControl} />
          <Route exact path={ROUTE_STATISTICS_PERIMETER} component={StatisticsPerimeter} />
          <Route exact path={ROUTE_STATISTICS_VOLUME_CONTROL} component={StatisticsVolumeControl} />
          <Route exact path={ROUTE_STATISTICS_WEIGHT} component={StatisticsWeight} />
          <Route exact path={ROUTE_TICKETS} component={Ticket} />
          <Route exact path={`${ROUTE_TICKETS_EDIT}/:id`} component={TicketEdit} />
          <Route exact path={ROUTE_TICKETS_NEW} component={TicketNew} />
          <Route exact path={ROUTE_TRAINING} component={Training} />
          <Route exact path={`/n/:n${ROUTE_TRAINING}/:tid${ROUTE_SESSION}/:sid`} component={TrainingDetail} />
          <Route exact path={ROUTE_WITH_YOU} component={WithYou} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
