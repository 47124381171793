import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import TrainingSession from '../../components/TrainingSession';

class TrainingDetail extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      match,
      user,
      token,
    } = props;

    this.state = {
      history,
      match,
      user,
      token,
    };

    this.authService = new AuthService();
  }

  render() {
    const { token, history, match, user } = this.state;

    return (
      <React.Fragment>
        <Header name='Entrenamiento' from='training' user={user} token={token} />
        <Menu from='training' user={user} />
        <TrainingSession token={token} history={history} match={match} />
      </React.Fragment>
    );
  }
}

TrainingDetail.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      n: PropTypes.string.isRequired,
      tid: PropTypes.string.isRequired,
      sid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  TrainingDetail,
  ['gf_plan_premium', 'gf_plan_pro']
);
