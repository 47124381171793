import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  ROUTE_SESSION,
  ROUTE_MAXIMUM_REPETITIONS_10
} from '../../../constants/routes';

class Content extends Component {
  constructor(props) {
    super(props);

    const {
      isHome,
      mesocycle,
      name,
      week,
      weeks,
      to,
      isRelax,
      isFinished,
    } = props;

    this.state = {
      isHome,
      mesocycle,
      name,
      week,
      weeks,
      to,
      isRelax,
      isFinished,
    };
  }

  componentDidMount() {
    const { week, weeks } = this.state;
    const calc = week * 100 / weeks;
    let progressBar = document.getElementById('progressBar');
    progressBar.style.width = `${calc}%`;
  }

  printLink = () => {
    const {
      isHome,
      to,
      isRelax,
      isFinished,
    } = this.state;

    if ((isHome === false && isRelax === true) || isFinished === true) {
      return;
    }

    if (isHome === true && isRelax === true) {
      return (
        <a href={`/training`} className='btn progress-training__btn'>Entrenar</a>
      );
    }

    return (
      <a href={`${ROUTE_SESSION}/${to}`} className='btn progress-training__btn'>Entrenar</a>
    );
  }

  render() {
    const {
      mesocycle,
      name,
      week,
      weeks,
    } = this.state;

    return (
      <div className='progress-training'>
        <p className='title-caps'>Tu próximo entrenamiento</p>
        <p className='progress-training__next'>{ name }</p>
        <div className='progress-training__card'>
          <div className='progress-training__card-title'>
            <p className='progress-training__card-title-p'>Macrociclo</p>
            <div className='progress-training__card-title-current'>
              <p className={mesocycle === 1 ? 'active' : ''}>1</p>
              <span>/</span>
              <p className={mesocycle === 2 ? 'active' : ''}>2</p>
              <span>/</span>
              <p className={mesocycle === 3 ? 'active' : ''}>3</p>
              <span>/</span>
              <p className={mesocycle === 4 ? 'active' : ''}>4</p>
            </div>
          </div>
          <div className='progress-training__card-info'>
            <p className='progress-training__card-info-current'>
              <span>{mesocycle}&deg;</span> Mesociclo</p>
            <div className='progress'>
              <div id='progressBar' className='progress-bar' role='progressbar'>{''}</div>
            </div>
            <div className='progress-training__card-info-weeks'>
              <p>({week} semanas)</p>
              <p>{weeks}</p>
            </div>
          </div>
        </div>
        { this.printLink() }
        <a href={ROUTE_MAXIMUM_REPETITIONS_10} title='Modifica tu 10 RM' className='progress-training__link'>Modifica tu 10 RM</a>
      </div>
    );
  }
}

Content.propTypes = {
  isHome: PropTypes.bool.isRequired,
  mesocycle: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  week: PropTypes.number.isRequired,
  weeks: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
  isRelax: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
};

export default Content;
