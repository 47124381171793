import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {ROUTE_TICKETS} from '../../constants/routes';

class TicketForm extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      token,
      id,
      update,
    } = props;

    this.state = {
      history,
      token,
      id: typeof id === 'undefined' ? null : id,
      update,
      title: '',
      message: '',
      link: '',
      image: null,
      imageName: '',
      imageSize: '',
      video: null,
      videoName: '',
      videoSize: '',
      send: false,
      error: false,
      errorText: '',
      errorTitle: false,
      errorTitleText: '',
      errorMessage: false,
      errorMessageText: '',
      errorLink: false,
      errorLinkText: '',
      errorImage: false,
      errorImageText: '',
      errorVideo: false,
      errorVideoText: '',
    };

    this.size = this.size.bind(this);
    this.isUrl = this.isUrl.bind(this);
    this.checkTitleField = this.checkTitleField.bind(this);
    this.checkMessageField = this.checkMessageField.bind(this);
    this.checkLinkField = this.checkLinkField.bind(this);
    this.checkImageField = this.checkImageField.bind(this);
    this.checkVideoField = this.checkVideoField.bind(this);
    this.handlePostSubmit = this.handlePostSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleChangeVideo = this.handleChangeVideo.bind(this);
    this.handleClickImageReset = this.handleClickImageReset.bind(this);
    this.handleClickVideoReset = this.handleClickVideoReset.bind(this);
  }

  size = (size) => {
    let fExt = ['Bytes', 'KB', 'MB', 'GB'];
    let i = 0;

    while (size >= 1024) {
      size = size / 1024;
      i++;
    }

    size = Math.round(size * 100) / 100;
    return size + ' ' + fExt[i];
  }

  isUrl = (str) => {
    try {
      new URL(str);
    } catch (e) {
      return false;
    }

    return true;
  }

  checkTitleField = () => {
    const { title } = this.state;

    if (title === '') {
      this.setState({
        errorTitle: true,
        errorTitleText: 'Ups! Campo requerido.',
      });
      return false;
    }

    if (title.length > 100) {
      this.setState({
        errorTitle: true,
        errorTitleText: 'Ups! Compruebe el número máximo de caracteres.',
      });
      return false;
    }

    this.setState({
      errorTitle: false,
      errorTitleText: '',
    });
    return true;
  }

  checkMessageField = () => {
    const { message } = this.state;

    if (message === '') {
      this.setState({
        errorMessage: true,
        errorMessageText: 'Ups! Campo requerido.',
      });
      return false;
    }
    if (message.length > 4096) {
      this.setState({
        errorMessage: true,
        errorMessageText: 'Ups! Compruebe el número máximo de caracteres.',
      });
      return false;
    }

    this.setState({
      errorMessage: false,
      errorMessageText: '',
    });
    return true;
  }

  checkLinkField = () => {
    const { link } = this.state;

    if (link !== '' && this.isUrl(link) === false) {
      this.setState({
        errorLink: true,
        errorLinkText: 'Ups! El enlace está mal escrito',
      });
      return false;
    }
    if (link.length > 255) {
      this.setState({
        errorLink: true,
        errorLinkText: 'Ups! Compruebe el número máximo de caracteres.',
      });
      return false;
    }

    this.setState({
      errorLink: false,
      errorLinkText: '',
    });
    return true;
  }

  checkImageField = () => {
    const { image } = this.state;
    if (image === null) {
      this.setState({
        errorImage: false,
        errorImageText: '',
      });
      return true;
    }

    /**
     * Evaluate type of file
     */
    const name = image.name.toLowerCase();
    let allowed = ['.png', '.jpeg', '.jpg'];
    let ext = (name.substring(name.lastIndexOf('.'))).toLowerCase();
    let search = allowed.indexOf(ext);

    if (search === -1) {
      this.setState({
        errorImage: true,
        errorImageText: 'Ups! El archivo seleccionado no es una imagen. Extensiones permitidas: .png, .jpeg y .jpg.',
      });
      return false;
    }

    /**
     * Evaluate size
     */
    const size = image.size;
    const allowedSize = 5242880; // 5 MB
    if (size > allowedSize) {
      this.setState({
        errorImage: true,
        errorImageText: 'Ups! El archivo es muy pesado. Tamaño máximo permitido: 5 MB.',
      });
      return false;
    }

    this.setState({
      errorImage: false,
      errorImageText: '',
    });
    return true;
  }

  checkVideoField = () => {
    const { video } = this.state;
    if (video === null) {
      this.setState({
        errorVideo: false,
        errorVideoText: '',
      });
      return true;
    }

    /**
     * Evaluate type of file
     */
    const name = video.name.toLowerCase();
    let allowed = ['.mp4'];
    let ext = (name.substring(name.lastIndexOf('.'))).toLowerCase();
    let search = allowed.indexOf(ext);

    if (search === -1) {
      this.setState({
        errorVideo: true,
        errorVideoText: 'Ups! El archivo seleccionado no es un video. Extensiones permitidas: .mp4.',
      });
      return false;
    }

    /**
     * Evaluate size
     */
    const size = video.size;
    const allowedSize = 52428800; // 50 MB
    if (size > allowedSize) {
      this.setState({
        errorVideo: true,
        errorVideoText: 'Ups! El archivo es muy pesado. Tamaño máximo permitido: 50 MB.',
      });
      return false;
    }

    this.setState({
      errorVideo: false,
      errorVideoText: '',
    });
    return true;
  }

  handlePostSubmit = (event) => {
    event.preventDefault();
    const { id, token, title, message, link, image, video } = this.state;

    if (this.checkTitleField() === false && id === null) {
      return;
    }

    if (this.checkMessageField() === false) {
      return;
    }

    if (this.checkLinkField() === false) {
      return;
    }

    if (this.checkImageField() === false) {
      return;
    }

    if (this.checkVideoField() === false) {
      return;
    }

    this.setState({
      send: true,
      error: false,
      errorText: '',
      errorTitle: false,
      errorTitleText: '',
      errorMessage: false,
      errorMessageText: '',
      errorLink: false,
      errorLinkText: '',
      errorImage: false,
      errorImageText: '',
      errorVideo: false,
      errorVideoText: '',
    });

    const method = 'post';
    const url = process.env.REACT_APP_URL_API;
    const endpoint = id === null ? `${url}/ticket/` : `${url}/ticket/${id}`;

    let formData = new FormData();
    formData.append('title', title);
    formData.append('message', message);
    formData.append('link', link);

    if (image !== null) {
      formData.append('image', image);
    }

    if (video !== null) {
      formData.append('video', video);
    }

    let config = {
      method: method,
      url: endpoint,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
      data: formData,
    };

    axios(config)
      .then(response => {
        if (response.status === 201) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(() => {
        if (id === null) {
          const { history } = this.state;
          history.replace(ROUTE_TICKETS);
        } else {
          const { update } = this.state;
          update();
        }
      }).catch(error => {
        const code = error.response.status;
        let message;
        if (code === 403) {
          message = 'Ups! Lo sentimos, no te quedan consultas este mes';
        } else if (code === 415) {
          message = 'Ups! Ha ocurrido un error al subir uno de los archivos.';
        } else {
          message = 'Ups! Ha ocurrido un error inesperado ¡Vuelva a intentarlo!';
        }

        document.getElementById('image').value = '';
        document.getElementById('video').value = '';
        this.setState({
          title: '',
          message: '',
          link: '',
          image: null,
          imageName: '',
          imageSize: '',
          video: null,
          videoName: '',
          videoSize: '',
          send: false,
          error: true,
          errorText: message,
          errorTitle: false,
          errorTitleText: '',
          errorMessage: false,
          errorMessageText: '',
          errorLink: false,
          errorLinkText: '',
          errorImage: false,
          errorImageText: '',
          errorVideo: false,
          errorVideoText: '',
        });
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handleChangeImage = (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const name = file.name;
    const size = file.size;

    this.setState({
      image: file,
      imageName: name.toLowerCase(),
      imageSize: this.size(size),
    });
  }

  handleChangeVideo = (e) => {
    const file = e.target.files[0];
    const name = file.name;
    const size = file.size;

    this.setState({
      video: file,
      videoName: name.toLowerCase(),
      videoSize: this.size(size),
    });
  }

  handleClickImageReset = () => {
    document.getElementById('image').value = '';

    this.setState({
      image: null,
      imageName: '',
      imageSize: '',
      errorImage: false,
      errorImageText: '',
    });
  }

  handleClickVideoReset = () => {
    document.getElementById('video').value = '';

    this.setState({
      video: null,
      videoName: '',
      videoSize: '',
      errorVideo: false,
      errorVideoText: '',
    });
  }

  render() {
    const {
      id,
      title,
      message,
      link,
      imageName,
      imageSize,
      videoName,
      videoSize,
      send,
      error,
      errorText,
      errorTitle,
      errorTitleText,
      errorMessage,
      errorMessageText,
      errorLink,
      errorLinkText,
      errorImage,
      errorImageText,
      errorVideo,
      errorVideoText,
    } = this.state;
    const lengthTitle = title.length;
    const lengthMessage = message.length;
    const lengthLink = link.length;

    return (
      <div className='app-message__form'>
        <form onSubmit={this.handlePostSubmit}>
          { id === null ?
            <div className='app-message__form-item'>
              <input type='text'
                     name='title'
                     placeholder='Título de la consulta'
                     onChange={this.handleChange}
                     className={errorTitle === true ? 'error' : ''}
              />
              <div className='app-message__form-item-info'>
                <p className='error'>{ errorTitle === true ? errorTitleText : '' }</p>
                <p className='count'>{`${lengthTitle} / 100 Caracteres`}</p>
              </div>
            </div>
            :
            ''
          }
          <div className='app-message__form-item'>
            <textarea name='message'
                      placeholder='Resume brevemente tu consulta'
                      onChange={this.handleChange}
                      className={errorMessage === true ? 'error' : ''}
            />
            <div className='app-message__form-item-info'>
              <p className='error'>{ errorMessage === true ? errorMessageText : '' }</p>
              <p className='count'>{`${lengthMessage} / 4096 Caracteres`}</p>
            </div>
          </div>
          <div className='app-message__form-item'>
            <input type='text'
                   name='link'
                   placeholder='Compartir una URL'
                   onChange={this.handleChange}
                   className={errorLink === true ? 'error' : ''}
            />
            <div className='app-message__form-item-info'>
              <p className='error'>{ errorLink === true ? errorLinkText : '' }</p>
              <p className='count'>{`${lengthLink} / 255 Caracteres`}</p>
            </div>
          </div>
          <div className='app-message__form-item app-message__form-item--image'>
            <div className='drop-area'>
              <span className='drop-area__button'>
                <i className='icon-image'>{''}</i>
              </span>
              <span className='drop-area__message'>{ imageName === '' ? 'Adjuntar imagen' : imageName }</span>
              <input type='file'
                     id='image'
                     name='image'
                     className='drop-area__hidden'
                     accept='image/x-png, image/jpeg, image/jpg'
                     onChange={this.handleChangeImage} />
            </div>
            <p className='reset'>
              <button type='button'
                      onClick={this.handleClickImageReset}>
                Limpiar
              </button>
            </p>
            <div className='app-message__form-item-info'>
              <p className='error'>{ errorImage === true ? errorImageText : '' }</p>
              <p className='count'>{ imageSize === '' ? 'Ningún archivo seleccionado' : 'Tamaño: ' + imageSize }</p>
            </div>
          </div>
          <div className='app-message__form-item  app-message__form-item--image'>
            <div className='drop-area'>
              <span className='drop-area__button'>
                <i className='icon-image'>{''}</i>
              </span>
              <span className='drop-area__message'>{ videoName === '' ? 'Adjuntar video' : videoName }</span>
              <input type='file'
                     id='video'
                     name='video'
                     className='drop-area__hidden'
                     accept='video/mp4'
                     onChange={this.handleChangeVideo} />
            </div>
            <p className='reset'>
              <button type='button'
                      onClick={this.handleClickVideoReset}>
                Limpiar
              </button>
              </p>
            <div className='app-message__form-item-info'>
              <p className='error'>{ errorVideo === true ? errorVideoText : '' }</p>
              <p className='count'>{ videoSize === '' ? 'Ningún archivo seleccionado' : 'Tamaño: ' + videoSize }</p>
            </div>
          </div>
          <div className='app-messages__new'>
            <input type='submit'
                   value={ send === false ? 'Enviar mensaje' : 'Enviando consulta...' }
                   disabled={send}
            />
          </div>
        </form>
        { error === true ?
          <div className='message error'>
            <p>{errorText}</p>
          </div>
          :
          ''
        }
      </div>
    );
  }
}

TicketForm.propTypes = {
  id: PropTypes.string,
  history: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired,
  token: PropTypes.string.isRequired,
  update: PropTypes.func,
};

export default TicketForm;
