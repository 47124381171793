import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';

class UserDashboard extends Component {
  constructor(props) {
    super(props);

    const { token } = props;

    this.state = {
      token,
      loading: true,
      name: '',
      plan: '',
      gender: null,
      image: 'https://via.placeholder.com/150x150.png?text=GFIT+COACH'
    };
  };

  transformData = (data) => {
    const {
      name,
      plan,
      gender,
    } = data;

    return {
      loading: false,
      name,
      plan,
      gender,
    };
  }

  renderProgress = () => {
    const { image } = this.state;

    return (
      <div className='app-myplan__intro-text'>
        <div className='app-myplan__intro-perfil'>
          <div className='app-myplan__intro-perfil--image'>
            <img src={image} alt='GFIT Coach Avatar' />
          </div>
          <div className='app-myplan__intro-perfil--text'>
            <p className='h2'>Hola de nuevo</p>
            <div className='blue italic'>
              <div className='placeholder__text height-20'>
                <LinearProgress/>
              </div>
            </div>
          </div>
        </div>
        <div className='app-myplan__intro-welcome'>
          <span className='line'>{''}</span>
          <p className='title-condensed'>Bienvenido a tu entrenamiento personalizado con GFIT COACH</p>
          <span className='line'>{''}</span>
        </div>
      </div>
    );
  };

  renderComponent() {
    const { name, plan, gender } = this.state;
    let { image } = this.state;

    if (gender === 'H') {
      image = '/assets/img/avatar-hombre.png';
    } else if (gender === 'M') {
      image = '/assets/img/avatar-mujer.png';
    }

    return (
      <React.Fragment>
        <div className='app-myplan__intro-text'>
          <div className='app-myplan__intro-perfil'>
            <div className='app-myplan__intro-perfil--image'>
              <img src={image} alt='GFIT Coach Avatar' />
            </div>
            <div className='app-myplan__intro-perfil--text'>
              <p className='h2'>Hola de nuevo, {name}</p>
              <p className='blue italic'>{plan}</p>
            </div>
          </div>
          <div className='app-myplan__intro-welcome'>
            <span className='line'>{''}</span>
            <p className='title-condensed'>Bienvenido a tu entrenamiento personalizado con GFIT COACH</p>
            <span className='line'>{''}</span>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/user/info/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState(this.transformData(data));
      }).catch(error => {
        this.setState({
          loading: true,
          name: '',
          plan: '',
          gender: null,
          image: 'https://via.placeholder.com/150x150.png?text=GFIT+COACH'
        });
      });
  }

  render() {
    const { loading } = this.state;
    return loading === true ? this.renderProgress() : this.renderComponent();
  }
}

UserDashboard.propTypes = {
  token: PropTypes.string.isRequired,
};

export default UserDashboard;
