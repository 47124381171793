import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PreviousTrainingSessionComment extends Component {
  constructor(props) {
    super(props);

    const {
      comment,
    } = props;

    this.state = {
      comment: comment
    };
  }

  render() {
    const { comment } = this.state;

    return (
      <div className='app-exercise__previous'>
        <div className='app-exercise__previous__comment'>
          <p className='app-exercise__previous__comment__title'>Tú comentario la última vez que hiciste el ejercicio fue:</p>
          <p>{comment}</p>
        </div>
      </div>
    );
  }
}

PreviousTrainingSessionComment.propTypes = {
  comment: PropTypes.string.isRequired,
};

export default PreviousTrainingSessionComment;
