import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ROUTE_TRAINING, ROUTE_SESSION} from '../../../constants/routes';

class Content extends Component {
  constructor(props) {
    super(props);

    const {
      previousSession,
      session,
      handleOpenModal,
      handleSubmitRelax
    } = props;

    this.state = {
      previousSession,
      session,
      handleOpenModal,
      handleSubmitRelax,
    };
  }

  handleClickMovement = (event) => {
    const { previousSession } = this.state;

    const isRelaxPreviousSession = previousSession.is_relax;
    const isFinishedPreviousSession = previousSession.is_finished;
    const isFinishedPreviousSessionStatsEffortControl = previousSession.stats_effort_control.is_finished;

    if (isRelaxPreviousSession === false && isFinishedPreviousSessionStatsEffortControl === false) {
      event.preventDefault();
      return false;
    }
    if (isRelaxPreviousSession === true && isFinishedPreviousSession === false) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  printMovements = () => {
    const { session } = this.state;
    const { training } = session;
    const idSession = session.id;
    const { movements } = training;
    let print = [];
    let number = 1;

    for (let i in movements) {
      if (movements.hasOwnProperty(i)) {
        const { id, name, is_finished, superserie } = movements[i];
        let cssLink = 'app-trainingday__item';
        cssLink += is_finished === true ? ' completed' : '';
        let css = 'app-trainingday__item-content';
        css += superserie !== '' ? ' superserie' : '';

        print.push(
          <a key={id}
             className={cssLink}
             title={name}
             href={`/n/${number}${ROUTE_TRAINING}/${id}${ROUTE_SESSION}/${idSession}`}
             onClick={this.handleClickMovement}
          >
            <div className={css} >
              <p className='app-trainingday__item-name'>
                <span className='number'>{number}</span> {name}
              </p>
              <div className='app-trainingday__item-check'>
                <i className='icon-tick'>{''}</i>
              </div>
            </div>
            {
              superserie !== '' ?
                <p className='app-trainingday__item-superserie'>
                  Superserie de <strong>{superserie}</strong>
                </p>
                :
                ''
            }
          </a>
        );

        number++;
      }
    }

    return print;
  }

  handleClickSession = (event) => {
    event.preventDefault();

    const { previousSession, session, handleOpenModal } = this.state;

    // Previous session assets
    const isRelaxPreviousSession = previousSession.is_relax;
    const isFinishedPreviousSession = previousSession.is_finished;
    const isFinishedPreviousSessionStatsEffortControl = previousSession.stats_effort_control.is_finished;

    // Session assets
    const isFinishedSession = session.is_finished;

    if (isRelaxPreviousSession === false && isFinishedPreviousSessionStatsEffortControl === false) {
      return false;
    }
    if (isRelaxPreviousSession === true && isFinishedPreviousSession === false) {
      return false;
    }
    if (isFinishedSession === false) {
      return false;
    }

    handleOpenModal();
    return true;
  }

  printTraining = () => {
    const { previousSession, session } = this.state;

    // Previous session assets
    const isRelaxPreviousSession = previousSession.is_relax;
    const isFinishedPreviousSession = previousSession.is_finished;
    const isFinishedPreviousSessionStatsEffortControl = previousSession.stats_effort_control.is_finished;

    // Session assets
    const { training } = session;
    const { movements } = training;
    const count = movements.length;
    const isFinishedSession = session.is_finished;
    const isFinishedSessionStatsEffortControl = session.stats_effort_control.is_finished;
    let rating = 0;
    let comment = '';
    if (isFinishedSessionStatsEffortControl === true) {
      rating = session.stats_effort_control.rating;
      comment = session.stats_effort_control.comment;
    }

    // Css assets
    let cssButton = 'app-trainingday__btn-link btn';
    if (isRelaxPreviousSession === false && isFinishedPreviousSessionStatsEffortControl === false) {
      cssButton = 'app-trainingday__btn-link btn disabled';
    }
    if (isRelaxPreviousSession === true && isFinishedPreviousSession === false) {
      cssButton = 'app-trainingday__btn-link btn disabled';
    }
    if (isFinishedSession === false) {
      cssButton = 'app-trainingday__btn-link btn disabled';
    }

    return (
      <div className='container-app container-app-medium'>
        <div className='app-trainingday__title'>
          <p className='app-trainingday__title-name'>Entrenamiento</p>
          <p className='app-trainingday__title-number'>{count} ejercicios</p>
        </div>
        <div className='app-trainingday__list'>
          { this.printMovements() }
        </div>
        {
          isFinishedSessionStatsEffortControl === true ?
            <div className='app-trainingday__data'>
              <p className='app-trainingday__data-rating'>TU FEEDBACK: {rating}</p>
              <p className='app-trainingday__data-comment'>{comment}</p>
            </div>
          :
            <div className='app-trainingday__btn'>
              <button
                className={cssButton}
                onClick={ this.handleClickSession }
              >
                Completar entrenamiento
              </button>
            </div>
        }
      </div>
    );
  }

  handleClickRelax = () => {
    const { previousSession, handleSubmitRelax } = this.state;
    const isRelax = previousSession.is_relax;
    const isFinished = previousSession.is_finished;
    const isFinishedStatsEffortControl = previousSession.stats_effort_control.is_finished;

    if (isRelax === false && isFinishedStatsEffortControl === false) {
      return;
    }

    if (isRelax === true && isFinished === false) {
      return;
    }

    handleSubmitRelax();
  }

  printRelax = () => {
    const { previousSession, session } = this.state;

    // Previous session assets
    const isRelaxPreviousSession = previousSession.is_relax;
    const isFinishedPreviousSession = previousSession.is_finished;
    const isFinishedStatsEffortControl = previousSession.stats_effort_control.is_finished;

    // Session assets
    const isFinished = session.is_finished;

    // Css assets
    let cssButton = 'app-trainingday__btn-link btn';

    if (isRelaxPreviousSession === false && isFinishedStatsEffortControl === false) {
      cssButton += ' disabled';
    }

    if (isRelaxPreviousSession === true && isFinishedPreviousSession === false) {
      cssButton += ' disabled';
    }

    return (
      <div className='container-app container-app-medium'>
        <div className='app-trainingday__title'>
          <p className='app-trainingday__title-name'>Día de descanso</p>
          <p className='app-trainingday__title-number'>Hoy no tienes ejercicios por hacer</p>
        </div>
        {
          isFinished === false ?
            <React.Fragment>
              <div className='app-trainingday__list rest'>
                <h2 className='app-trainingday__list-title'>¿Has aprovechado tu día de descanso?</h2>
              </div>
              <div className='app-trainingday__btn rest'>
                <button
                  className={cssButton}
                  onClick={ this.handleClickRelax }
                >
                  Completar descanso
                </button>
              </div>
            </React.Fragment>
            :
            <div className='app-trainingday__list'>
              <h2 className='app-trainingday__list-title'>¡Bien! Has completado tu día de descanso</h2>
            </div>
        }
      </div>
    )
  }

  printPreviousSessionIsNotFinished = () => {
    const { previousSession } = this.state;

    // Previous session assets
    const isFinishedPreviousSession = previousSession.is_finished;
    const isRelaxPreviousSession = previousSession.is_relax;
    const statsEffortControl = previousSession.stats_effort_control;
    const isFinishedStatsEffortControl = statsEffortControl.is_finished;

    if (isRelaxPreviousSession === false && isFinishedStatsEffortControl === true) {
      return;
    }

    if (isRelaxPreviousSession === true && isFinishedPreviousSession === true) {
      return;
    }

    return (
      <div className='container-app container-app-medium'>
        <p className='app-trainingday__message'>Ups! Hemos detectado que no has acabado la sesión anterior =)</p>
      </div>
    );
  }

  render() {
    const { session } = this.state;
    const { day } = session;
    const isRelax = session.is_relax;

    return (
      <React.Fragment>
        <div className='app-trainingday'>
          <div className='app-trainingday__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-back'>
                <a href={ROUTE_TRAINING} className='app-back__link'>
                  <i className='icon-arrow'>{''}</i>
                  <p>Volver atrás</p>
                </a>
              </div>
              <div className='app-trainingday__intro-title'>
                <h2 className='title-condensed'>Entrenamiento día {day}</h2>
              </div>
            </div>
          </div>
          { this.printPreviousSessionIsNotFinished() }
          { isRelax === true ? this.printRelax() : this.printTraining() }
        </div>
      </React.Fragment>
    );
  }
}

Content.propTypes = {
  previousSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    is_relax: PropTypes.bool.isRequired,
    is_finished: PropTypes.bool.isRequired,
    stats_effort_control: PropTypes.shape({
      id: PropTypes.string,
      rating: PropTypes.number,
      comment: PropTypes.string,
      is_finished: PropTypes.bool.isRequired,
    }).isRequired
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    day: PropTypes.number.isRequired,
    is_relax: PropTypes.bool.isRequired,
    is_finished: PropTypes.bool.isRequired,
    training: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      movements: PropTypes.objectOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        superserie: PropTypes.string.isRequired,
        is_finished: PropTypes.bool.isRequired,
      })),
    }).isRequired,
    stats_effort_control: PropTypes.shape({
      id: PropTypes.string,
      rating: PropTypes.number,
      comment: PropTypes.string,
      is_finished: PropTypes.bool.isRequired,
    }).isRequired
  }).isRequired,
  handleOpenModal: PropTypes.func,
  handleSubmitRelax: PropTypes.func,
};

export default Content;
