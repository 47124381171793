import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import {Link} from 'react-router-dom';
import {ROUTE_MY_ACCOUNT} from '../../constants/routes';
import UserMyAccount from '../../components/UserMyAccount';
import MacrocycleForm from '../../components/MacrocycleForm';

class Macrocycle extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
    };

    this.authService = new AuthService();
  };

  render() {
    const { token, user } = this.state;

    return (
      <React.Fragment>
        <Header name='Macrociclo' from='macrocycle' user={user} token={token} />
        <Menu user={user} />
        <div className='app-settings'>
          <div className='container-app container-app-mini'>
            <div className='app-back'>
              <Link to={ROUTE_MY_ACCOUNT} className='app-back__link'>
                <i className='icon-arrow'>{''}</i>
                <p>Volver atrás</p>
              </Link>
            </div>
            <div className='app-settings__list'>
              <UserMyAccount token={token} />
              <MacrocycleForm token={token} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Macrocycle.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  Macrocycle,
  ['gf_plan_premium', 'gf_plan_pro']
);
