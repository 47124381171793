import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import {
  ROUTE_STATISTICS,
  ROUTE_TRAINING,
} from '../../constants/routes';

class RMCalculator extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      RMCalculated: false,
      repetitions: '',
      kilos: '',
      rmOne: 0,
      rmFive: 0,
      rmTen: 0,
      rmFifteen: 0,
      goBackTraining: ROUTE_TRAINING,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.calculateRM = this.calculateRM.bind(this);
    this.authService = new AuthService();
  };

  handleInputChange = (event) => {
    const { name, value} = event.target;

    this.setState({
      [name]: value,
    });
  };

  calculateRM = (event) => {
    event.preventDefault();

    let initialRm = (this.state.repetitions / 30 + 1) * this.state.kilos;
    this.setState({
      rmOne: Math.round(initialRm),
      rmFive: Math.round(initialRm / 1.167),
      rmTen: Math.round(initialRm / 1.33),
      rmFifteen: Math.round(initialRm / 1.475),
      RMCalculated: true,
    })
  }

  componentDidMount() {
    const { history } = this.state;
    const { location } = history;
    const { state } = location;
    const { from, fromTraining } = state;

    if (fromTraining === true) {
      this.setState({
        goBackTraining: from,
      })
    }
  }

  render() {
    const { goBackTraining, user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Calculadora RM' from='statistics' user={user} token={token} />
        <Menu from='statistics' user={user} />
        <div className='app-calculator'>
          <div className='app-calculator__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-back'>
                <Link to={ROUTE_STATISTICS} className='app-back__link'>
                  <i className='icon-arrow'>{''}</i>
                  <p>Volver atrás</p>
                </Link>
              </div>
            </div>
            <div className='container-app container-app-medium'>
              <div className='app-calculator__intro-title'>
                <h2 className='title-condensed'>Calcula tu rendimiento máximo</h2>
                <p>Utiliza nuestra calculadora de Rendimiento Máximo (RM) para conocer los pesos que debes levantar a la hora de entrenar un ejercicio concreto en función del número máximo de repeticiones.</p>
                <p className='bold'> Controla cuál va a ser el volumen de peso o cargas que vas a levantar y cuenta el número de repeticiones que eres capaz de hacer con este.</p>
                <a href={goBackTraining} className='btn btn-sm'>Volver al ejercicio</a>
              </div>
              <div className='app-statistics__intro-image'>
                <img src='/assets/img/calculator-background-section.png'
                     alt='GFITCOACH'/>
              </div>
            </div>
          </div>
          <div className='container-app container-app-medium'>
            <form onSubmit={this.calculateRM}>
              <div className='app-calculator__form'>
                <div className='app-calculator__form-inputs'>
                  <div className='app-calculator__form-input'>
                    <p>Número máximo de repeticiones realizadas</p>
                    <div className='input-label'>
                      <input type='number'
                             name='repetitions'
                             onChange={this.handleInputChange}
                             required
                      />
                      <label id='label'>Número de repeticiones</label>
                      <i className='icon-mark-full'>{''}</i>
                    </div>
                  </div>
                  <div className='app-calculator__form-input'>
                    <p>Kilos levantados hasta el fallo</p>
                    <div className='input-label'>
                      <input type='number'
                             step='any'
                             name='kilos'
                             onChange={this.handleInputChange}
                             required
                      />
                      <label id='label'>Kilos levantados</label>
                      <i className='icon-volume-full'>{''}</i>
                      <small>Para introducir decimales utiliza: '.'. Ejemplo: 25.5</small>
                    </div>
                  </div>
                </div>
                <div className='app-calculator__form-btn'>
                  <input type='submit' value='Calcular RM'/>
                </div>
              </div>
            </form>
            {(this.state.RMCalculated !== false) ?
              <div className='app-calculator__list'>
                <div className='app-calculator__item'>
                  <p className='number-rm'>1 RM</p>
                  <p className='number'>{this.state.rmOne}</p>
                </div>
                <div className='app-calculator__item'>
                  <p className='number-rm'>5 RM</p>
                  <p className='number'>{this.state.rmFive}</p>
                </div>
                <div className='app-calculator__item'>
                  <p className='number-rm'>10 RM</p>
                  <p className='number'>{this.state.rmTen}</p>
                </div>
                <div className='app-calculator__item'>
                  <p className='number-rm'>15 RM</p>
                  <p className='number'>{this.state.rmFifteen}</p>
                </div>
              </div>
              :
              <div className='app-calculator__list'>
                <div className='app-calculator__item'>
                  <p className='number-rm'>1 RM</p>
                  <p className='number'>--</p>
                </div>
                <div className='app-calculator__item'>
                  <p className='number-rm'>5 RM</p>
                  <p className='number'>--</p>
                </div>
                <div className='app-calculator__item'>
                  <p className='number-rm'>10 RM</p>
                  <p className='number'>--</p>
                </div>
                <div className='app-calculator__item'>
                  <p className='number-rm'>15 RM</p>
                  <p className='number'>--</p>
                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

RMCalculator.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  RMCalculator,
  ['gf_plan_premium', 'gf_plan_pro']
);
