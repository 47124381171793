import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import MaximumRepetitionsItem from '../MaximumRepetitionsItem';
import LinearProgress from '@material-ui/core/LinearProgress';

class MaximumRepetitionsList extends Component {
  constructor(props) {
    super(props);

    const {
      token,
    } = props;

    this.state = {
      token,
      mesocycles: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.printItem = this.printItem.bind(this);
    this.printData = this.printData.bind(this);
  };

  handleClick = (event) => {
    event.preventDefault();

    let currentTarget = event.currentTarget;
    let parentNode = currentTarget.parentNode;
    let classList = parentNode.classList;
    if (classList.contains('open')) {
      classList.remove('open');
    } else {
      classList.add('open');
    }
  }

  printItem = (items, current) => {
    const { token } = this.state;
    let print = [];

    for (let i in items) {
      if (items.hasOwnProperty(i)) {
        const id = items[i].id;
        const practice = items[i].practice;
        const value = items[i].maximum_repetition;
        const movement = items[i].movement;

        if (movement === 'gf_abd') {
          continue;
        }

        print.push(
          <MaximumRepetitionsItem
            key={id}
            token={token}
            id={id}
            practice={practice}
            value={value}
            current={current}
          />
        );
      }
    }

    return print;
  }

  printData = () => {
    const { mesocycles } = this.state;
    let print = [];

    for (let i in mesocycles) {
      if (mesocycles.hasOwnProperty(i)) {
        const items = mesocycles[i].items;
        const data = mesocycles[i].data;
        const id = data.id;
        const number = data.number;
        const current = data.current;
        const style = current === true ? 'maximum-repetitions__row current' : 'maximum-repetitions__row';

        print.push(
          <div key={id} className={style}>
            <div className='maximum-repetitions__header' onClick={this.handleClick}>
              <p>Mesociclo {number}</p>
              <div className='maximum-repetitions__header-toggle'>
                <span>{''}</span>
                <span>{''}</span>
              </div>
            </div>
            <div className='maximum-repetitions__body'>
              { this.printItem(items, current) }
            </div>
          </div>
        );
      }
    }

    return print;
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/maximum-repetitions/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState({
          mesocycles: data.mesocycles,
        });
      }).catch(() => {
        this.setState({
          mesocycles: null,
        });
      });
  }

  render() {
    const { mesocycles } = this.state;

    if (mesocycles === null) {
      return (
        <div className='maximum-repetitions__content'>
          <LinearProgress />
        </div>
      );
    }

    if (typeof mesocycles === 'undefined') {
      return (
        <div className='maximum-repetitions__content'>
          <p>Ups! Actualmente no tienes información</p>
        </div>
      );
    }

    return (
      <div className='maximum-repetitions__content'>
        { this.printData() }
      </div>
    );
  }
}

MaximumRepetitionsList.propTypes = {
  token: PropTypes.string.isRequired,
};

export default MaximumRepetitionsList;
