import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';

class UserMyAccount extends Component {
  constructor(props) {
    super(props);

    const { token } = props;

    this.state = {
      token,
      loading: true,
      name: '',
      email: '',
      gender: null,
      image: 'https://via.placeholder.com/150x150.png?text=GFIT+COACH'
    };
  }

  transformData = (data) => {
    const {
      name,
      email,
      gender,
    } = data;

    return {
      loading: false,
      name,
      email,
      gender,
    };
  }

  renderComponentLoading = () => {
    const { image } = this.state;

    return (
      <div className='app-settings__item item-picture'>
        <div className='app-settings__item-title'>
          <div className='image'>
            <img src={image} alt='GFIT Coach Avatar' />
          </div>
          <div className='placeholder__text height-20'>
            <LinearProgress/>
          </div>
        </div>
      </div>
    );
  }

  renderComponent = () => {
    const { name, email, gender } = this.state;
    let { image } = this.state;

    if (gender === 'H') {
      image = '/assets/img/avatar-hombre.png';
    } else if (gender === 'M') {
      image = '/assets/img/avatar-mujer.png';
    }

    return (
      <div className='app-settings__item item-picture'>
        <div className='app-settings__item-title'>
          <div className='image'>
            <img src={image} alt='GFIT Coach Avatar' />
          </div>
          <div className='text'>
            <p className='text-name'>{name}</p>
            <p className='text-mail'>{email}</p>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/user/info/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState(this.transformData(data));
      }).catch(error => {
        this.setState({
          loading: true,
          name: '',
          email: '',
          gender: null,
          image: 'https://via.placeholder.com/150x150.png?text=GFIT+COACH'
        });
      });
  }

  render() {
    const { loading } = this.state;
    return loading === true ? this.renderComponentLoading() : this.renderComponent();
  }
}

UserMyAccount.propTypes = {
  token: PropTypes.string.isRequired,
};

export default UserMyAccount;
