import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ROUTE_TICKETS_EDIT, ROUTE_TICKETS_NEW} from '../../../constants/routes';
import {formatDatetime} from '../../../constants/common';

class Content extends Component {
  constructor(props) {
    super(props);

    const { tickets } = props;

    this.state = {
      tickets,
    };
  }

  printTicket = () => {
    const { tickets } = this.state;
    let print = [];

    for (let i in tickets) {
      if (tickets.hasOwnProperty(i)) {
        const {
          id,
          title,
          state,
          createdAt,
          updatedAt,
          message
        } = tickets[i];

        print.push(
          <a key={id} href={`${ROUTE_TICKETS_EDIT}/${id}`} className='app-messages__item'>
            <div className='app-messages__item-content'>
              <p className='title' dangerouslySetInnerHTML={{__html: title}} />
              <p className='message' dangerouslySetInnerHTML={{__html: message}} />
              <div className='date'>
                <p className='created'><span>Fecha creación:</span> {formatDatetime(createdAt)}</p>
                <p className='updated'><span>Última respuesta:</span> {formatDatetime(updatedAt)}</p>
              </div>
            </div>
            <div className='app-messages__item-link'>
              <span className='item-state'>{state}</span>
              <i className='icon-arrow'>{''}</i>
            </div>
          </a>
        );
      }
    }

    return print;
  }

  renderComponent = () => {
    return (
      <div className='app-messages__content'>
        <div className='app-messages__list'>
          {this.printTicket()}
        </div>
        <div className='app-messages__new'>
          <a href={ROUTE_TICKETS_NEW} className='btn'>Nueva consulta</a>
        </div>
      </div>
    );
  }

  renderEmpty = () => {
    return (
      <div className='app-messages__content'>
        <div className='app-messages__list'>
          <p className='app-messages__item no-message'>Todavía no has realizado ninguna consulta.</p>
        </div>

        <div className='app-messages__new'>
          <a href={ROUTE_TICKETS_NEW} className='btn'>Nueva consulta</a>
        </div>
      </div>
    );
  }

  render() {
    const { tickets } = this.state;

    return tickets.length === 0 ? this.renderEmpty() : this.renderComponent();
  }
}

Content.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  })).isRequired,
};

export default Content;
