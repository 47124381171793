import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import axios from 'axios';
import {ROUTE_MACROCYCLE} from '../../constants/routes';
import Content from './Content/';
import Loading from './Loading/';

class Training extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      hasMacrocycle: null,
    };

    this.authService = new AuthService();
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token, history } = this.state;

    let config = {
      method: 'get',
      url: `${url}/macrocycle/status/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState({
          hasMacrocycle: true,
        });
      }).catch(error => {
        history.replace(ROUTE_MACROCYCLE);
      });
  }

  render() {
    const { token, user, hasMacrocycle } = this.state;
    return hasMacrocycle === null ?
      <Loading  token={token} user={user}/>
      :
      <Content  token={token} user={user}/>;
  }
}

Training.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  Training,
  ['gf_plan_premium', 'gf_plan_pro']
);
