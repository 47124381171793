import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {ROUTE_SESSION} from '../../constants/routes';
import Content from './Content';
import ContentFinished from './ContentFinished';
import ContentFirst from './ContentFirst';
import Loading from './Loading/';

class TrainingSession extends Component {
  constructor(props) {
    super(props);

    const {
      token,
      history,
      match,
    } = props;

    this.state = {
      token,
      history,
      match,
      loading: true,
      session: null,
      sessionTrainingCycle: null,
      sessionTrainingCycleBefore: null,
      trainingSession: null,
      movementOfTraining: null,
      movement: null,
      previousTrainingSession: null,
    };

    this.handleSubmitFirst = this.handleSubmitFirst.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  send = (config, history, id) => {
    axios(config)
      .then(response => {
        if (response.status === 201) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(() => {
        history.replace(`${ROUTE_SESSION}/${id}`);
      }).catch(() => {
        this.setState({
          loading: true,
          session: null,
          sessionTrainingCycle: null,
          sessionTrainingCycleBefore: null,
          trainingSession: null,
          movementOfTraining: null,
          movement: null,
          previousTrainingSession: null,
        });
      });
  }

  handleSubmitFirst = (data) => {
    const url = process.env.REACT_APP_URL_API;
    const { token, history, session } = this.state;
    const { id } = session;

    let config = {
      method: 'post',
      url: `${url}/training/first/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      params: data,
    };

    this.send(config, history, id);
  }

  handleSubmit = (data) => {
    const url = process.env.REACT_APP_URL_API;
    const { token, history, session } = this.state;
    const { id } = session;

    let config = {
      method: 'post',
      url: `${url}/training/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      params: data,
    };

    this.send(config, history, id);
  }

  transformData = (data) => {
    const session = typeof data.session === 'undefined' ? null : data.session;
    const sessionTrainingCycle = typeof data.session_training_cycle === 'undefined' ? null : data.session_training_cycle;
    const sessionTrainingCycleBefore = typeof data.session_training_cycle_before === 'undefined' ? null : data.session_training_cycle_before;
    const trainingSession = typeof data.training_session === 'undefined' ? null : data.training_session;
    const movementOfTraining = typeof data.movement_of_training === 'undefined' ? null : data.movement_of_training;
    const movement = typeof data.movement === 'undefined' ? null : data.movement;
    const previousTrainingSession = typeof data.previous_training_session === 'undefined' ? null : data.previous_training_session;

    return {
      loading: false,
      session,
      sessionTrainingCycle,
      sessionTrainingCycleBefore,
      trainingSession,
      movementOfTraining,
      movement,
      previousTrainingSession,
    };
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token, match } = this.state;
    const { params } = match;
    const { tid, sid } = params;

    let config = {
      method: 'get',
      url: `${url}/training/${tid}/session/${sid}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState(this.transformData(data));
      }).catch(() => {
        this.setState({
          loading: true,
          session: null,
          trainingSession: null,
          movementOfTraining: null,
          movement: null,
          previousTrainingSession: null,
        });
      });
  }

  renderComponentLoading = () => {
    const { match } = this.state;
    const { params } = match;
    const { n, sid } = params;
    const number = n < 10 ? `0${n}.` : `${n}.`;

    return <Loading number={number} sid={sid} />
  }

  renderComponentFirst = () => {
    const {
      history,
      match,
      session,
      movementOfTraining,
      movement,
      previousTrainingSession,
    } = this.state;
    const { pathname } = history.location;
    const { params } = match;
    const { n } = params;
    const number = n < 10 ? `0${n}.` : `${n}.`;

    return <ContentFirst
      pathname={pathname}
      number={number}
      session={session}
      movementOfTraining={movementOfTraining}
      movement={movement}
      previousTrainingSession={previousTrainingSession}
      handleSubmitFirst={this.handleSubmitFirst}
    />
  }

  renderComponent = () => {
    const {
      match,
      session,
      sessionTrainingCycle,
      sessionTrainingCycleBefore,
      movementOfTraining,
      movement,
      previousTrainingSession,
    } = this.state;
    const { params } = match;
    const { n } = params;
    const number = n < 10 ? `0${n}.` : `${n}.`;

    return <Content
      number={number}
      session={session}
      sessionTrainingCycle={sessionTrainingCycle}
      sessionTrainingCycleBefore={sessionTrainingCycleBefore}
      movementOfTraining={movementOfTraining}
      movement={movement}
      previousTrainingSession={previousTrainingSession}
      handleSubmit={this.handleSubmit}
    />
  }

  renderComponentFinished = () => {
    const {
      match,
      session,
      trainingSession,
    } = this.state;
    const { params } = match;
    const { n } = params;
    const number = n < 10 ? `0${n}.` : `${n}.`;

    return <ContentFinished
      number={number}
      session={session}
      trainingSession={trainingSession}
    />
  }

  render() {
    const {
      loading,
      session,
      trainingSession,
    } = this.state;

    if (trainingSession !== null && loading === false) {
      return this.renderComponentFinished();
    }

    if (session !== null && loading === false) {
      const trainingCycle = session.training_cycle;

      if (trainingCycle === 1) {
        return this.renderComponentFirst();
      }
      if (trainingCycle > 1) {
        return this.renderComponent();
      }
    }

    return this.renderComponentLoading();
  }
}

TrainingSession.propTypes = {
  token: PropTypes.string.isRequired,
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      n: PropTypes.string.isRequired,
      tid: PropTypes.string.isRequired,
      sid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TrainingSession;
