import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

class Academy extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      featuredPost: '',
      data: '',
      content_type: '',
      level: '',
      categories: [],
      subcategories: [],
      category: null,
      subcategory: null,
      searchText: '',
      searchitems: '',
      pagesIndex: 1,
      update: false,
      loading: true,
      totalPages: 0,
    };

    this.authService = new AuthService();

    this.handleCategorySelection = this.handleCategorySelection.bind(this);
    this.handleSubcategorySelection = this.handleSubcategorySelection.bind(this);
  };

  render() {
    const {
      featuredPost,
      searchText,
      data,
      pagesIndex,
      user,
      token,
      categories,
      subcategories,
      totalPages,
      loading,
    } = this.state;

    return (
      <>
        <Header name='Academia' from='academy' user={user} token={token} />
        <Menu from='academy' user={user} />
        <div className='app-academy'>
          <div className='app-academy__intro'>
            <div className='container-app container-app-full'>
              <div className='app-academy__intro-welcome'>
                <h1 className='title-caps'><span>Bienvenido a la </span>Academia
                </h1>
                <p>Aquí encontrarás todo lo que necesitas saber sobre entrenamiento personal, nutrición, trucos y ejercicios para ponerte en forma. Se irá publicando contenido de valor continuamente para que te encuentres en un continuo proceso de aprendizaje y evolución.</p>
              </div>
              {featuredPost === '' ?
                <div className='academy__item placeholder'>
                  <CircularProgress className='loading__progress'/>
                </div> :
                featuredPost
              }
            </div>
          </div>

          <div className='container-app container-app-full'>
            <div className='app-academy__filter'>
              <div className='app-academy__filter-content'>
                <div className='app-academy__filter-category'>
                  <div className='select-black'>
                    <select name='category'
                            className='select'
                            onChange={(e) => this.handleCategorySelection(e)}
                            dangerouslySetInnerHTML={{__html: categories}}>
                    </select>
                  </div>
                </div>
                {subcategories.length >= 1 ?
                  <div className='app-academy__filter-level'>
                      <div className='select-black'>
                        <select name='subcategory'
                                className='select'
                                onChange={(e) => this.handleSubcategorySelection(e)}
                                dangerouslySetInnerHTML={{__html: subcategories}}>
                        </select>
                      </div>
                  </div>
                : ''}
                <div className='app-academy__filter-search'>
                  <div className='app-academy__filter-search-form'>
                    <input type='text'
                           name='searchText'
                           placeholder='Buscar...'
                           className='app-academy__filter-search-input'
                           value={searchText}
                           onChange={this.handleChangeSearch} />
                    <button type='button'
                            className='app-academy__filter-search-btn'
                            value='Buscar'><i className='icon-search'>{''}</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {loading === true ?
              <div className='academy__grid'>
                <div className='academy__item placeholder placeholder-small'>
                  <CircularProgress className='loading__progress'/>
                </div>
                <div className='academy__item placeholder placeholder-small'>
                  <CircularProgress className='loading__progress'/>
                </div>
                <div className='academy__item placeholder placeholder-small'>
                  <CircularProgress className='loading__progress'/>
                </div>
                <div className='academy__item placeholder placeholder-small'>
                  <CircularProgress className='loading__progress'/>
                </div>
              </div>
              : data.length === 0 ?
                this.printNoData()
                :
                <div className='academy__grid'>
                  {data}
                </div>
            }
            {totalPages > pagesIndex ?
              <div className='academy__grid-nav'>
                <button className='btn' onClick={this.getMorePosts}>Cargar más</button>
              </div>
              :
              ''
            }
          </div>
        </div>
      </>
    );
  }

  /**
   * Return no result
   * @returns {JSX.Element}
   */
  printNoData() {
    return (
      <div>
        <div className='placeholder__empty full'>
          <p className='placeholder__empty-pretitle'>Oops!</p>
          <p className='placeholder__empty-title'>Lo sentimos, no hay contenido disponible que se ajuste a tu búsqueda</p>
          <p className='placeholder__empty-text'>Prueba a buscar otro contenido o filtrar de otra manera</p>
        </div>
      </div>
    );
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.getAllCategories();
    this.getPosts();
  }

  /**
   * Detect state update to rerender posts
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { category, subcategory, searchText, pagesIndex } = this.state;

    if (prevState.category !== category ||
        prevState.subcategory !== subcategory ||
        prevState.searchText !== searchText
    ) {
      this.setState({
        pagesIndex: 1,
        loading: true,
      }, this.getPosts());
    }

    if (prevState.pagesIndex !== pagesIndex) {
      this.getPosts();
    }
  }

  /**
   * Handles user category selection
   * @param e
   */
  handleCategorySelection = (e) => {
    const categorySelected = e.target.value;

    this.setState({
      category: categorySelected,
      subcategory: null,
      searchText: '',
    }, () => {
      if (categorySelected === '') {
        this.setState({
          subcategories: [],
        });
      } else {
        this.getSubcategories();
      }
    });
  }

  /**
   * Handles user subcategory selection
   * @param e
   */
  handleSubcategorySelection = (e) => {
    this.setState({
      subcategory: e.target.value,
      searchText: '',
    });
  }

  /**
   * Load more posts
   * @param event
   */
  getMorePosts = (event) => {
    event.preventDefault();

    const { pagesIndex } = this.state;

    this.setState({
      pagesIndex: (pagesIndex + 1),
    });
  }

  /**
   * Get subcategories
   */
  getSubcategories= () => {
    const url = process.env.REACT_APP_URL_WORDPRESS;
    const { category } = this.state;
    const { user } = this.state;
    const { appKey } = user;

    axios.get(`${url}/wp-json/wp/v2/categories?parent=${category}&per_page=100`, {
      params: {
        key: appKey
      }
    }).then(res => {
      this.setState({
        subcategories: this.resToSelectOptions(res, 'Ver todo'),
      });
    }).catch(err => {
      console.log(`Error in getSubcategories: ${err}`);
    });
  }

  /**
   * Return the posts API URL formatted with filters
   * @returns {string}
   */
  buildGetPostsUrl = () => {
    const url = process.env.REACT_APP_URL_WORDPRESS;
    const { pagesIndex, category, subcategory, searchText } = this.state;

    let postsUrl = `${url}/wp-json/wp/v2/posts?page=${pagesIndex}&per_page=${process.env.REACT_APP_POST_PER_PAGE}`;

    if (subcategory !== null && subcategory !== '') {
      postsUrl += `&categories=${subcategory}`; // Search by subcategory
    } else if (category !== null && category !== '') {
      postsUrl += `&categories=${category}`;   // Search by category
    }

    if (searchText.length > 0) {
      postsUrl += `&search=${searchText}`; // Search by text
    }

    return postsUrl;
  }

  /**
   * Get posts
   */
  getPosts = () => {
    const { user } = this.state;
    const { appKey } = user;

    axios.get(this.buildGetPostsUrl(), {
      params: {
        key: appKey
      }
    })
      .then(res => {
        const headers = res.headers;
        const totalPages = headers['x-wp-totalpages'];

        this.preparePostsToRender(res, totalPages);
      })
      .catch(err => {
        console.log(`Error in getPost: ${err}`);
      });
  }

  /**
   * Render fetched posts
   * @param res
   * @param totalPages
   */
  preparePostsToRender = (res, totalPages) => {
    const { featuredPost, pagesIndex } = this.state;
    let { data } = this.state;

    let result = [];
    res.data.forEach(item => {
      const fecha = new Date(item.date);
      if (item.featured_img) {
        result.push(
          <div key={item.id} className='academy__item'>
            <Link to={{
              pathname: `/academy/post/${item.slug}`,
              state: {from: window.location.pathname}
            }}>
              <div className='academy__item-text'>
                {item.categories.map((category) => (
                  <p key={category.category_id}
                     className='academy__item-text-category'>{category.category_name}</p>
                ))}
                <span className='academy__item-text-line'></span>
                <p
                  className='academy__item-text-date'>{new Intl.DateTimeFormat('es-ES', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }).format(fecha)}</p>
              </div>
              <div className='academy__item-image'>
                <div className='img-outside'>
                  <div className='img-inside'>
                    <div className='gradient'></div>
                    <div className='img-post-blog'>
                      <img src={item.featured_img} alt={item.title.rendered}/>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      }
    });

    if (featuredPost === '') {
      // First load
      let featured = result.slice(0, 1);
      result = result.slice(1);

      this.setState({
        featuredPost: featured,
        data: result,
        totalPages: totalPages,
        loading: false,
      });
    } else {
      const newData = pagesIndex === 1 ? result : data.concat(result);

      this.setState({
        data: newData,
        totalPages: totalPages,
        loading: false
      });
    }
  }

  /**
   * Return options for select from res
   * @param res
   * @param text
   * @returns {string}
   */
  resToSelectOptions = (res, text) => {
    let categorySelect = `<option value=''>${text}</option>`;

    res.data.forEach(item => {
        categorySelect += `<option key=${item.id} value=${item.id}>${item.name}</option>`;
    });

    return categorySelect;
  }

  /**
   * Get all WP categories
   */
  getAllCategories = () => {
    const url = process.env.REACT_APP_URL_WORDPRESS;
    const { user } = this.state;
    const { appKey } = user;

    axios.get(url + '/wp-json/wp/v2/categories?parent=0&per_page=100', {
      params: {
        key: appKey
      }
    }).then(res => {
      this.setState({
        categories: this.resToSelectOptions(res, 'Categorías'),
      });
    }).catch(err => {
      console.log(`Error in getAllCategories: ${err}`);
    });
  }

  /**
   *
   * @param event
   */
  handleChangeSearch = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }
}


Academy.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  Academy,
  ['gf_plan_basic', 'gf_plan_premium', 'gf_plan_pro']
);
