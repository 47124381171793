import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PreviousTrainingSession extends Component {
  constructor(props) {
    super(props);

    const {
      repetitions,
      weight,
    } = props;

    this.state = {
      repetitions: repetitions,
      weight: weight
    };
  }

  repetitionsToText = () => {
    const { repetitions } = this.state;

    return repetitions.join(', ').replace(/, ([^, ]*)$/, ' y $1');
  }

  render() {
    const { weight } = this.state;

    let repetitionsText = this.repetitionsToText();

    return (
      <div className='app-exercise__previous'>
        <div className='app-exercise__previous__icon'>
          <i className='icon-tick-full'/>
        </div>
        <div className='app-exercise__previous__text'>
          <p>En la última sesión hiciste con {weight} kgs {repetitionsText} repeticiones</p>
        </div>
      </div>
    );
  }
}

PreviousTrainingSession.propTypes = {
  repetitions: PropTypes.array.isRequired,
  weight: PropTypes.number.isRequired,
};

export default PreviousTrainingSession;
