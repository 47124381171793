export const ROUTE_HOME = '/';
export const ROUTE_ACADEMY = '/academy';
export const ROUTE_ACADEMY_POST = '/academy/post';
export const ROUTE_FEEDING = '/feeding';
export const ROUTE_LOGIN = '/login';
export const ROUTE_RECOVER_PASSWORD = '/recover-password';
export const ROUTE_RESTORE_PASSWORD = '/restore-password';
export const ROUTE_MY_ACCOUNT = '/my-account';
export const ROUTE_NOTIFICATIONS = '/notifications';
export const ROUTE_RM_CALCULATOR = '/rm-calculator';
export const ROUTE_SESSION = '/session';
export const ROUTE_STATISTICS = '/statistics';
export const ROUTE_STATISTICS_BRAND = '/statistics/brand';
export const ROUTE_STATISTICS_EFFORT_CONTROL = '/statistics/effort-control';
export const ROUTE_STATISTICS_PERIMETER = '/statistics/perimeter';
export const ROUTE_STATISTICS_VOLUME_CONTROL = '/statistics/volume-control';
export const ROUTE_STATISTICS_WEIGHT = '/statistics/weight';
export const ROUTE_TICKETS = '/ticket';
export const ROUTE_TICKETS_EDIT = '/ticket/edit';
export const ROUTE_TICKETS_NEW = '/ticket/new';
export const ROUTE_TRAINING = '/training';
export const ROUTE_WITH_YOU = '/with-you';
export const ROUTE_MACROCYCLE = '/macrocycle';
export const ROUTE_MAXIMUM_REPETITIONS_10 = '/10rm';
