import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  ROUTE_ACADEMY,
  ROUTE_FEEDING,
  ROUTE_HOME,
  ROUTE_STATISTICS,
  ROUTE_TRAINING
} from '../../constants/routes';

class Menu extends Component {
  constructor(props) {
    super(props);

    const {
      from,
      user,
    } = props;

    this.state = {
      from,
      user,
    };
  }

  render() {
    const { from, user } = this.state;
    const { p } = user;

    return (
      <div className='container'>
        <div className='nav-app'>
          <div className='nav-app__list'>
            <a
              href={ROUTE_HOME}
              className={from === 'home' ? 'nav-app__item active' : 'nav-app__item'}
            >
              <i className='icon-myacount'>{''}</i>
              <p className='nav-app__item-name'>Mi plan</p>
            </a>
            { p === 'gf_plan_premium' || p === 'gf_plan_pro' ?
              <React.Fragment>
                <a
                  href={ROUTE_STATISTICS}
                  className={from === 'statistics' ? 'nav-app__item active' : 'nav-app__item'}
                >
                  <i className='icon-statistics'>{''}</i>
                  <p className='nav-app__item-name'>Estadísticas</p>
                </a>
                <a
                  href={ROUTE_TRAINING}
                  className={from === 'training' ? 'nav-app__item active' : 'nav-app__item'}
                >
                  <i className='icon-training'>{''}</i>
                  <p className='nav-app__item-name'>Entrenamiento</p>
                </a>
              </React.Fragment>
              :
              ''
            }
            <a
              href={ROUTE_FEEDING}
              className={from === 'feeding' ? 'nav-app__item active' : 'nav-app__item'}
            >
              <i className='icon-food'>{''}</i>
              <p className='nav-app__item-name'>Alimentación</p>
            </a>
            <a
              href={ROUTE_ACADEMY}
              className={from === 'academy' ? 'nav-app__item active' : 'nav-app__item'}
            >
              <i className='icon-academy'>{''}</i>
              <p className='nav-app__item-name'>Academia</p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  from: PropTypes.string,
  user: PropTypes.shape({
    p: PropTypes.string.isRequired,
  }).isRequired,
};

export default Menu;
