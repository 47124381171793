import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ROUTE_SESSION} from '../../../constants/routes';
import LinearProgress from '@material-ui/core/LinearProgress';

class Loading extends Component {
  constructor(props) {
    super(props);

    const { number, sid } = props;

    this.state = {
      number,
      sid,
    };
  }

  render() {
    const { number, sid } = this.state;

    return (
      <div className='app-exercise'>
        <div className='app-exercise__intro'>
          <div className='container-app container-app-medium'>
            <div className='app-back'>
              <a href={`${ROUTE_SESSION}/${sid}`} className='app-back__link'>
                <i className='icon-arrow'>{''}</i>
                <p>Volver atrás</p>
              </a>
            </div>
            <div className='app-exercise__nav'>
              <p>Entrenamiento</p>
              <a href={`${ROUTE_SESSION}/${sid}`}>Ver ejercicio anterior</a>
            </div>
            <div className='app-exercise__title'>
              <h2 className='title-condensed'>{number} Ejercicio</h2>
            </div>
          </div>
        </div>
        <div className='container-app container-app-medium'>
          <LinearProgress />
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  number: PropTypes.string.isRequired,
  sid: PropTypes.string.isRequired,
};

export default Loading;
