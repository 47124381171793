import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Moment from 'moment';
import {ROUTE_SESSION} from '../../../constants/routes';

class Content extends Component {
  constructor(props) {
    super(props);

    const { current, session } = props;

    this.state = {
      current,
      session
    };
  }

  handleClickOpen = (event) => {
    event.preventDefault();

    let current = event.currentTarget;
    let parent = current.parentNode.parentNode;
    let classList = parent.classList;

    if (classList.contains('open')) {
      classList.remove('open');
    } else {
      classList.add('open');
    }
  }

  printStartDate = (timestamp, currentWeek, week) => {
    let date = Moment.unix(timestamp);
    let operation = 0;

    if (parseInt(currentWeek) === parseInt(week)) {
      date.startOf('isoWeek');
    } else if (parseInt(currentWeek) < parseInt(week)) {
      operation = week - currentWeek;
      date.add(operation, 'week').startOf('isoWeek');
    } else {
      operation = currentWeek - week;
      date.subtract(operation, 'week').startOf('isoWeek');
    }

    return date.format('DD-MM-YYYY');
  }

  printEndDate = (timestamp, currentWeek, week) => {
    let date = Moment.unix(timestamp);
    let operation = 0;

    if (parseInt(currentWeek) === parseInt(week)) {
      date.endOf('isoWeek');
    } else if (parseInt(currentWeek) < parseInt(week)) {
      operation = week - currentWeek;
      date.add(operation, 'week').endOf('isoWeek');
    } else {
      operation = currentWeek - week;
      date.subtract(operation, 'week').endOf('isoWeek');
    }

    return date.format('DD-MM-YYYY');
  }

  printDays = (data) => {
    let print = [];

    for (let i in data) {
      if (data.hasOwnProperty(i)) {
        const { id, name, day } = data[i];
        const isRelax = data[i].is_relax;
        const isFinished = data[i].is_finished;
        let css = 'app-training__week-content-day';
        css += isRelax === true ? ' day-rest' : '';
        css += isFinished === true ? ' completed' : '';

        print.push(
          <div
            key={id}
            className={css}
          >
            <a href={`${ROUTE_SESSION}/${id}`}
               className='app-training__week-content-link'
            >
              <div className='app-training__week-content-day-info'>
                <p className='day-number'>Día {day}</p>
                <p className='day-activity'>{name}</p>
              </div>
              <i className='icon-tick'>{''}</i>
            </a>
          </div>
        );
      }
    }

    return print;
  }

  printWeeks = () => {
    let print = [];
    const { current, session } = this.state;
    let currentWeek = current.week;
    const currentDay = current.day;
    const currentIsFinished = current.is_finished;
    const currentIsLastSessionMacrocycle = current.is_last_session_macrocycle;
    if (currentDay === 7 &&
      currentIsFinished === true &&
      currentIsLastSessionMacrocycle === false) {
      currentWeek++;
    }

    const timestamp = current.timestamp;

    for(let i in session) {
      if (session.hasOwnProperty(i)) {
        const { data, active } = session[i];
        const week = i;
        let css = 'app-training__week';
        css += active === true ? ' active' : '';
        css += currentWeek === parseInt(i) ? ' current open' : '';

        print.push(
          <div
            key={week}
            data-week={week}
            className={css}
          >
            <div className='app-training__week-head'>
              <div className='app-training__week-head-text'>
                <p className='week-number'>Semana {week}</p>
                <div className='week-date'>
                  <p>{ this.printStartDate(timestamp, currentWeek, week) }</p>
                  <span> / </span>
                  <p>{ this.printEndDate(timestamp, currentWeek, week) }</p>
                </div>
              </div>
              <div
                className='app-training__week-head-btn'
                onClick={ this.handleClickOpen }
              >
                <span>{''}</span>
                <span>{''}</span>
                { active === false ? <p>Todavía no disponible</p> : '' }
              </div>
            </div>
            {
              active === true ?
                <div className='app-training__week-content'>
                  { this.printDays(data) }
                </div>
                : ''
            }
          </div>
        );
      }
    }

    return print;
  }

  render() {
    return (
      <React.Fragment>
        <div className='container-app container-app-full'>
          <p className='h2 app-training__current'>Semana Actual:</p>
        </div>
        <div className='container-app container-app-full app-training__list'>
          <div className='app-training__weektitle'>
            <p className='h2 app-training__current'>Tu macrociclo completo:</p>
          </div>
          { this.printWeeks() }
        </div>
      </React.Fragment>
    );
  }
}

Content.propTypes = {
  current: PropTypes.shape({
    id: PropTypes.string.isRequired,
    week: PropTypes.number.isRequired,
    day: PropTypes.number.isRequired,
    timestamp: PropTypes.number.isRequired,
    is_finished: PropTypes.bool.isRequired,
    is_last_session_macrocycle: PropTypes.bool.isRequired,
  }).isRequired,
  session: PropTypes.object.isRequired,
};

export default Content;
