import React, {Component} from 'react';
import {Line, Bar} from 'react-chartjs-2';

class Graph extends Component {
  constructor(props) {
    super(props);

    const {
      data,
      options,
      type,
    } = props;

    const width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    const minWidthScreen = 700;

    this.state = {
      data,
      options,
      type,
      width,
      minWidthScreen
    };
  };

  render() {
    const {data, options, type, width, minWidthScreen} = this.state;

    let className = '',
      element;

    if(width < minWidthScreen) {
      options.maintainAspectRatio = false;
      className = 'chart-container';
    }

    if (type === 'bar') {
      element = <Bar ref='chart' data={data} options={options}/>;
    } else {
      element = <Line ref='chart' data={data} options={options}/>;
    }

    return (
      <div className={className}>
        {element}
      </div>
    );
  }
}

export default Graph;
