import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import CircularProgress from '@material-ui/core/CircularProgress';
import ComCoverPost from '../../components/ComCoverPost';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import TrainingNext from '../../components/TrainingNext';
import UserDashboard from '../../components/UserDashboard';
import Webinar from '../../components/Webinar';
import {ROUTE_FEEDING, ROUTE_WITH_YOU} from '../../constants/routes';

class Home extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      dataPost: [],
      lastWebinar: [],
      loading: true,
    };

    this.authService = new AuthService();
  };

  getAcademyPost = () => {
    const url = process.env.REACT_APP_URL_WORDPRESS;
    const { user } = this.state;
    const { appKey } = user;
    let result = [];
    let webinar = [];
    const numberWords = [];
    numberWords[1] = 'one';
    numberWords[2] = 'two';
    numberWords[3] = 'three';

    axios.get(url + '/wp-json/wp/v2/posts/?page=1&per_page=10', {
      params: {
        key: appKey
      }
    })
      .then(res => {
        let contPost = 1;
        res.data.forEach(item => {
          let categories = [];
          const date = new Date(item.date);
          item.categories.map((category) => (
            categories.push({
              id: category.category_id,
              name: category.category_name,
            })
          ));
          if (item.featured_img && contPost < 4) {
            result.push({
              id: item.id,
              slug: item.slug,
              date: item.acf.gf_cpt_post_content_type === 'webinar' ? item.acf.gf_cpt_post_webinar_date : date,
              title: item.title.rendered,
              img: item.featured_img,
              numPost: numberWords[contPost],
              categories: categories,
            });
          }
          if (item.acf.gf_cpt_post_content_type === 'webinar' && webinar.length === 0) {
            webinar.push({
              id: item.id,
              slug: item.slug,
              date: item.acf.gf_cpt_post_webinar_date,
              title: item.title.rendered,
              img: item.featured_img,
            });
          }
          contPost++;
        });

        this.setState({
          dataPost: result,
          lastWebinar: webinar,
          loading: false,
        })
      }).catch(err => {
      console.error(err);
    });
  }

  renderProgress = () => {
    const { token, user } = this.state;
    const { p } = user;

    return (
      <React.Fragment>
        <Header name='Mi Plan' from='home' user={user} token={token} />
        <Menu from='home' user={user} />
        <div className='app-myplan'>
          <div className='app-myplan__intro'>
            <div className='container-app'>
              <UserDashboard token={token} />
              { p === 'gf_plan_premium' || p === 'gf_plan_pro' ? <TrainingNext token={token} isHome={true} /> : ''}
            </div>
          </div>
          <div className='container-app'>
            <div className='placeholder__graphic'>
              <CircularProgress className='loading__progress'/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderComponent = () => {
    const { token, user } = this.state;
    const { p } = user;

    return (
      <React.Fragment>
        <Header name='Mi Plan' from='home' user={user} token={token} />
        <Menu from='home' user={user} />
        <div className='app-myplan'>
          <div className='app-myplan__intro'>
            <div className='container-app'>
              <UserDashboard token={token} />
              { p === 'gf_plan_premium' || p === 'gf_plan_pro' ? <TrainingNext token={token} isHome={true} /> : ''}
            </div>
          </div>
          <div className='container-app'>
            {this.state.lastWebinar.length > 0 ? <Webinar data={this.state.lastWebinar[0]}/> : ''}
            <div className='app-myplan__academy'>
              <div className='title-section'>
                <h2 className='title-caps'>Academia</h2>
                <span className='line'>{''}</span>
              </div>
              <div className='app-myplan__academy-link'>
                <p>No te pierdas las últimas novedades de la academia y mantente siempre informado</p>
                <Link to='/academy' className='btn'>Ir a la Academia</Link>
              </div>
              <div className='academy__home'>
                <div className='grid-container'>
                  {this.state.dataPost.map((post) => (<ComCoverPost key={post.id} data={post}/>))}
                </div>
              </div>
            </div>
            <div className='app-myplan__download'>
              <div className='app-myplan__download-info'>
                <div className='app-myplan__download-info-text'>
                  <h2 className='title-caps'>Lleva siempre GFIT COACH contigo</h2>
                  <span className='line'>{''}</span>
                  <p>Descarga GFIT COACH en tu móvil y olvídate del cuaderno para registrar tus entrenamientos</p>
                  <a href={ROUTE_WITH_YOU} className='btn'>Descargar</a>
                </div>
                <div className='app-myplan__download-info-logo'>
                  <img src='/assets/img/logo-footer.svg' alt=''/>
                </div>
              </div>
              <a href={ROUTE_WITH_YOU}>
                <div className='box-image'>
                  <div className='gradient'>{''}</div>
                  <img src='/assets/img/miplan-banner-desktop.jpg' alt=''/>
                </div>
              </a>
            </div>
            <div className='app-myplan__fitnesspal'>
              <div className='app-myplan__fitnesspal-info'>
                <div className='app-myplan__fitnesspal-info-text'>
                  <h2 className='title-caps'>Alimentación</h2>
                  <p>Aprende, controla y mejora tu nutrición a través del conteo de calorías y macros. ¿No sabes cómo? ¡Nosotros te ayudamos!</p>
                  <a href={ROUTE_FEEDING} className='btn'>Mejora tu alimentación</a>
                </div>
              </div>
              <a href={ROUTE_FEEDING}>
                <div className='box-image'>
                  <div className='gradient'>{''}</div>
                  <img src='/assets/img/mi_plan_banner_alimentacion.jpg' alt='Alimentación'/>
                </div>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.getAcademyPost();
  }

  render() {
    const { loading } = this.state;
    return loading === true ? this.renderProgress() : this.renderComponent();
  }
}

Home.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  Home,
  ['gf_plan_basic', 'gf_plan_premium', 'gf_plan_pro']
);
