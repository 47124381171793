import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatDatetime} from '../../../constants/common';
import TicketForm from '../../TicketForm';
import TicketDownload from '../../TicketDownload';

class Content extends Component {
  constructor(props) {
    super(props);

    const { history, token, ticket, update } = props;

    this.state = {
      history,
      token,
      ticket,
      update,
      image: 'https://via.placeholder.com/150x150.png?text=GFIT+COACH'
    };
  }

  printMessages = () => {
    const { token, ticket } = this.state;
    const { messages } = ticket;
    let print = [];

    for (let i in messages) {
      if (messages.hasOwnProperty(i)) {
        const {
          id,
          name,
          gender,
          text,
          link,
          createdAt,
        } = messages[i];
        const mediaImage = messages[i].image;
        const mediaVideo = messages[i].video;

        let { image } = this.state;
        if (gender === 'H') {
          image = '/assets/img/avatar-hombre.png';
        } else if (gender === 'M') {
          image = '/assets/img/avatar-mujer.png';
        }

        print.push(
          <div key={id} className='app-message__item'>
            <div className='app-message__item-user'>
              <img src={image} alt={name} />
              <div className='app-message__item-user-text'>
                <p className='name'>{name}</p>
                <p className='date'>{formatDatetime(createdAt)}</p>
              </div>
            </div>
            <div className='app-message__item-content'>
              <div className='message-text'>
                <p dangerouslySetInnerHTML={{__html: text}} />
                {
                  link === '' ?
                    ''
                    :
                    <a href={link}
                       title={link}
                       target='_blank'
                       rel='noopener noreferrer'
                    >
                      <i className='icon-link'>{''}</i>
                    </a>
                }
                {
                  mediaImage === '' ?
                    ''
                    :
                    <TicketDownload token={token}
                              ticketMessageId={id}
                              media={mediaImage}
                              icon='icon-image-full'
                    />
                }
                {
                  mediaVideo === '' ?
                    ''
                    :
                    <TicketDownload token={token}
                                    ticketMessageId={id}
                                    media={mediaVideo}
                                    icon='icon-play-full'
                    />
                }
              </div>
            </div>
          </div>
        );
      }
    }

    return print;
  }

  render() {
    const { history, token, ticket, update } = this.state;
    const { id, title, state } = ticket;
    const stateMachineName = ticket.state_machine_name;

    return (
      <React.Fragment>
        <div className='app-message__intro'>
          <p className='message-title h2' dangerouslySetInnerHTML={{__html: title}} />
          <p className='message-state'>Estado: {state}</p>
        </div>
        <div className='app-message__list'>
          {this.printMessages()}
        </div>
        {
          stateMachineName === 'gf_ticket_state_resolved' ?
            ''
            :
            <div className='app-message__form'>
              <p className='message-title h2'>Nuevo mensaje:</p>
              <TicketForm history={history}
                          token={token}
                          id={id}
                          update={update}
              />
            </div>
        }
      </React.Fragment>
    );
  }
}

Content.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  token: PropTypes.string.isRequired,
  ticket: PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    state_machine_name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
    messages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      createdAt: PropTypes.number.isRequired,
      updatedAt: PropTypes.number.isRequired,
    })).isRequired,
  }).isRequired,
  update: PropTypes.func.isRequired,
};

export default Content;
