import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';

class Modal extends Component {
  constructor(props) {
    super(props);

    const {
      handleCloseModal,
      handleSubmitFeedback
    } = props;

    this.state = {
      handleCloseModal,
      handleSubmitFeedback,
      message: 'He obtenido un bombeo grande, me ha costado esfuerzo acabar el ejercicio y he llegado con algo de agujetas a esta sesión.',
      modalDom: document.getElementById('app-modal'),
    };
  }

  handleClickClose = (event) => {
    event.preventDefault();

    const { handleCloseModal, modalDom } = this.state;

    const classList = modalDom.classList;
    if (classList.contains('app-modal')) {
      classList.remove('app-modal');
    }

    handleCloseModal();
  }

  handleClickSubmit = (event) => {
    event.preventDefault();

    const { handleCloseModal, modalDom, handleSubmitFeedback } = this.state;
    let feedback = document.getElementById('feedback').value;

    const classList = modalDom.classList;
    if (classList.contains('app-modal')) {
      classList.remove('app-modal');
    }

    handleSubmitFeedback(feedback);
    handleCloseModal();
  }

  handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    let message = '';

    if (parseInt(value) === -1) {
      message = 'He obtenido un bombeo muy grande, me ha costado mucho esfuerzo acabar el ejercicio y he llegado con agujetas a esta sesión.';
    } else if (parseInt(value) === 0) {
      message = 'He obtenido un bombeo grande, me ha costado esfuerzo acabar el ejercicio y he llegado con algo de agujetas a esta sesión.';
    } if (parseInt(value) === 1) {
      message = 'Apenas he obtenido bombeo, no me ha costado mucho esfuerzo acabar el ejercicio y he llegado plenamente recuperado a esta sesión.';
    }

    this.setState({
      message,
    });
  }

  render() {
    const { modalDom, message } = this.state;

    // Css assets
    const classList = modalDom.classList;
    classList.add('app-modal');

    return createPortal(
      <div className='app-modal__content'>
        <div className='app-modal__content-close' onClick={this.handleClickClose}>
          <i className='icon-plus-full'>{''}</i>
        </div>
        <div className='app-modal__content-text'>
          <p className='h2'>Feedback</p>
          <p className='text'>¿Cómo de recuperado te sientes después del último entrenamiento para afrontar este ejercicio?</p>
        </div>
        <div className='app-modal__content-form'>
          <form onSubmit={this.handleClickSubmit}>
            <div className='range-slider'>
              <div className='range-slider__text'>
                <span>-1</span>
                <span>0</span>
                <span>1</span>
              </div>
              <input id='feedback'
                     type='range'
                     min='-1'
                     max='1'
                     step='1'
                     defaultValue='0'
                     onChange={this.handleChange}
              />
            </div>
            <small className='text'>{message}</small>
            <input type='submit' value='Enviar' />
          </form>
        </div>
      </div>,
      modalDom
    );
  }
}

Modal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmitFeedback: PropTypes.func.isRequired,
};

export default Modal;
