import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ROUTE_LOGIN } from '../../constants/routes';
import AuthService from '../../services/AuthService';

const authRequired = (AuthComponent, granted = []) => {
  class AuthContainer extends Component {
    constructor(props) {
      super(props);

      const { history, match } = props;

      this.state = {
        history,
        match,
        user: null,
        token: null
      };

      this.authService = new AuthService();
    }

    logoutAction = () => {
      const { history } = this.state;
      this.authService.logout();
      history.replace(ROUTE_LOGIN);
    }

    componentDidMount = () => {
      if (this.authService.isLoggedIn() === false) {
        this.logoutAction();
        return;
      }

      /**
       * Evaluate keep alive
       */
      this.authService.keepAlive()
        .then(() => {
          try {
            /**
             * Evaluate profile
             */
            const user = this.authService.getProfile();
            const { p } = user;
            if (granted.indexOf(p) === -1) {
              this.logoutAction();
            }

            /**
             * Get and set token
             */
            const token = this.authService.getToken();
            this.setState({
              user,
              token
            });
          } catch (e) {
            this.logoutAction();
          }
        }).catch(e => {
          this.logoutAction();
        });
    }

    render = () => {
      const { history, match, user, token } = this.state;

      if (user !== null) {
        return (
          <AuthComponent history={history} match={match} user={user} token={token} />
        );
      }

      return null;
    }
  }

  AuthContainer.propTypes = {
    history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  };

  return AuthContainer;
};

export default authRequired;
