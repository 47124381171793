import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

class Statistics extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
    };

    this.authService = new AuthService();
  }

  render() {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Estadísticas' from='statistics' user={user} token={token} />
        <Menu from='statistics' user={user} />
        <div className='app-statistics'>
          <div className='app-statistics__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-statistics__intro-title'>
                <h2 className='title-condensed'>Controla el progreso que has
                  llevado desde que comenzaste a entrenar</h2>
                <p>A tu disposición está el registro de todos tus avances y
                  progresos a lo largo del tiempo usando GFITCOACH.</p>
                <Link to={{
                  pathname: '/rm-calculator',
                  state: {from: window.location.pathname}
                }} className='btn'><i
                  className='icon-calculator-2'></i> Calculadora RM</Link>
              </div>
              <div className='app-statistics__intro-image'>
                <img src='/assets/img/statistics-background-section.png'
                     alt='GFITCOACH'/>
              </div>
            </div>
          </div>
          <div className='container-app container-app-medium'>
            <div className='app-statistics__section'>
              <div className='app-statistics__section-intro'>
                <div className='title-section'>
                  <h2 className='title-caps'>Visualización y edición</h2>
                  <span className='line'></span>
                </div>
                <p>La evolución del peso y de los perímetros has de
                  introducirlas cada vez que te llegue una notificación a la
                  plataforma.</p>
              </div>
              <div className='app-statistics__section-list'>
                <Link to='/statistics/weight'
                      className='app-statistics__section-item'>
                  <div className='app-statistics__section-item-name'>
                    <i className='icon-weight'></i>
                    <p>Evolución del peso con IMC</p>
                  </div>
                  <div className='img-outside'>
                    <div className='img-inside'>
                      <div className='gradient'></div>
                      <img src='/assets/img/statistics_background_section_04.jpg'
                           alt='GFITCOACH'/>
                    </div>
                  </div>
                </Link>
                <Link to='/statistics/perimeter'
                      className='app-statistics__section-item'>
                  <div className='app-statistics__section-item-name'>
                    <i className='icon-perimeter'></i>
                    <p>Evolución de los perímetros</p>
                  </div>
                  <div className='img-outside'>
                    <div className='img-inside'>
                      <div className='gradient'></div>
                      <img src='/assets/img/statistics_background_section_05.jpg'
                           alt='GFITCOACH'/>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className='app-statistics__section'>
              <div className='app-statistics__section-intro'>
                <div className='title-section'>
                  <h2 className='title-caps'>Visualización</h2>
                  <span className='line'></span>
                </div>
                <p>Esta selección de gráficas se va completando con la
                  información que introduces al realizar tus ejercicios
                  diarios.</p>
              </div>
              <div className='app-statistics__section-list'>
                <Link to='/statistics/effort-control'
                      className='app-statistics__section-item'>
                  <div className='app-statistics__section-item-name'>
                    <i className='icon-effort'></i>
                    <p>Control de esfuerzo percibido</p>
                  </div>
                  <div className='img-outside'>
                    <div className='img-inside'>
                      <div className='gradient'></div>
                      <img src='/assets/img/statistics_background_section_01.jpg'
                           alt='GFITCOACH'/>
                    </div>
                  </div>
                </Link>
                <Link to='/statistics/brand'
                      className='app-statistics__section-item'>
                  <div className='app-statistics__section-item-name'>
                    <i className='icon-mark'></i>
                    <p>Registro de marcas</p>
                  </div>
                  <div className='img-outside'>
                    <div className='img-inside'>
                      <div className='gradient'></div>
                      <img src='/assets/img/statistics_background_section_02.jpg'
                           alt='GFITCOACH'/>
                    </div>
                  </div>
                </Link>
                <Link to='/statistics/volume-control'
                      className='app-statistics__section-item'>
                  <div className='app-statistics__section-item-name'>
                    <i className='icon-volume'></i>
                    <p>Control volumen entrenamiento</p>
                  </div>
                  <div className='img-outside'>
                    <div className='img-inside'>
                      <div className='gradient'></div>
                      <img src='/assets/img/statistics_background_section_03.jpg'
                           alt='GFITCOACH'/>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Statistics.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  Statistics,
  ['gf_plan_premium', 'gf_plan_pro']
);
