import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';
import Content from './Content';
import axios from 'axios';

class TicketDetail extends Component {
  constructor(props) {
    super(props);

    const { history, match, token } = props;

    this.state = {
      history,
      match,
      token,
      loading: true,
      ticket: null,
    };
  }

  getTicket = () => {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;
    const { id } = this.state.match.params;

    let config = {
      method: 'get',
      url: `${url}/ticket/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState({
          loading: false,
          ticket: data.ticket,
        });
      }).catch(() => {
        this.setState({
          loading: true,
          ticket: null,
        });
      });
  }

  update = () => {
    this.setState({
      loading: true,
    });
    this.getTicket();
  }

  componentDidMount() {
    this.getTicket();
  }

  render() {
    const { history, token, loading, ticket } = this.state;

    return loading === true ? <Loading /> : <Content history={history}
                                                     token={token}
                                                     ticket={ticket}
                                                     update={this.update} />;
  }
}

TicketDetail.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default TicketDetail;
