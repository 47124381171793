import {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class TicketNumber extends Component {
  constructor(props) {
    super(props);

    const { token } = props;

    this.state = {
      token,
      number: '?',
    };
  }

  transformData = (data) => {
    const { number } = data;

    return {
      number,
    };
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/ticket/number/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState(this.transformData(data));
      }).catch(() => {
        this.setState({
          number: 0,
        });
      });
  }

  render() {
    const { number } = this.state;

    return number;
  }
}

TicketNumber.propTypes = {
  token: PropTypes.string.isRequired,
};

export default TicketNumber;
