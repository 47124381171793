import React, {Component} from 'react';
import ReactPlayer from 'react-player/vimeo'
import PropTypes from 'prop-types';

class Media extends Component {
  constructor(props) {
    super(props);

    const { image, video, name } = props;

    this.state = {
      image,
      video,
      name,
      showImage: true,
      showVideo: false,
    }
  }

  handleClickOpen = () => {
    this.setState({
      showImage: false,
      showVideo: true,
    })
  }

  handleClickClose = () => {
    this.setState({
      showImage: true,
      showVideo: false,
    })
  }

  isVimeo = (str) => {
    let regex = /^(http:\/\/|https:\/\/)(vimeo\.com|player.vimeo\.com)\/([\w/]+)$/;

    return regex.test(str);
  }

  printImage = () => {
    const { image, video, name } = this.state;
    const pathImage = image === '' ? '/assets/img/login-desktop.jpg' : image;
    const pathVideo = this.isVimeo(video) === true ? video : '';

    return (
      <div className='box-image'>
        { pathVideo === '' ? <i className='icon-not-see'>{''}</i> : <i className='icon-play' onClick={this.handleClickOpen}>{''}</i> }
        <div className='img-outside'>
          <div className='img-inside'>
            <div className='gradient'>{''}</div>
            <img src={pathImage} alt={name} />
          </div>
        </div>
      </div>
    );
  }

  printVideo = () => {
    const { video } = this.state;

    return (
      <div className='box-video player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={video}
          controls={true}
          playing={true}
          width='100%'
          height='100%'
        />
      </div>
    );
  }

  render() {
    const { showImage, showVideo } = this.state;

    return (
      <div className='app-exercise__video'>
        { showImage === true ? this.printImage() : '' }
        { showVideo === true ? this.printVideo() : '' }
      </div>
    );
  }
}

Media.propTypes = {
  image: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Media;
