import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Content from './Content/';
import Loading from './Loading/';

class TrainingNext extends Component {
  constructor(props) {
    super(props);

    const { token, isHome } = props;

    this.state = {
      token,
      isHome,
      loading: true,
      mesocycle: 0,
      name: '',
      weeks: 0,
      week: 0,
      to: '',
      isRelax: false,
      isFinished: false,
    };
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/training/next/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState({
          loading: false,
          mesocycle: data.number_of_mesocycle,
          weeks: data.number_of_weeks,
          week: data.number_of_week,
          name: data.name_movement,
          to: data.session_to,
          isRelax: data.is_relax,
          isFinished: data.is_finished,
        });
      }).catch(error => {
        this.setState({
          loading: true,
          mesocycle: 0,
          name: '',
          weeks: 0,
          week: 0,
          to: '',
          isRelax: false,
          isFinished: false,
        });
      });
  }

  render() {
    const {
      isHome,
      loading,
      mesocycle,
      name,
      weeks,
      week,
      to,
      isRelax,
      isFinished,
    } = this.state;

    if (loading === true) {
      return <Loading />
    }

    return <Content
      isHome={isHome}
      mesocycle={mesocycle}
      name={name}
      weeks={weeks}
      week={week}
      to={to}
      isRelax={isRelax}
      isFinished={isFinished}
    />
  }
}

TrainingNext.propTypes = {
  token: PropTypes.string.isRequired,
  isHome: PropTypes.bool.isRequired,
};

export default TrainingNext;
