import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class TicketDownload extends Component {
  constructor(props) {
    super(props);

    const { token, ticketMessageId, media, icon } = props;

    this.state = {
      token,
      ticketMessageId,
      media,
      icon,
    };
  }

  saveData = (response, filename) => {
    const data = response.data;
    const mimeType = response.headers['content-type'];
    const a = document.createElement('a');
    const blob = new Blob([data],{ type: mimeType });
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  }

  handleClick = (e) => {
    e.preventDefault();
    const { token, ticketMessageId, media } = this.state;
    const mediaId = media.id;
    const mediaFile = media.file;
    const url = process.env.REACT_APP_URL_API;

    let config = {
      method: 'get',
      url: `${url}/ticket/${ticketMessageId}/media/${mediaId}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      responseType: 'blob',
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response;
        }

        throw Error(response.statusText);
      }).then(response => {
        this.saveData(response, mediaFile);
      }).catch((e) => {
        console.error('Ups! Algo ha ido mal', e);
      });
  }

  render() {
    const { icon } = this.state;

    return (
      <React.Fragment>
        <button onClick={this.handleClick}>
          <i className={icon}>{''}</i>
        </button>
      </React.Fragment>
    );
  }
}

TicketDownload.propTypes = {
  token: PropTypes.string.isRequired,
  ticketMessageId: PropTypes.string.isRequired,
  media: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  icon: PropTypes.string.isRequired,
};

export default TicketDownload;
