import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Graph from '../../components/Graph';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

class StatisticsVolumeControl extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      data: '',
      options: '',
      muscleGroups: [],
      muscle_group_filter: '',
      start_date: '',
      end_date: '',
      firstData: false,
      firstLoad: true,
      loading: true
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.authService = new AuthService();

  };

  render() {
    const {loading} = this.state;

    return loading === true ? this.renderProgress() : this.renderComponent();
  }

  componentDidMount() {
    this.getVolumeControls();
  }

  renderProgress = () => {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Control Volumen Entrenamiento' from='statistics' user={user} token={token} />
        <Menu from='statistics' user={user} />
        <div className='app-statistics'>
          <div className='app-statistic__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-back'>
                <Link to='/statistics' className='app-back__link'>
                  <i className='icon-arrow'></i>
                  <p>Volver atrás</p>
                </Link>
              </div>

              <div className='app-statistic__intro-title'>
                <h2 className='title-condensed'>Control volumen
                  entrenamiento</h2>
                <p>A través de la siguiente gráfica podrás visualizar el volumen de carga soportado por grupo muscular a lo largo del tiempo. Puedes filtrar tanto por fecha como por grupo muscular para hacer un exhaustivo seguimiento del volumen de entrenamiento.</p>
              </div>
            </div>
          </div>

          <div className='container-app container-app-medium'>
            <div className='app-statistic__filter'>
              <div className='app-statistic__filter-item'>
                <p>Fecha Inicio</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>
              <div className='app-statistic__filter-item'>
                <p>Fecha Fin</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>
              <div className='app-statistic__filter-item'>
                <p>Grupo muscular</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>
            </div>

            <div className='placeholder__graphic'>
              <CircularProgress className='loading__progress'/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderComponent() {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Control Volumen Entrenamiento' from='statistics' user={user} token={token} />
        <Menu from='statistics' user={user} />
        <div className='app-statistic'>
          <div className='app-statistic__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-back'>
                <Link to='/statistics' className='app-back__link'>
                  <i className='icon-arrow'></i>
                  <p>Volver atrás</p>
                </Link>
              </div>

              <div className='app-statistic__intro-title'>
                <h2 className='title-condensed'>Control volumen
                  entrenamiento</h2>
                <p>A través de la siguiente gráfica podrás visualizar el volumen de carga soportado por grupo muscular a lo largo del tiempo. Puedes filtrar tanto por fecha como por grupo muscular para hacer un exhaustivo seguimiento del volumen de entrenamiento.</p>
              </div>
            </div>
          </div>
          <div className='container-app container-app-medium'>
            {(this.state.firstData === true) ?
              <React.Fragment>
                <div className='app-statistic__filter'>
                  <div className='app-statistic__filter-item'>
                    <p>Fecha Inicio</p>
                    <div className='input-black'>
                      <input type='date' name='start_date' id=''
                             onChange={this.handleFilter}/>
                    </div>
                  </div>
                  <div className='app-statistic__filter-item'>
                    <p>Fecha Fin</p>
                    <div className='input-black'>
                      <input type='date' name='end_date' id=''
                             onChange={this.handleFilter}/>
                    </div>
                  </div>
                  <div className='app-statistic__filter-item'>
                    <p>Grupo muscular</p>
                    <div className='select-black'>
                      <select name='muscle_group_filter' id=''
                              onChange={this.handleFilter}>
                        <option value='no'>Ver todos</option>
                        {this.state.muscleGroups.map((muscleGroup) => (
                          <option key={muscleGroup.machineName}
                                  value={muscleGroup.machineName}>{muscleGroup.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='app-statistic__graphic'>
                  {(this.state.data !== '' && this.state.state === true) ?
                    <Graph data={this.state.data} options={this.state.options}/>
                    :
                    <div className='placeholder__empty full'>
                      <p className='placeholder__empty-pretitle'>Oops!</p>
                      <p className='placeholder__empty-title'>La busqueda que
                        has hecho no tiene contenido.</p>
                      <p className='placeholder__empty-text'>Prueba a <span>seleccionar otro grupo muscular</span> o
                        unas <span> fechas diferentes!</span></p>
                    </div>
                  }
                </div>
              </React.Fragment>
              :
              <div className='placeholder__empty full'>
                <p className='placeholder__empty-pretitle'>Oops!</p>
                <p className='placeholder__empty-title'> Todavía o hay contenido
                  disponible en esta gráfica</p>
                <p className='placeholder__empty-text'>Conforme vayas
                  introduciendo datos podrás visualizar aquí tu progresión en
                  los entrenamientos en función del grupo muscular.</p>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }

  getVolumeControls = (data) => {
    const url = process.env.REACT_APP_URL_API;
    const { user, token } = this.state;
    const { username } = user;
    let weeks = [];
    let muscleGroups = [];
    let results = [];
    let start_date = '';
    let end_date = '';
    let muscle_group_filter = '';
    let tableDataset = [];
    const colors = [
      'yellow',
      'green',
      'black',
      'pink',
      'blue',
      'red',
      'brown',
      'orange',
      'aquamarine1'
    ];
    let auxCont = 0;

    if (data && data.start_date !== undefined && data.start_date !== '') {
      start_date = data.start_date;
    } else if (this.state.start_date !== undefined && this.state.start_date !== '') {
      start_date = this.state.start_date;
    } else {
      start_date = 'no';
    }

    if (data && data.end_date !== undefined && data.end_date !== '') {
      end_date = data.end_date;
    } else if (this.state.end_date !== undefined && this.state.end_date !== '') {
      end_date = this.state.end_date;
    } else {
      end_date = 'no';
    }

    if (data && data.muscle_group_filter !== undefined && data.muscle_group_filter !== '') {
      muscle_group_filter = data.muscle_group_filter;
    } else if (this.state.muscle_group_filter !== undefined && this.state.muscle_group_filter !== '') {
      muscle_group_filter = this.state.muscle_group_filter;
    } else {
      muscle_group_filter = 'no';
    }

    axios.get(url + '/get_training_volume/u/'+username+'/mg/'+muscle_group_filter+'/sd/'+start_date+'/ed/'+end_date, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          for (let key in response.data.muscleGroups) {
            muscleGroups.push(response.data.muscleGroups[key].name);
            results[response.data.muscleGroups[key].name] = [];
          }
          for (let key in response.data.trainingVolumes) {
            let arrayAuxMuscleGroups = [];
            weeks.push(key);
            for (let subkey in response.data.trainingVolumes[key]) {
              arrayAuxMuscleGroups.push(subkey);
              results[subkey].push(response.data.trainingVolumes[key][subkey][0]);
            }
            muscleGroups.forEach(item => {
              if (!arrayAuxMuscleGroups.includes(item)) {
                results[item].push(0);
              }
            })
          }

          for (let key in results) {
            tableDataset.push({
              label: key,
              fill: false,
              lineTension: 0.1,
              backgroundColor: colors[auxCont],
              borderColor: colors[auxCont],
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: colors[auxCont],
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: colors[auxCont],
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: results[key]
            })
            auxCont++;
          }

          this.setState({
            data: {
              labels: weeks,
              datasets: tableDataset,
            },
            options: {
              scales: {
                yAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Carga',
                  }
                }],
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Semanas'
                  }
                }]
              }
            },
            muscleGroups: (this.state.firstLoad === true) ? response.data.muscleGroups : this.state.muscleGroups,
            state: (response.data.state === 'no_data') ? false : true,
            firstData: (this.state.firstLoad === true && response.data.state === 'no_data') ? false : true,
            firstLoad: false,
            loading: false,
          })
        } else {
          this.setState({
            data: '',
            options: '',
            state: false,
            firstData: (this.state.firstLoad === true) ? false : true,
            firstLoad: false,
            loading: false,
          })
        }
      }, error => {
        console.error(error);
      });
  }

  handleFilter = (event) => {
    event.preventDefault();
    let value = '';
    if (event.target.name === 'start_date' || event.target.name === 'end_date') {
      if (event.target.value === '') {
        value = 'no';
      } else {
        value = event.target.value;
      }
    } else {
      value = event.target.value;
    }
    const data = {[event.target.name]: value};
    this.setState({
      [event.target.name]: value,
    })
    this.getVolumeControls(data);
  }
}


StatisticsVolumeControl.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  StatisticsVolumeControl,
  ['gf_plan_premium', 'gf_plan_pro']
);
