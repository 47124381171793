import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const Loading = () => (
  <div className='progress-training'>
    <p className='title-caps'>Tu próximo entrenamiento</p>
    <div className='progress-training__next'>
      <div className='placeholder__text height-40'>
        <LinearProgress />
      </div>
    </div>
    <div className='progress-training__card'>
      <div className='progress-training__card-title'>
        <p className='progress-training__card-title-p'>Macrociclo</p>
        <div className='progress-training__card-title-current'>
          <p>1</p>
          <span>/</span>
          <p>2</p>
          <span>/</span>
          <p>3</p>
          <span>/</span>
          <p>4</p>
        </div>
      </div>
      <div className='progress-training__card-info'>
        <div className='placeholder__text height-40'>
          <LinearProgress />
        </div>
      </div>
    </div>
    <button className='btn progress-training__btn'>
      Cargando ...
    </button>
  </div>
);

export default Loading;
