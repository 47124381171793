import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

class NotFound extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
    };

    this.authService = new AuthService();
  }

  render() {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='404: Página no encontrada' user={user} token={token} />
        <Menu user={user} />
        <div className='app-error'>
          <div className='app-error__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-error__intro-title'>
                <h2 className='title-condensed'>OPPS...</h2>
                <p>Algo ha salido mal</p>
                <p>Lo sentimos, no hemos encontrado la pagina que buscas.</p>
              </div>
              <div className='app-error__intro-image'>
                <img src='/assets/img/feeding-background-section.png'
                     alt='GFITCOACH'/>
              </div>
            </div>
          </div>

          <div className='container-app container-app-medium'>
            <div className='app-error__content'>
              <p className='title'>Prueba a seguir navegando a través de la web o accede a tu plan.</p>
              <a href='/' className='btn'>Mi Plan</a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

NotFound.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  NotFound,
  ['gf_plan_basic', 'gf_plan_premium', 'gf_plan_pro']
);
