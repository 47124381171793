import React, {Component} from 'react';
import * as Moment from 'moment';
import {Link} from 'react-router-dom';

class ComCoverPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
    };
  };

  render = () => {
    let date = Moment(this.props.data.date);

    return (
      <div className={'post-' + this.props.data.numPost}>
        <div className='academy__item'>
          <Link to={{
            pathname: `/academy/post/${this.props.data.slug}`,
            state: {from: window.location.pathname}
          }} className='academy__item-link'>
            <div className='academy__item-text'>
              {this.props.data.categories.map((category) => (
                <p key={category.id}
                   className='academy__item-text-category'>{category.name}</p>
              ))}
              <span className='academy__item-text-line'>{''}</span>
              <p className='academy__item-text-date'>{date.format('DD-MM-YYYY')}</p>
            </div>
            <div className='academy__item-image'>
              <div className='img-outside'>
                <div className='img-inside'>
                  <div className='gradient'>{''}</div>
                  <div className='img-post-blog'>
                    <img src={this.props.data.img} alt={this.props.data.title}/>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default ComCoverPost;
