import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import {
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_MACROCYCLE,
  ROUTE_MAXIMUM_REPETITIONS_10,
  ROUTE_WITH_YOU,
} from '../../constants/routes';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import UserMyAccount from '../../components/UserMyAccount';

class MyAccount extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
    };

    this.authService = new AuthService();
    this.handleLogout = this.handleLogout.bind(this);
  };

  handleLogout = () => {
    const { history } = this.state;
    this.authService.logout();
    history.replace(ROUTE_LOGIN);
  }

  render() {
    const { token, user } = this.state;
    const { p } = user;

    return (
      <React.Fragment>
        <Header name='Mi Cuenta' user={user} token={token} />
        <Menu user={user} />
        <div className='app-settings'>
          <div className='container-app container-app-mini'>
            <div className='app-back'>
              <Link to={ROUTE_HOME} className='app-back__link'>
                <i className='icon-arrow'>{''}</i>
                <p>Volver atrás</p>
              </Link>
            </div>
            <div className='app-settings__list'>
              <UserMyAccount token={token} />
              {/*<a href='#0' className='app-settings__item new-block'>*/}
              {/*  <div className='app-settings__item-title'>*/}
              {/*    <p>Datos personales</p>*/}
              {/*  </div>*/}
              {/*  <div className='app-settings__item-icon'>*/}
              {/*    <i className='icon-arrow'>{''}</i>*/}
              {/*  </div>*/}
              {/*</a>*/}
              {
                p === 'gf_plan_premium' || p === 'gf_plan_pro' ?
                  <React.Fragment>
                    <a href={ROUTE_MACROCYCLE} className='app-settings__item new-block'>
                      <div className='app-settings__item-title'>
                        <p>Mi macrociclo</p>
                      </div>
                      <div className='app-settings__item-icon'>
                        <i className='icon-arrow'>{''}</i>
                      </div>
                    </a>
                    <a href={ROUTE_MAXIMUM_REPETITIONS_10} className='app-settings__item'>
                      <div className='app-settings__item-title'>
                        <p>Mis 10 RM</p>
                      </div>
                      <div className='app-settings__item-icon'>
                        <i className='icon-arrow'>{''}</i>
                      </div>
                    </a>
                  </React.Fragment>
                  :
                  ''
              }
              <a href={ROUTE_WITH_YOU} className={`app-settings__item${ p === 'gf_plan_basic' ? '' : ' new-block' }`}>
                <div className='app-settings__item-title'>
                  <p>GFIT COACH en el móvil</p>
                </div>
                <div className='app-settings__item-icon'>
                  <i className='icon-arrow'>{''}</i>
                </div>
              </a>
              <a href='https://gfitcoach.com/contacto/'
                 className='app-settings__item'
                 target='_blank'
                 rel='noopener noreferrer'
              >
                <div className='app-settings__item-title'>
                  <p>Contáctanos</p>
                </div>
                <div className='app-settings__item-icon'>
                  <i className='icon-arrow'>{''}</i>
                </div>
              </a>
              <a href='https://gfitcoach.com/preguntas-frecuentes/'
                 className='app-settings__item'
                 target='_blank'
                 rel='noopener noreferrer'
              >
                <div className='app-settings__item-title'>
                  <p>Preguntas frecuentes</p>
                </div>
                <div className='app-settings__item-icon'>
                  <i className='icon-arrow'>{''}</i>
                </div>
              </a>
              <a href='https://gfitcoach.com/mi-cuenta/'
                 className='app-settings__item new-block'
                 target='_blank'
                 rel='noopener noreferrer'
              >
                <div className='app-settings__item-title'>
                  <p>Mis datos de pago</p>
                </div>
                <div className='app-settings__item-icon'>
                  <i className='icon-arrow'>{''}</i>
                </div>
              </a>
              <a href='https://gfitcoach.com/politica-privacidad/'
                 className='app-settings__item'
                 target='_blank'
                 rel='noopener noreferrer'
              >
                <div className='app-settings__item-title'>
                  <p>Condiciones de compra</p>
                </div>
                <div className='app-settings__item-icon'>
                  <i className='icon-arrow'>{''}</i>
                </div>
              </a>
              <button onClick={this.handleLogout}
                      className='app-settings__item button new-block'
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

MyAccount.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  MyAccount,
  ['gf_plan_basic', 'gf_plan_premium', 'gf_plan_pro']
);
