import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

class Feeding extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      dataPost: [],
      lastWebinar: [],
      loading: true,
      CaloriesCalculated: false,
      formAge: 0,
      formHeight: 0,
      formWeight: 0,
      formSex: 0,
      formActivity: 0,
      MaintenanceCalories: 0,
      WinCalories: 0,
      LoseCalories: 0,
      VolumeProteins: 0,
      VolumeFats: 0,
      VolumeCarbohydrates: 0,
      DefinitionProteins: 0,
      DefinitionFats: 0,
      DefinitionCarbohydrates: 0
    };

    this.handleInputChange = this.handleInputChange.bind(this)
    this.calculateCalories = this.calculateCalories.bind(this);
    this.authService = new AuthService();
  };

  handleInputChange = (event) => {
    const { name, value} = event.target;

    this.setState({
      [name]: value,
    });
  };

  calculateCalories = (event) => {
    event.preventDefault();

    let result = 0;
    if (this.state.formSex === 'H') {
      result = (((9.99*this.state.formWeight)+(6.25*this.state.formHeight)-(4.92*this.state.formAge)-50)-200)*this.state.formActivity;
    } else {
      result = ((((9.99*this.state.formWeight)+(6.25*this.state.formHeight)-(4.92*this.state.formAge)-50)-200)*this.state.formActivity)-200;
    }

    let calcWinCalories = result+300;
    let calcLoseCalories = result-300;

    let calcVolumeProteins = this.state.formWeight*2.5;
    let calcVolumeFats = this.state.formWeight*1;
    let calcVolumeCarbos = (calcWinCalories-(calcVolumeProteins*4)-(calcVolumeFats*9))/4;
    let calcDefinitionProteins = this.state.formWeight*3;
    let calcDefinitionFats = this.state.formWeight*0.75;
    let calcDefinitionCarbohydrates = (calcLoseCalories-(calcDefinitionProteins*4)-(calcDefinitionFats*9))/4;

    this.setState({
      MaintenanceCalories: Math.round(result * 100) / 100,
      WinCalories: Math.round(calcWinCalories * 100) / 100,
      LoseCalories: Math.round(calcLoseCalories* 100) / 100,
      VolumeProteins: Math.round(calcVolumeProteins* 100) / 100,
      VolumeFats: Math.round(calcVolumeFats* 100) / 100,
      VolumeCarbohydrates: Math.round(calcVolumeCarbos* 100) / 100,
      DefinitionProteins: Math.round(calcDefinitionProteins* 100) / 100,
      DefinitionFats: Math.round(calcDefinitionFats* 100) / 100,
      DefinitionCarbohydrates: Math.round(calcDefinitionCarbohydrates* 100) / 100,
      CaloriesCalculated: true,
    })
  }

  render() {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Alimentación' from='feeding' user={user} token={token} />
        <Menu from='feeding' user={user} />
        <div className='app-food'>
          <div className='app-food__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-food__intro-title'>
                <h2 className='title-condensed'>Alimentación</h2>
                <p>Para realizar un correcto control de tu alimentación es necesario que conozcas la cantidad de calorías y el reparto de macronutrientes que debes consumir a diario. Para ello, en GFIT COACH ponemos a tu disposición nuestra propia calculadora de calorías y macronutrientes, y te recomendamos la descarga y utilización de la aplicación MyFitnessPal.</p>
                <p>En esta app, podrás introducir los alimentos consumidos en las distintas comidas diarias y la app te informará sobre las calorías y los macronutrientes consumidos a diario. Pero antes de ello, comprueba tanto las calorías como los macronutrientes que necesitas consumir a diario en la calculadora de GFIT COACH.</p>
                <a href='https://apps.apple.com/us/app/myfitnesspal/id341232718'
                   className='btn-img'
                   target='_blank'
                   rel='noopener noreferrer'
                >
                  <img src='/assets/img/ios-myfitnespal.svg' alt='GFITCOACH'/>
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.myfitnesspal.android'
                   className='btn-img'
                   target='_blank'
                   rel='noopener noreferrer'
                >
                  <img src='/assets/img/android-myfitnespal.svg' alt='GFITCOACH'/>
                </a>
              </div>
              <div className='app-food__intro-image'>
                <img src='/assets/img/feeding-background-section.png'
                     alt='GFITCOACH'/>
              </div>
            </div>
          </div>
          <div className='container-app container-app-medium'>
            <div className='app-food__form'>
              <form onSubmit={this.calculateCalories}>
                <div className='app-food__form-item'>
                  <div className='app-food__form-title'>
                    <div className='title-section'>
                      <h2 className='title-caps'><i className='icon-food'>{''}</i>Calculadora de calorías</h2>
                      <span className='line'>{''}</span>
                    </div>
                    <p>Completa el siguiente formulario y conoce el consumo de calorías recomendado para cumplir tus objetivos. En MyFitnessPal también podrás calcular las calorías, pero en GFIT COACH hemos afinado mucho más la calculadora, así que, confía en nosotros y ten en cuenta los resultados de esta calculadora en lugar de los de MyFitnessPal.</p>
                  </div>
                  <div className='app-food__form-input'>
                    <div className='input-label'>
                      <input
                        id='formage'
                        type='number'
                        name='formAge'
                        onChange={this.handleInputChange}
                        required
                        />
                      <label id='label'>Edad </label>
                      <i className='icon-mark-full'>{''}</i>
                    </div>
                    <div className='input-label'>
                      <input
                          id='formheight'
                          type='number'
                          step='any'
                          name='formHeight'
                          onChange={this.handleInputChange}
                          required
                        />
                      <label id='label'>Altura</label>
                      <i className='icon-perimeter-full'>{''}</i>
                    </div>
                    <div className='input-label'>
                      <input
                          id='formweight'
                          type='number'
                          step='any'
                          name='formWeight'
                          onChange={this.handleInputChange}
                          required
                        />
                      <label id='label'>Peso</label>
                      <i className='icon-weight-full'>{''}</i>
                    </div>
                    <div className='select'>
                      <select name='formSex' onChange={this.handleInputChange}>
                        <option value='H'>Hombre</option>
                        <option value='M'>Mujer</option>
                      </select>
                    </div>
                    <div className='select'>
                      <select name='formActivity' onChange={this.handleInputChange}>
                        <option value='1.2'>Sedentario</option>
                        <option value='1.4'>Ligera actividad física</option>
                        <option value='1.6'>3 veces a la semana</option>
                        <option value='1.8'>4-5 veces a la semana</option>
                        <option value='2'>Diariamente</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='app-food__form-btn'>
                  <input type='submit' value='Enviar datos'/>
                </div>
              </form>

              {(this.state.CaloriesCalculated !== false) ?
                <div className='app-food__list'>
                  <table>
                    <tr>
                      <th>Objetivo</th>
                      <th>Calorias</th>
                    </tr>
                    <tr>
                      <td>Mantenimiento</td>
                      <td>{this.state.MaintenanceCalories} kcal</td>
                    </tr>
                    <tr>
                      <td>Ganar masa muscular</td>
                      <td>{this.state.WinCalories} kcal</td>
                    </tr>
                    <tr>
                      <td>Perder grasa corporal</td>
                      <td>{this.state.LoseCalories} kcal</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th></th>
                      <th>Volumen</th>
                      <th>Definición</th>
                    </tr>
                    <tr>
                      <td>Proteínas</td>
                      <td>{this.state.VolumeProteins} gr</td>
                      <td>{this.state.DefinitionProteins} gr</td>
                    </tr>
                    <tr>
                      <td>Grasas</td>
                      <td>{this.state.VolumeFats} gr</td>
                      <td>{this.state.DefinitionFats} gr</td>
                    </tr>
                    <tr>
                      <td>Carboihdratos</td>
                      <td>{this.state.VolumeCarbohydrates} gr</td>
                      <td>{this.state.DefinitionCarbohydrates} gr</td>
                    </tr>
                  </table>
                </div>
                :
                <div className='app-food__list'>
                  <table>
                    <tr>
                      <th>Objetivo</th>
                      <th>Calorias</th>
                    </tr>
                    <tr>
                      <td>Mantenimiento</td>
                      <td>--</td>
                    </tr>
                    <tr>
                      <td>Ganar masa muscular</td>
                      <td>--</td>
                    </tr>
                    <tr>
                      <td>Perder grasa corporal</td>
                      <td>--</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th></th>
                      <th>Volumen</th>
                      <th>Definición</th>
                    </tr>
                    <tr>
                      <td>Proteínas</td>
                      <td>--</td>
                      <td>--</td>
                    </tr>
                    <tr>
                      <td>Grasas</td>
                      <td>--</td>
                      <td>--</td>
                    </tr>
                    <tr>
                      <td>Carboihdratos</td>
                      <td>--</td>
                      <td>--</td>
                    </tr>
                  </table>
                </div>
              }
            </div>
          </div>
          <div className='container-app container-app-medium'>
            <div className='app-food__banner'>
              <div className='app-food__banner-info'>
                <div className='app-food__banner-info-text'>
                  <p> En MyFitnessPal podrás controlar tu consumo diario de calorías y macros. Y, además, ¡te invitamos a seguir nuestro perfil para probar todas nuestras recetas fit!</p>
                  <a href='https://apps.apple.com/us/app/myfitnesspal/id341232718'
                     className='btn-img'
                     target='_blank'
                     rel='noopener noreferrer'
                  >
                    <img src='/assets/img/ios-myfitnespal.svg' alt='GFITCOACH'/>
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.myfitnesspal.android'
                     className='btn-img'
                     target='_blank'
                     rel='noopener noreferrer'
                  >
                    <img src='/assets/img/android-myfitnespal.svg' alt='GFITCOACH'/>
                  </a>
                </div>
              </div>
              <div className='box-image'>
                <div className='gradient'>{''}</div>
                <img src='/assets/img/alimentacion_banner.jpg' alt='MyFitnessPal'/>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Feeding.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  Feeding,
  ['gf_plan_basic', 'gf_plan_premium', 'gf_plan_pro']
);
