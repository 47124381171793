import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  ROUTE_SESSION,
  ROUTE_RM_CALCULATOR,
} from '../../../constants/routes';
import {
  checkNumber,
  checkProperties,
  getProperties,
  multiple
} from '../../../constants/common';
import Media from '../Media';
import Modal from '../Modal';
import {Link} from 'react-router-dom';

class ContentFirst extends Component {
  constructor(props) {
    super(props);

    const {
      pathname,
      number,
      session,
      movementOfTraining,
      movement,
      previousTrainingSession,
      handleSubmitFirst,
    } = props;

    this.state = {
      pathname,
      number,
      session,
      movementOfTraining,
      movement,
      previousTrainingSession,
      handleSubmitFirst,
      loadingMedia: true,
      practice: null,
      weight: 0,
      seriesHtml: null,
      seriesValues: [],
      realWeight: 0,
      comment: '',
      continueStartAction: false,
      displaySecondPart: false,
      continueSubmitAction: false,
      sendAction: false,
      feedback: 0,
      modalIsOpen: false,
    };

    this.handleSubmitFeedback = this.handleSubmitFeedback.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmitStart = this.handleSubmitStart.bind(this);
    this.handleSubmitFinish = this.handleSubmitFinish.bind(this);
    this.handleChangeMaximumRepetition = this.handleChangeMaximumRepetition.bind(this);
    this.handleChangePractice = this.handleChangePractice.bind(this);
    this.handleChangeSeries = this.handleChangeSeries.bind(this);
    this.handleChangeRealWeight = this.handleChangeRealWeight.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.checkRequiredInputs = this.checkRequiredInputs.bind(this);
  }

  calculateWeight = () => {
    let { practice } = this.state;

    if (practice === null) {
      const { movement } = this.state;
      const { practices } = movement;
      practice = practices[0];
    }

    const maximumRepetition = practice.maximum_repetition;
    const maximumRepetitionValue = maximumRepetition.value;
    this.setState({
      weight: maximumRepetitionValue,
      realWeight: maximumRepetitionValue,
      continueStartAction: maximumRepetitionValue !== 0,
    });
  }

  calculate = () => {
    const { movement } = this.state;
    const movementMachineName = movement.machine_name;
    if (movementMachineName === 'gf_abd') {
      return 0;
    }

    const { weight, movementOfTraining } = this.state;
    const { quotient } = movementOfTraining;
    const { progressions } = quotient;
    const { value } = progressions;

    return multiple(weight, value);
  }

  handleSubmitFeedback = (feedback) => {
    this.setState({
      feedback,
      modalIsOpen: false,
      displaySecondPart: true,
    });
  };

  handleOpenModal = () => {
    this.setState({
      modalIsOpen: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  handleSubmitStart = (event) => {
    event.preventDefault();

    const { previousTrainingSession } = this.state;
    if (previousTrainingSession === null) {
      this.setState({
        displaySecondPart: true,
      });
    } else {
      this.handleOpenModal();
    }
  }

  handleSubmitFinish = (event) => {
    event.preventDefault();

    const {
      seriesHtml,
      comment,
      realWeight,
      session,
      movementOfTraining,
      weight,
      feedback,
      handleSubmitFirst,
    } = this.state;
    let { practice } = this.state;
    if (practice === null) {
      const { movement } = this.state;
      const { practices } = movement;
      practice = practices[0];
    }
    const idSession = session.id;
    const idMovementOfTraining = movementOfTraining.id;
    const idPractice = practice.id;
    const seriesValues = getProperties(seriesHtml);
    const load = this.calculate();

    this.setState({
      continueSubmitAction: false,
      sendAction: true,
    });

    try {
      handleSubmitFirst({
        sid: idSession,
        tid: idMovementOfTraining,
        pid: idPractice,
        feedback,
        weight_maximum_repetition: parseInt(weight),
        weight_load: load,
        comment: comment,
        series: seriesValues,
        real_weight: realWeight,
      });
    } catch (e) {
      this.setState({
        loadingMedia: true,
        practice: null,
        weight: 0,
        realWeight: 0,
        comment: '',
        continueStartAction: false,
        displaySecondPart: false,
        continueSubmitAction: false,
        sendAction: false,
        feedback: 0,
        modalIsOpen: false,
      });
    }
  }

  handleChangeMaximumRepetition = (event) => {
    const { value } = event.target;

    this.setState({
      weight: value === '' ? 0 : value,
      continueStartAction: value !== '' && parseInt(value) > 1,
    });
  }

  handleChangePractice = (event) => {
    const { value } = event.target;
    const { movement } = this.state;
    const { practices } = movement;
    const filtered = practices.filter(o => o.id === value, value);
    const practice = filtered[0];

    const maximumRepetition = practice.maximum_repetition;
    const maximumRepetitionValue = maximumRepetition.value;
    this.setState({
      loadingMedia: false,
      practice: practice,
      weight: maximumRepetitionValue,
      continueStartAction: maximumRepetitionValue !== 0,
    });
  }

  checkRequiredInputs = () => {
   const { seriesHtml, realWeight } = this.state;

   let okSeries = (seriesHtml !== null && checkProperties(seriesHtml));
   let okWeight = (realWeight !== null && checkNumber(realWeight));

    this.setState({
      continueSubmitAction: okSeries && okWeight,
    });
  }

  handleChangeRealWeight = (event) => {
    const { value } = event.target;

    this.setState({
      realWeight: value.replace(',', '.')
    });
  };

  handleChangeComment = (event) => {
    const { value } = event.target;

    this.setState({
      comment: value
    });
  }

  handleChangeSeries = (event) => {
    event.preventDefault();

    let seriesHtml = document.getElementsByClassName('series');
    let seriesValues = [];

    for (let s in seriesHtml) {
      seriesValues.push(s.value);
    }

    this.setState({
      seriesHtml: seriesHtml,
      seriesValues: seriesValues
    });
  }

  printSeries = () => {
    const { movementOfTraining, previousTrainingSession } = this.state;
    let numberSeries = movementOfTraining.number_series;
    if (previousTrainingSession !== null) {
      const { rating } = previousTrainingSession;
      numberSeries = numberSeries + rating;
    }
    let print = [];

    for (let i = 1; i <= numberSeries; i++) {
      print.push(
        <div key={i} className='app-exercise__userform-input'>
          <p>Número de repeticiones que has hecho en la serie {i}</p>
          <div className='input-label'>
            <input className={`series serie-${i}`}
                   type='number'
                   required
                   onChange={this.handleChangeSeries}
            />
            <label>Repeticiones serie {i}</label>
            <i className='icon-mark-full'>{''}</i>
          </div>
        </div>
      );
    }

    return print;
  }

  printRealWeight = (load) => {
    return(
      <div  className='
        app-exercise__userform
        app-exercise__userform--isolated
        app-exercise__userform--isolated--top
      '>
        <div key="realWeight" className={`app-exercise__userform-input`}>
          <p>Carga real</p>
          <div className='input-label'>
            <input type='text'
                   required
                   id="realWeight"
                   defaultValue={ load }
                   onChange={ this.handleChangeRealWeight }
            />
            <label>Carga real con la que has realizado el ejercicio</label>
            <i className='icon-mark-full'>{''}</i>
          </div>
          <small>Para introducir decimales utiliza: '.'. Ejemplo: 25.5</small>
        </div>
      </div>
    );
  }

  printComment = () => {
    return (
      <div  className='
        app-exercise__userform
        app-exercise__userform--isolated
        app-exercise__userform--isolated--bottom
      '>
        <div key="comment" className='app-exercise__userform-input '>
          <p>Comentario</p>
          <div className='input-label'>
             <textarea
               placeholder=" "
               id="comment"
               maxLength="500"
               onChange={ this.handleChangeComment }
             />
            <label className="comment">¿Algo que comentar sobre el ejercicio?</label>
          </div>
        </div>
      </div>
    );
  }

  printPracticeName = () => {
    let { practice } = this.state;

    if (practice === null) {
      const { movement } = this.state;
      const { practices } = movement;
      practice = practices[0];
    }

    return practice.name;
  }

  printPracticesItems = () => {
    const { movement } = this.state;
    const { practices } = movement;
    let print = [];

    for (let i in practices) {
      if (practices.hasOwnProperty(i)) {
        const { id, machine_name, name } = practices[i];

        print.push(
          <option key={id} data-machine={machine_name} value={id}>{name}</option>
        );
      }
    }

    return print;
  }

  printPractices = () => {
    let { practice } = this.state;

    if (practice === null) {
      const { movement } = this.state;
      const { practices } = movement;
      practice = practices[0];
    }

    const practiceId = practice.id;

    return (
      <div className='select'>
        <select
          defaultValue={practiceId}
          onChange={this.handleChangePractice}>
          { this.printPracticesItems() }
        </select>
      </div>
    )
  }

  printMedia = () => {
    let { practice, loadingMedia } = this.state;

    if (practice === null) {
      const { movement } = this.state;
      const { practices } = movement;
      practice = practices[0];
    }

    const practiceImage = practice.image;
    const practiceVideo = practice.video;
    const practiceName = practice.name;

    if (loadingMedia === false) {
      return <CircularProgress />;
    }

    return <Media image={practiceImage}
                  video={practiceVideo}
                  name={practiceName} />
  }

  printModal = () => {
    const { modalIsOpen } = this.state;

    if (modalIsOpen === false) {
      return null;
    }

    return <Modal
      handleCloseModal={this.handleCloseModal}
      handleSubmitFeedback={this.handleSubmitFeedback}
    />
  }

  componentDidMount() {
    this.calculateWeight();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { practice, realWeight, seriesValues } = this.state;

    if (practice !== prevState.practice) {
      this.setState({
        loadingMedia: true,
      });
    }
    if (realWeight !== prevState.realWeight || seriesValues !== prevState.seriesValues) {
      this.checkRequiredInputs();
    }
  }

  render() {
    const {
      pathname,
      number,
      session,
      movementOfTraining,
      movement,
      previousTrainingSession,
      weight,
      continueStartAction,
      displaySecondPart,
      continueSubmitAction,
      sendAction,
    } = this.state;

    // Session assets
    const sessionId = session.id;

    // Movement assets
    const movementName = movement.name;
    const movementMachineName = movement.machine_name;

    // Movement of training assets
    let numberSeries = movementOfTraining.number_series;
    if (previousTrainingSession !== null) {
      const { rating } = previousTrainingSession;
      numberSeries = numberSeries + rating;
    }

    // Load
    const load = this.calculate();

    return (
      <React.Fragment>
        <div className='app-exercise'>
          <div className='app-exercise__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-back'>
                <a href={`${ROUTE_SESSION}/${sessionId}`} className='app-back__link'>
                  <i className='icon-arrow'>{''}</i>
                  <p>Volver atrás</p>
                </a>
              </div>
              <div className='app-exercise__nav'>
                <p>Entrenamiento</p>
                <a href={`${ROUTE_SESSION}/${sessionId}`}>Ver ejercicio anterior</a>
              </div>
              <div className='app-exercise__title'>
                <h2 className='title-condensed'>{number} {movementName}</h2>
              </div>
            </div>
          </div>
          {
            displaySecondPart === false ?
              <div className='container-app container-app-medium'>
                <div className='app-exercise__form-title'>
                  <p className='title-caps'><i className='alert'>{''}</i> Indicaciones</p>
                  {
                    movementMachineName === 'gf_abd' ?
                      <p>Antes de comenzar el ejercicio debes <strong>seleccionar la técnica o modalidad</strong> en la que vas a realizarlo.</p>
                      :
                      <p>Antes de comenzar el ejercicio debes <strong>seleccionar la técnica o modalidad</strong> en la que vas a realizarlo, e indicar cuál es el <strong>peso máximo con el que puedes hacer 10 repeticiones.</strong></p>
                  }
                </div>
                <form className='app-exercise__form' onSubmit={this.handleSubmitStart}>
                  <div className='app-exercise__form-inputs'>
                    <div className={`app-exercise__form-input${movementMachineName === 'gf_abd' ? ' abdomen' : ''}`}>
                      <p>Técnica de realización del ejercicio</p>
                      { this.printPractices() }
                    </div>
                    {
                      movementMachineName === 'gf_abd' ?
                        ''
                        :
                        <div className='app-exercise__form-input'>
                          <p>Peso máximo con el que haces 10 repeticiones</p>
                          <div className='input-rm'>
                            <div className='input-label'>
                              <input
                                type='number'
                                step='any'
                                required
                                value={ weight === 0 ? '' : weight }
                                onChange={this.handleChangeMaximumRepetition} />
                              <label>Peso máximo 10RM</label>
                              <small>Para introducir decimales utiliza: '.'. Ejemplo: 25.5</small>
                            </div>
                            <Link
                              className='input-rm-link'
                              to={{
                                pathname: ROUTE_RM_CALCULATOR,
                                state: {
                                  from: pathname,
                                  fromTraining: true,
                                },
                              }}
                            >
                              <i className='icon-calculator-2'>{''}</i> <span>Calcular</span>
                            </Link>
                          </div>
                        </div>
                    }
                  </div>
                  { this.printMedia() }
                  <div className='app-exercise__indications'>
                    <div className='app-exercise__indications-serie'>
                      <p className='type'>Series</p>
                      <p className='number'>{ numberSeries }</p>
                    </div>
                    <div className='app-exercise__indications-weight'>
                      <p className='type'>Carga</p>
                      <p className='number'>{ load === 0 ? '--' : load }</p>
                    </div>
                  </div>
                  <div className='app-exercise__form-submit'>
                    {
                      continueStartAction === false && movementMachineName !== 'gf_abd' ?
                        <input type='submit' value='Comenzar ejercicio' disabled />
                        :
                        <input type='submit' value='Comenzar ejercicio' />
                    }
                  </div>
                </form>
              </div>
              :
              <div className='container-app container-app-medium'>
                <div className='app-exercise__form completed'>
                  <div className='app-exercise__form-inputs'>
                    <div className={`app-exercise__form-input selected${movementMachineName === 'gf_abd' ? ' abdomen' : ''}`}>
                      <p>{ this.printPracticeName() }</p>
                    </div>
                    {
                      movementMachineName === 'gf_abd' ?
                        ''
                        :
                        <div className='app-exercise__form-input selected'>
                          <p>10 RM</p>
                          <p className='number'>{ weight }</p>
                        </div>
                    }
                  </div>
                  { this.printMedia() }
                  <div className='app-exercise__indications'>
                    <div className='app-exercise__indications-serie'>
                      <p className='type'>Series a realizar</p>
                      <p className='number'>{ numberSeries }</p>
                    </div>
                    <div className='app-exercise__indications-weight'>
                      <p className='type'>Carga</p>
                      <p className='number'>{ load === 0 ? '--' : load }</p>
                    </div>
                  </div>
                </div>
                <div className='app-exercise__form-title middle'>
                  <p className='title-caps'><i className='icon-notification-full'>{''}</i> Indicaciones</p>
                  <p>Una vez hayas comenzado el ejercicio debes ir <strong>indicando el número de repeticiones </strong>que te has hecho en cada una de las series.</p>
                  { movementMachineName === 'gf_abd' ?
                    <p>Mantén el ejercicio en un rango de 10 a 20 repeticiones.</p>
                    :
                    ''
                  }
                </div>
                <form className='app-exercise__userform' onSubmit={this.handleSubmitFinish}>
                  { movementMachineName === 'gf_abd' ? '' : this.printRealWeight(load) }
                  { this.printSeries() }
                  { this.printComment() }
                  <div className='app-exercise__userform-submit'>
                    {
                      continueSubmitAction === false ?
                        <input type='submit' value={sendAction === true ? 'Guardando...' : 'Finalizar Ejercicio'} disabled />
                        :
                        <input type='submit' value='Finalizar Ejercicio' />
                    }
                  </div>
                </form>
              </div>
          }
        </div>
        { this.printModal() }
      </React.Fragment>
    );
  }
}

ContentFirst.propTypes = {
  pathname: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    machine_name: PropTypes.string.isRequired,
    number_week: PropTypes.number.isRequired,
    number_weeks_mesocycle: PropTypes.number.isRequired,
    number_day: PropTypes.number.isRequired,
    training_cycle: PropTypes.number.isRequired,
    training_cycle_day: PropTypes.number.isRequired,
    is_relax: PropTypes.bool.isRequired,
    is_finished: PropTypes.bool.isRequired,
  }).isRequired,
  movementOfTraining: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number_series: PropTypes.number.isRequired,
    quotient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      progressions: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        number_weeks: PropTypes.number.isRequired,
        number_week: PropTypes.number.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  movement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    machine_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    practices: PropTypes.array.isRequired,
  }).isRequired,
  previousTrainingSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
  }),
  handleSubmitFirst: PropTypes.func.isRequired,
};

export default ContentFirst;
