import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {ROUTE_TRAINING} from '../../../constants/routes';

const Loading = () => (
  <React.Fragment>
    <div className='app-trainingday'>
      <div className='app-trainingday__intro'>
        <div className='container-app container-app-medium'>
          <div className='app-back'>
            <a href={ ROUTE_TRAINING } className='app-back__link'>
              <i className='icon-arrow'>{''}</i>
              <p>Volver atrás</p>
            </a>
          </div>
          <div className='app-trainingday__intro-title'>
            <h2 className='title-condensed'>Entrenamiento</h2>
          </div>
        </div>
      </div>
      <div className='container-app container-app-medium'>
        <LinearProgress />
      </div>
    </div>
  </React.Fragment>
);

export default Loading;
