import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  ROUTE_ACADEMY,
  ROUTE_HOME, ROUTE_STATISTICS,
  ROUTE_STATISTICS_BRAND,
  ROUTE_STATISTICS_PERIMETER,
  ROUTE_STATISTICS_WEIGHT,
  ROUTE_TICKETS,
} from '../../constants/routes';
import LinearProgress from '@material-ui/core/LinearProgress';
import {formatDate} from '../../constants/common';

class NotificationList extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      token,
    } = props;

    this.state = {
      history,
      token,
      loading: true,
      page: 1,
      total: 0,
      totalClient: 0,
      totalViewed: 0,
      notifications: {},
      count: 0,
    };

    this.resetState = this.resetState.bind(this);
    this.groupBy = this.groupBy.bind(this);
    this.transformData = this.transformData.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.printIcon = this.printIcon.bind(this);
    this.printLink = this.printLink.bind(this);
    this.handleClickAll = this.handleClickAll.bind(this);
    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleClickLoadMore = this.handleClickLoadMore.bind(this);
    this.printItems = this.printItems.bind(this);
    this.printData = this.printData.bind(this);
    this.renderComponent = this.renderComponent.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
  };

  resetState = () => {
    this.setState({
      loading: true,
      page: 1,
      total: 0,
      totalClient: 0,
      totalViewed: 0,
      notifications: {},
      count: 0,
    });
  }

  groupBy = (array, key) => {
    const { notifications } = this.state;
    let { count } = this.state;

    // Return the end result
    let result = array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );

      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      count++;
      return result;
    }, notifications);

    this.setState({
      count,
    });
    return result;
  };

  transformData = (data) => {
    const { items } = data.notifications;
    return this.groupBy(items, 'createdAt');
  }

  getNotifications = (page) => {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/notification/${page}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        const { total } = data.notifications;
        const totalClient = data.notifications.total_client;
        const totalViewed = data.notifications.total_viewed;

        this.setState({
          loading: false,
          total,
          totalClient,
          totalViewed,
          notification: this.transformData(data),
        });
      }).catch(() => {
        this.resetState();
      });
  }

  printIcon = (str) => {
    const icons = {
      'gf_notification_type_academy': 'icon-academy-access',
      'gf_notification_type_streaming': 'icon-academy-access',
      'gf_notification_type_query': 'icon-consulta',
      'gf_notification_type_progress': 'icon-recomendacion',
      'gf_notification_type_weight': 'icon-recomendacion',
      'gf_notification_type_score': 'icon-awward',
      'gf_notification_type_advice': 'icon-recomendacion',
    };

    return icons[str];
  }

  printLink = (str) => {
    const links = {
      'gf_notification_type_academy': ROUTE_ACADEMY,
      'gf_notification_type_streaming': ROUTE_ACADEMY,
      'gf_notification_type_query': ROUTE_TICKETS,
      'gf_notification_type_progress': ROUTE_STATISTICS_PERIMETER,
      'gf_notification_type_weight': ROUTE_STATISTICS_WEIGHT,
      'gf_notification_type_score': ROUTE_STATISTICS_BRAND,
      'gf_notification_type_advice': ROUTE_STATISTICS,
    };

    return links[str];
  }

  handleClickAll = (event) => {
    event.preventDefault();

    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'put',
      url: `${url}/notification/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 201) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(() => {
        this.resetState();
        this.getNotifications(1);
      }).catch(() => {
        this.resetState();
    });
  }

  handleClickItem = (event) => {
    event.preventDefault();

    const url = process.env.REACT_APP_URL_API;
    const { history, token } = this.state;
    const { type, state, client, key } = event.currentTarget.dataset;

    if (state === 'gf_notification_state_view') {
      history.replace(this.printLink(type));
      return true;
    }

    if (client === 'all') {
      history.replace(this.printLink(type));
      return true;
    }

    let config = {
      method: 'put',
      url: `${url}/notification/${key}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 201) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(() => {
        history.replace(this.printLink(type));
      }).catch(() => {
        this.resetState();
      });
  }

  handleClickLoadMore = (event) => {
    event.preventDefault();

    let { page } = this.state;
    page = page + 1;
    this.setState({
      page,
    });
    this.getNotifications(page);
  }

  printItems = (items) => {
    let print = [];

    for (let i in items) {
      if (items.hasOwnProperty(i)) {
        const {
          id,
          client,
          state,
          type,
          title,
          message,
        } = items[i];
        let css = 'app-notifications__item';
        css += state === 'gf_notification_state_view' || client === 'all' ? ' completed' : '';

        print.push(
          <a key={id}
             href={this.printLink(type)}
             onClick={this.handleClickItem}
             data-key={id}
             data-type={type}
             data-state={state}
             data-client={client}
             className={css}
          >
            <div className='app-notifications__item-content'>
              <div className='app-notifications__item-text'>
                <p className='title'><i className={this.printIcon(type)}>{''}</i> {title}</p>
                <p className='message'>{message}</p>
              </div>
            </div>
            <i className='icon-tick app-notifications__item-check'>{''}</i>
          </a>
        )
      }
    }

    return print;
  }

  printData = () => {
    const { notifications } = this.state;
    let print = [];

    for (let i in notifications) {
      if (notifications.hasOwnProperty(i)) {
        print.push(
          <div key={i} className='app-notifications__list'>
            <p className='date'>{formatDate(i)}</p>
            {this.printItems(notifications[i])}
          </div>
        );
      }
    }

    return print;
  }

  renderComponent = () => {
    const {
      page,
      total,
      totalClient,
      totalViewed,
      count
    } = this.state;

    if (total === 0) {
      return (
        <div className='app-notifications'>
          <div className='container container-mini'>
            <div className='app-back'>
              <a href={ROUTE_HOME} className='app-back__link'>
                <i className='icon-arrow'>{''}</i>
                <p>Volver atrás</p>
              </a>
            </div>
            <div className='app-notifications__content'>
              <p className='no-content'>Todavía no has recibido ninguna notificación.</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='app-notifications'>
        <div className='container container-mini'>
          <div className='app-back'>
            <a href={ROUTE_HOME} className='app-back__link'>
              <i className='icon-arrow'>{''}</i>
              <p>Volver atrás</p>
            </a>
            {
              totalViewed < totalClient ?
                <button onClick={this.handleClickAll} className='button-all-read'>Marcar todo como leído</button>
                :
                ''
            }
          </div>
          <div className='app-notifications__content'>
            { this.printData() }
          </div>
          <div className='app-notifications__meta'>
            <div className='info'>
              <p>1-{count} de {total}. Página {page}</p>
            </div>
            {
              count < total ?
                <div className='action'>
                  <button onClick={this.handleClickLoadMore} className='button-all-read'>Cargar más</button>
                </div>
                :
                ''
            }
          </div>
        </div>
      </div>
    );
  }

  renderLoading = () => {
    return (
      <div className='app-notifications'>
        <div className='container container-mini'>
          <div className='app-back'>
            <a href={ROUTE_HOME} className='app-back__link'>
              <i className='icon-arrow'>{''}</i>
              <p>Volver atrás</p>
            </a>
          </div>
          <div className='app-notifications__content'>
            <LinearProgress />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getNotifications(1);
  }

  render() {
    const { loading } = this.state;
    return loading === true ? this.renderLoading() : this.renderComponent();
  }
}

NotificationList.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default NotificationList;
