import React from 'react';
import * as Moment from 'moment';

export const checkProperties = objs => {
  for (let key in objs) {
    if (objs.hasOwnProperty(key)) {
      const { value } = objs[key];
      if (value === null || value === '' || parseInt(value) > 30 || parseInt(value) < 1) {
        return false;
      }
    }
  }

  return true;
}

export const checkNumber = number => {
  return (number !== null && number !== '' && isNaN(number) === false);
}

export const getProperties = objs => {
  let values = [];
  for (let key in objs) {
    if (objs.hasOwnProperty(key)) {
      const { value } = objs[key];
      if (value === null || value === '' || parseInt(value) > 30 || parseInt(value) < 1) {
        continue;
      }

      values.push(value);
    }
  }

  return values;
}

export const multiple = (weight, value) => {
  if (weight === 0) {
    return '--';
  }

  if (value === 0) {
    return weight;
  }

  let base = weight * value;
  return Math.round(base / 2.5) * 2.5;
}

export const printMessage = (send, success, error, title, message) => {
  let css = 'app-login__form-message';

  if (send === true) {
    css += ' send';
  } else if (success === true) {
    css += ' success';
  } else if (error === true) {
    css += ' error';
  }

  if (send === true || success === true || error === true) {
    return (
      <div className={css}>
        <p className='strong'>{title}</p>
        <p>{message}</p>
      </div>
    );
  }

  return '';
}

export const formatDatetime = (timestamp) => {
  const date = Moment.unix(timestamp);
  return date.format('DD-MM-YYYY H:m');
}

export const formatDate = (timestamp) => {
  const date = Moment.unix(timestamp);
  return date.format('DD-MM-YYYY');
}

export const formatDateSort = (timestamp) => {
  const date = Moment.unix(timestamp);
  return date.format('YYYYMMDD');
}
