import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {ROUTE_TICKETS_NEW} from '../../../constants/routes';

const Loading = () => (
  <div className='app-messages__content'>
    <div className='app-messages__list'>
      <LinearProgress />
    </div>
    <div className='app-messages__new'>
      <a href={ROUTE_TICKETS_NEW} className='btn'>Nueva consulta</a>
    </div>
  </div>
);

export default Loading;
