import React, {Component} from 'react';
import * as Moment from 'moment';
import {ROUTE_ACADEMY_POST} from '../../constants/routes';

class Webinar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
    };
  };

  render() {
    let date = Moment(this.props.data.date);
    let now = Moment(new Date());

    if (date.isAfter(now) === false) {
      return '';
    }

    return (
      <div className='app-myplan__banner'>
        <div className='app-myplan__banner-info'>
          <div className='app-myplan__banner-info-text'>
            <a href={`${ROUTE_ACADEMY_POST}/${this.props.data.slug}`} title='Próximo streaming'>
              <p className='date'>{date.format('DD-MM-YYYY')}</p>
              <p className='title'>Próximo streaming</p>
            </a>
            <span className='line'>{''}</span>
          </div>
          <div className='app-myplan__banner-info-icon'>
            <i className='icon-streaming'>{''}</i>
          </div>
        </div>
        <div className='box-image'>
          <div className='gradient'>{''}</div>
          <img src={this.props.data.img} alt={this.props.data.title}/>
        </div>
      </div>
    );
  }
}

export default Webinar;
