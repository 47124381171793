import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header from '../../../components/Header';
import Menu from '../../../components/Menu';
import LinearProgress from '@material-ui/core/LinearProgress';

class Loading extends Component {
  constructor(props) {
    super(props);

    const {
      user,
      token,
    } = props;

    this.state = {
      user,
      token,
    };
  }

  render() {
    const { token, user } = this.state;

    return (
      <React.Fragment>
        <Header name='Entrenamiento' from='training' user={user} token={token} />
        <Menu from='training' user={user} />
        <div className='app-training'>
          <div className='app-training__intro'>
            <div className='container-app'>
              <div className='app-training__intro-text'>
                <div className='app-training__intro-welcome'>
                  <p className='title-condensed'>
                    Bienvenido a tu entrenamiento personalizado con GFIT COACH
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-app container-app-full app-training__list'>
          <div className='app-training__weektitle'>
            <p className='h2 app-training__current'>Validando macrociclo:</p>
          </div>
          <LinearProgress className='app-training__week' />
        </div>
      </React.Fragment>
    );
  }
}

Loading.propTypes = {
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default Loading;
