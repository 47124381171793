import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';
import Content from './Content';
import axios from 'axios';

class TicketList extends Component {
  constructor(props) {
    super(props);

    const { token } = props;

    this.state = {
      token,
      loading: true,
      tickets: null,
    };
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/ticket/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState({
          loading: false,
          tickets: data.tickets,
        });
      }).catch(() => {
        this.setState({
          loading: true,
          tickets: null,
        });
      });
  }

  render() {
    const { loading, tickets } = this.state;

    return loading === true ? <Loading /> : <Content tickets={tickets} />;
  }
}

TicketList.propTypes = {
  token: PropTypes.string.isRequired,
};

export default TicketList;
