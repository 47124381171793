import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import MacrocycleList from '../MacrocycleList/MacrocycleList';

class MacrocycleForm extends Component {
  constructor(props) {
    super(props);

    const {
      token,
    } = props;

    this.state = {
      token,
      loading: true,
      macrocycle: '',
      gender: '',
      variant: '',
      trainingDays: '0',
      level: '',
      objective: '',
      areYouSure: false,
      macrocycleVariants:[],
      canConfirmNewMacrocycle: false,
      macrocycleVariantsLoaded: false,
      send: false,
      result: '',
      resultText: ''
    };

    this.handleClickAgree = this.handleClickAgree.bind(this);
    this.handleClickDisagree = this.handleClickDisagree.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkGender = this.checkGender.bind(this);
    this.checkTrainingDays = this.checkTrainingDays.bind(this);
    this.checkLevel = this.checkLevel.bind(this);
    this.checkObjective = this.checkObjective.bind(this);
    this.checkRestrictionsByGender = this.checkRestrictionsByGender.bind(this);
    this.checkMacrocycle = this.checkMacrocycle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.transformData = this.transformData.bind(this);
    this.loadMacrocycleVariants = this.loadMacrocycleVariants.bind(this);
    this.handleClickSelectVariant = this.handleClickSelectVariant.bind(this);
  }

  handleClickAgree = (event) => {
    event.preventDefault();

    this.setState({
      areYouSure: true,
      result: '',
      resultText: ''
    });
  }

  handleClickDisagree = (event) => {
    event.preventDefault();

    this.setState({
      areYouSure: false,
      macrocycleVariantsLoaded: false,
      canConfirmNewMacrocycle: false,
      variant: '',
      macrocycleVariants: [],
      result: '',
      resultText: ''
    });
  }

  handleClickSelectVariant = (event) => {
    event.preventDefault();

    this.setState({
      canConfirmNewMacrocycle: true,
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    let {
      macrocycleVariantsLoaded,
      canConfirmNewMacrocycle,
      areYouSure,
    } = this.state;

    if (name !== 'variant') {
      macrocycleVariantsLoaded = false;
      canConfirmNewMacrocycle = false;
      areYouSure = false;
    }

    this.setState({
      areYouSure: areYouSure,
      macrocycleVariantsLoaded: macrocycleVariantsLoaded,
      canConfirmNewMacrocycle: canConfirmNewMacrocycle,
      [name]: value,
    });
  }

  async loadMacrocycleVariants() {
    const url = process.env.REACT_APP_URL_API;
    const {
      token,
      gender,
      trainingDays,
      level,
      objective,
    } = this.state;

    if (
      this.checkGender() === false ||
      this.checkTrainingDays() === false ||
      this.checkLevel() === false ||
      this.checkObjective() === false ||
      this.checkRestrictionsByGender() === false ||
      this.checkMacrocycle() === false
    ) {
      this.setState({
        areYouSure: false,
      });

      return false;
    }

    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      params: {
        'gender': gender,
        'training_days': trainingDays,
        'level': level,
        'objective': objective,
      },
    };

    axios.get(`${url}/macrocycle/variants/list`, config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        const { variants } = data;

        let canConfirmNewMacrocycle = false;
        let variant = '';

        if (variants.length === 1) {
          const uniqueVariant = variants[0];
          const { id } = uniqueVariant;

          variant = id;
          canConfirmNewMacrocycle = true;
        }

        this.setState({
          macrocycleVariantsLoaded: true,
          macrocycleVariants: variants,
          variant: variant,
          canConfirmNewMacrocycle: canConfirmNewMacrocycle,
        });
    }).catch(() => {
      this.setState({
        areYouSure: false,
        macrocycleVariantsLoaded: false,
        canConfirmNewMacrocycle: false,
        variant: '',
        macrocycleVariants: [],
        result: 'error',
        resultText: 'Ups! algo ha ido mal'
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.areYouSure !== this.state.areYouSure) {
      if (true === this.state.areYouSure) {
        this.loadMacrocycleVariants();
      }
    }
  }

  checkGender = () => {
    const { gender } = this.state;

    if (gender !== 'H' && gender !== 'M') {
      this.setState({
        send: false,
        result: 'error',
        resultText: 'Ups! Hay un error en la selección del género',
      });

      return false;
    }

    return true;
  }

  checkTrainingDays = () => {
    const { trainingDays } = this.state;
    const trainingDaysNumber = parseInt(trainingDays);

    if (
      trainingDaysNumber !== 3 &&
      trainingDaysNumber !== 4 &&
      trainingDaysNumber !== 5 &&
      trainingDaysNumber !== 6
    ) {
      this.setState({
        send: false,
        result: 'error',
        resultText: 'Ups! Hay un error en la selección de los días de entrenamiento',
      });

      return false;
    }

    return true;
  }

  checkLevel = () => {
    const { level } = this.state;

    if (level !== 'I' && level !== 'A') {
      this.setState({
        send: false,
        result: 'error',
        resultText: 'Ups! Hay un error en la selección del nivel',
      });

      return false;
    }

    return true;
  }

  checkObjective = () => {
    const { objective } = this.state;

    if (
      objective !== 'EQ' &&
      objective !== 'PE' &&
      objective !== 'ES' &&
      objective !== 'HB' &&
      objective !== 'PI' &&
      objective !== 'GL'
    ) {
      this.setState({
        send: false,
        result: 'error',
        resultText: 'Ups! Hay un error en la selección del objetivo',
      });

      return false;
    }

    return true;
  }

  checkRestrictionsByGender = () => {
    const { gender, objective } = this.state;

    if (
      (gender === 'M' && objective === 'PE') ||
      (gender === 'H' && objective === 'GL')
    ) {
      this.setState({
        send: false,
        result: 'error',
        resultText: 'Ups! Hay un error en la selección del género y objetivo',
      });

      return false;
    }

    return true;
  }

  checkMacrocycle = () => {
    const {
      macrocycle,
      gender,
      trainingDays,
      level,
      objective,
    } = this.state;
    const newMacrocycle = gender + trainingDays + level + objective;

    if (macrocycle === newMacrocycle) {
      this.setState({
        send: false,
        result: 'error',
        resultText: 'Ups! Los datos enviados son los mismos que tienes actualmente',
      });

      return false;
    }

    return true;
  }

  checkMacrocycleVariant = () => {
    const { variant } = this.state;

    if ('' === variant) {
      this.setState({
        send: false,
        result: 'error',
        resultText: 'Ups! Hay un error en la variante del macrociclo',
      });

      return false;
    }

    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      send: true,
      result: 'info',
      resultText: 'Enviando petición...',
    });

    if (
      this.checkGender() === false ||
      this.checkTrainingDays() === false ||
      this.checkLevel() === false ||
      this.checkObjective() === false ||
      this.checkRestrictionsByGender() === false ||
      this.checkMacrocycle() === false ||
      this.checkMacrocycleVariant === false
    ) {
      return false;
    }

    const url = process.env.REACT_APP_URL_API;
    const {
      token,
      gender,
      trainingDays,
      level,
      objective,
      variant,
    } = this.state;
    let formData = new FormData();
    formData.append('gender', gender);
    formData.append('training_days', trainingDays);
    formData.append('level', level);
    formData.append('objective', objective);
    formData.append('variant', variant);

    let config = {
      method: 'post',
      url: `${url}/macrocycle/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: formData,
    };

    axios(config)
      .then(response => {
        if (response.status === 201) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
      const macrocycle = gender + trainingDays + level + objective;

        this.setState({
          loading: false,
          macrocycle,
          areYouSure: false,
          macrocycleVariantsLoaded: false,
          canConfirmNewMacrocycle: false,
          variant: '',
          macrocycleVariants: [],
          send: false,
          result: 'success',
          resultText: 'Los datos han sido guardados correctamente'
        });
      }).catch(error => {
        this.setState({
          loading: false,
          areYouSure: false,
          macrocycleVariantsLoaded: false,
          canConfirmNewMacrocycle: false,
          variant: '',
          macrocycleVariants: [],
          send: false,
          result: 'error',
          resultText: 'Ups! algo ha ido mal'
        });
      });
  }

  transformData = (data) => {
    const gender = data.gender;
    const trainingDays = data.training_days;
    const level = data.level;
    const objective = data.objective;
    const macrocycle = gender + trainingDays + level + objective;

    return {
      loading: false,
      macrocycle,
      gender,
      trainingDays,
      level,
      objective,
    };
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/macrocycle/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState(this.transformData(data));
      }).catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const {
      token,
      loading,
      gender,
      trainingDays,
      level,
      variant,
      objective,
      areYouSure,
      canConfirmNewMacrocycle,
      macrocycleVariantsLoaded,
      macrocycleVariants,
      send,
      result,
      resultText,
    } = this.state;

    return (
      <React.Fragment>
        <div className='macrocycle__content macrocycle__content--form'>
          <div className='macrocycle__header'>
            <h3 className='macrocycle__title'>Macrociclo</h3>
            <p className='macrocycle__text'>En esta sección puedes comprobar la información que aportaste a GFit Coach para personalizar tu macrociclo.</p>
            <p className='macrocycle__text macrocycle__text--alert'><strong>¡Atención! </strong>Modificar los datos implica iniciar un nuevo plan de entrenamiento desde cero.</p>
          </div>
          <div className='macrocycle__body'>
            <form className='macrocycle__form' onSubmit={this.handleSubmit}>
              {/* START: Question 1 */}
              <div className='macrocycle__form-group'>
                <label className='macrocycle__form-label'>Genero:</label>
                <div className='select'>
                  <select className='macrocycle__form-item'
                    name='gender'
                    value={gender}
                    onChange={this.handleChange}
                  >
                    <option value=''>Elige una opción</option>
                    <option value='M'>Mujer</option>
                    <option value='H'>Hombre</option>
                  </select>
                </div>
              </div>
              {/* END: Question 1 */}
              {/* START: Question 2 */}
              <div className='macrocycle__form-group'>
                <label className='macrocycle__form-label'>Días de entrenamiento a la semana:</label>
                <div className='select'>
                  <select className='macrocycle__form-item'
                          name='trainingDays'
                          value={trainingDays}
                          onChange={this.handleChange}
                  >
                    <option value='0'>Elige una opción</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                  </select>
                </div>
              </div>
              {/* END: Question 2 */}
              {/* START: Question 3 */}
              <div className='macrocycle__form-group'>
                <label className='macrocycle__form-label'>Nivel:</label>
                <div className='select'>
                  <select className='macrocycle__form-item'
                          name='level'
                          value={level}
                          onChange={this.handleChange}
                  >
                    <option value=''>Elige una opción</option>
                    <option value='I'>Intermedio</option>
                    <option value='A'>Avanzado</option>
                  </select>
                </div>
              </div>
              {/* END: Question 3 */}
              {/* START: Question 4 */}
              <div className='macrocycle__form-group'>
                <label className='macrocycle__form-label'>Objetivo:</label>
                <div className='select'>
                  <select className='macrocycle__form-item'
                    name='objective'
                    value={objective}
                    onChange={this.handleChange}
                  >
                    <option value=''>Elige una opción</option>
                    <option value='EQ'>Equilibrado</option>
                    <option value='PE'>Pectoral</option>
                    <option value='ES'>Espalda</option>
                    <option value='HB'>Hombros-Brazos</option>
                    <option value='PI'>Piernas</option>
                    <option value='GL'>Glúteo</option>
                  </select>
                </div>
              </div>
              {/* END: Question 4 */}
              {/* START: Submit */}
              {
                loading === true ?
                  ''
                  :
                  <div className='macrocycle__form-group macrocycle__form-group--submit'>
                    {
                      areYouSure === false ?
                        <React.Fragment>
                          <p className='macrocycle__form-question'>¿Estás de acuerdo en cambiar los datos?</p>
                          <button className='macrocycle__form-btn btn'
                                  type='button'
                                  disabled={
                                    '' === objective ||
                                    '' === gender ||
                                    '0' === trainingDays ||
                                    '' === level
                                  }
                                  onClick={this.handleClickAgree}
                          >
                            Confirmar
                          </button>
                        </React.Fragment>
                        :
                        <>
                        { canConfirmNewMacrocycle === true ?
                          <React.Fragment>
                            <p className='macrocycle__form-question'>¡Cuidado!
                              Los cambios no se pueden deshacer.</p>
                            {
                              send === false ?
                                <button
                                  className='macrocycle__form-btn btn macrocycle__form-btn--cancel'
                                  type='button'
                                  onClick={this.handleClickDisagree}
                                >
                                  Cancelar
                                </button>
                                :
                                ''
                            }
                            <button
                              className='macrocycle__form-btn btn macrocycle__form-btn--submit'
                              type='submit'
                              disabled={send}
                            >
                              Enviar
                            </button>
                          </React.Fragment>
                          :
                          <>
                            { macrocycleVariantsLoaded === true ?
                              <>
                                <p className='macrocycle__form-question'>Selecciona una variante</p>
                                <div className='macrocycle__form-group macrocycle__form-group--single'>
                                  <label className='macrocycle__form-label'>Variantes:</label>
                                  <div className='select'>
                                    <select className='macrocycle__form-item'
                                            name='variant'
                                            value={variant}
                                            onChange={this.handleChange}
                                    >
                                      <option value=''>Elige una opción</option>
                                      {macrocycleVariants.map(item => {
                                        return (<option key={item.id} value={item.id}>{item.name}</option>);
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <button
                                  className='macrocycle__form-btn btn macrocycle__form-btn--cancel'
                                  type='button'
                                  onClick={this.handleClickDisagree}
                                >
                                  Cancelar
                                </button>
                                <button
                                  className='macrocycle__form-btn btn'
                                  type='button'
                                  disabled={variant === ''}
                                  onClick={this.handleClickSelectVariant}
                                >
                                Seleccionar
                                </button>
                              </>
                              :
                              <p className='macrocycle__text macrocycle__text--alert macrocycle__text--alert--fullw'>
                                Cargando...
                              </p>
                            }
                          </>
                        }
                      </>
                    }
                  </div>
              }
              {/* END: Submit 4 */}
            </form>
            {
              result !== '' ?
                <div className={`macrocycle__result macrocycle__result--${result}`}>{ resultText }</div>
                :
                ''
            }
          </div>
        </div>
        { send === false ? <MacrocycleList token={token} /> : '' }
      </React.Fragment>
    );
  }
}

MacrocycleForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default MacrocycleForm;
