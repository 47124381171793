import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  ROUTE_LOGIN,
  ROUTE_RECOVER_PASSWORD,
} from '../../constants/routes';
import {printMessage} from '../../constants/common';

class LoginRestorePassword extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      match,
    } = props;

    this.state = {
      history,
      match,
      hash: '',
      email: '',
      emailDecode: '',
      send: false,
      success: false,
      error: false,
      isDisabled: false,
      password: '',
      rpassword: '',
      title: '',
      message: '',
      displayPassword: false,
      displayRepeatPassword: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickDisplayPassword = this.handleClickDisplayPassword.bind(this);
    this.handleClickDisplayRepeatPassword = this.handleClickDisplayRepeatPassword.bind(this);
  }

  isPasswordValid = (str) => {
    const re = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,20}$/;
    return re.test(str);
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {hash, email, password, rpassword} = this.state;

    if (password === '' || rpassword === '') {
      return;
    }

    if (this.isPasswordValid(password) === false || this.isPasswordValid(rpassword) === false) {
      this.setState({
        send: false,
        success: false,
        error: true,
        isDisabled: false,
        title: 'Ups! Contraseña poco segura',
        message: 'La contraseña debe tener entre 8 y 20 caracteres, al menos un dígito, una minúscula y una mayúscula.',
      });
      return;
    }

    if (password !== rpassword) {
      this.setState({
        send: false,
        success: false,
        error: true,
        isDisabled: false,
        title: 'Ups! Contraseñas mal introducidas',
        message: 'Las contraseñas que has escrito no coinciden.',
      });
      return;
    }

    this.setState({
      send: true,
      success: false,
      error: false,
      isDisabled: true,
      title: 'Enviando solicitud',
      message: 'Estamos procesando su petición... Por favor, espere.',
    });

    const url = process.env.REACT_APP_URL;
    let config = {
      method: 'post',
      url: `${url}/restore-password/`,
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        hash,
        email,
        password: btoa(password),
        rpassword: btoa(rpassword),
      },
    };

    axios(config)
      .then(response => {
        if (response.status === 201) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(() => {
        this.setState({
          send: false,
          success: true,
          error: false,
          isDisabled: false,
          password: '',
          rpassword: '',
          title: 'Tu contraseña ha sido restablecida',
          message: '¡Bien! Ahora que se ha restablecido tu contraseña, no hay excusa. ¡A seguir entrenando!'
        });
      }).catch((error) => {
        setTimeout(() => {
          this.setState({
            send: false,
            success: false,
            error: true,
            isDisabled: false,
            password: '',
            rpassword: '',
            title: 'Ups! Algo no ha ido bien',
            message: 'Ha ocurrido algo inesperado. ¡Vuelva a intentarlo! y recuerde que el enlace caduca en 30 minutos.',
          });
        }, 1000);
      });
  }

  handleClickDisplayPassword = (e) => {
    e.preventDefault();
    const { displayPassword } = this.state;

    this.setState({
      displayPassword: displayPassword === false,
    });
  }

  handleClickDisplayRepeatPassword = (e) => {
    e.preventDefault();
    const { displayRepeatPassword } = this.state;

    this.setState({
      displayRepeatPassword: displayRepeatPassword === false,
    });
  }

  componentDidMount() {
    const { match } = this.state;
    const { hash, email } = match.params;

    try {
      this.setState({
        hash,
        email,
        emailDecode: atob(email),
      });
    } catch (e) {
      const { history } = this.state;
      history.replace(ROUTE_RECOVER_PASSWORD);
    }
  }

  render = () => {
    const {
      send,
      success,
      error,
      password,
      rpassword,
      isDisabled,
      emailDecode,
      displayPassword,
      displayRepeatPassword,
      title,
      message,
    } = this.state;

    return (
      <div className='app-login'>
        <div className='container'>
          <div className='app-login__info'>
            <div className='app-login__logo'>
              <img src='/assets/img/logo-horizontal.svg' alt='GFIT Coach' />
            </div>
            <div className='app-login__form'>
              {printMessage(send, success, error, title, message)}
              <form onSubmit={this.handleSubmit}>
                <p>Crea una nueva contraseña para {emailDecode}</p>
                <div className='input__icon psw'>
                  <input type={displayPassword === true ? 'text' : 'password'}
                         name='password'
                         placeholder='Nueva contraseña'
                         value={password}
                         onChange={this.handleChange}
                  />
                  <i className='icon-lock'>{''}</i>
                  <i className={`icon-not-see${displayPassword === true ? ' open' : ''}`}
                     onClick={this.handleClickDisplayPassword}
                  >
                    {''}
                  </i>
                </div>
                <div className='input__icon psw'>
                  <input type={displayRepeatPassword === true ? 'text' : 'password'}
                         name='rpassword'
                         placeholder='Repite la contraseña'
                         value={rpassword}
                         onChange={this.handleChange}
                  />
                  <i className='icon-lock'>{''}</i>
                  <i className={`icon-not-see${displayRepeatPassword === true ? ' open' : ''}`}
                     onClick={this.handleClickDisplayRepeatPassword}
                  >
                    {''}
                  </i>
                </div>
                <input type='submit'
                       value='Guardar contraseña'
                       className='app-login__form-login'
                       disabled={isDisabled}
                />
                <Link to={{
                  pathname: ROUTE_LOGIN,
                  state: {from: window.location.pathname}
                }} className='a app-login__form-forgot'>Iniciar sesión</Link>
              </form>
            </div>
          </div>
          <div className='shadow'>{''}</div>
        </div>
      </div>
    )
  }
}

LoginRestorePassword.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      hash: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default LoginRestorePassword;
