import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const Loading = () => (
  <React.Fragment>
    <div className='container-app container-app-full'>
      <p className='h2 app-training__current'>Semana Actual:</p>
    </div>
    <div className='container-app container-app-full app-training__list'>
      <div className='app-training__weektitle'>
        <p className='h2 app-training__current'>Tu macrociclo completo:</p>
      </div>
      <LinearProgress className='app-training__week current' />
      <LinearProgress className='app-training__week' />
    </div>
  </React.Fragment>
);

export default Loading;
