import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import {ROUTE_TICKETS} from '../../constants/routes';
import Header from '../../components/Header';
import TicketForm from '../../components/TicketForm';
import TicketNumber from '../../components/TicketNumber';

class TicketNew extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
    };

    this.authService = new AuthService();
  };

  render() {
    const { history, user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Consultas' from='ticket' user={user} token={token} />
        <div className='app-messages'>
          <div className='container container-mini'>
            <div className='app-back'>
              <a href={ROUTE_TICKETS} className='app-back__link'>
                <i className='icon-arrow'>{''}</i>
                <p>Volver atrás</p>
              </a>
            </div>
            <div className='app-messages__content'>
              <div className='app-messages__intro'>
                <p className='message-title h2'>Realizar una nueva consulta</p>
                <p className='message-number'>Consultas restantes este mes: <span><TicketNumber token={token} /></span></p>
              </div>
              <TicketForm history={history} token={token} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TicketNew.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  TicketNew,
  ['gf_plan_pro']
);
