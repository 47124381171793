import React, {Component} from 'react';

class Perimeter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      options: '',
      perimeterExist: '',
      imgSrc: '',
      loading: true
    };
  };

  render() {
    return (
      <React.Fragment>
        {(this.props.data.machineName === 'gf_perimeter_front' || this.props.data.machineName === 'gf_perimeter_side'
          || this.props.data.machineName === 'gf_perimeter_back') ?
          <div className='drop-area'>
            <span className='drop-area__button'>
              <i className='icon-image'></i>
            </span>
            <span
              className='drop-area__message'>{(this.state.imgSrc !== '') ? this.state.imgSrc : this.props.data.type}</span>
            <input className='drop-area__hidden'
                   name={this.props.data.machineName}
                   id={this.props.data.machineName}
                   placeholder={this.state.imgSrc}
                   type='file' accept='image/x-png, image/jpeg, image/jpg'
                   onInput={this.onFileChange}
                   onChange={this.props.handleInputChange}
                   required
            />
          </div>
          :
          <div className='input-label'>
            <input type='number'
                   step='any'
                   name={this.props.data.machineName}
                   id={this.props.data.machineName}
                   onChange={this.props.handleInputChange}
                   required
            />
            <label id='label'
                   htmlFor={this.props.data.machineName}>{this.props.data.type}</label>
            <i className='icon-volume-full'></i>
          </div>
        }
      </React.Fragment>
    );
  }

  onFileChange = (e) => {
    this.setState({
      imgSrc: e.target.files[0].name,
    });
  }
}

export default Perimeter;
