import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

class AcademyPost extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      match,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      match,
      title: '',
      srcImage: '',
      srcVideo: '',
      fecha: '',
      content: '',
      contentAcf: '',
      contentAcfExtra: '',
      categories: '',
      tags: '',
      type: '',
      loading: true
    };

    this.youtubeVideo = React.createRef();
    this.authService = new AuthService();

  };

  render() {
    const {loading} = this.state;
    return loading === true ? this.renderProgress() : this.renderComponent();
  }

  componentDidMount() {
    this.getPost();
  }

  renderProgress = () => {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Academia' from='academy' user={user} token={token} />
        <Menu from='academy' user={user} />
        <div className='app-academypost'>
          <div className='container-app'>
            <div className='app-back'>
              <div className='app-back__link'>
                <i className='icon-arrow'></i>
                <p>Volver atrás</p>
              </div>
            </div>
            <div className='container-app-blog big'>
              <div className='app-academypost__tags'>
                <p className='app-academypost__tags-label'>
                  <div className='placeholder__text height-22 center'>
                    <LinearProgress/>
                  </div>
                  <div className='placeholder__text height-60 center'>
                    <LinearProgress/>
                  </div>
                </p>
              </div>
              <div className='app-academypost__img'>
                <div className='placeholder__graphic'>
                  <CircularProgress className='loading__progress'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderComponent = () => {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Academia' from='academy' user={user} token={token} />
        <Menu from='academy' user={user} />
        <div className='app-academypost'>
          <div className='container-app'>
            <div className='app-back'>
                <Link to='/academy' className='app-back__link'>
                  <i className='icon-arrow'></i>
                  <p>Volver atrás</p>
                </Link>
            </div>
            <div className='container-app-blog big'>
              <div className='app-academypost__tags'>

                <p className='app-academypost__tags-label'>
                  {this.state.categories.map((category) => (
                    <Link key={category.id}
                          to={`/academy/?category=${category.id}`}
                          className='blue'>{category.name}</Link>
                  ))}
                </p>
              </div>
              <div className='app-academypost__title'>
                <h1 dangerouslySetInnerHTML={{__html: this.state.title}}></h1>
              </div>

              {(this.state.type === 'clase magistral') ?
                <React.Fragment>
                  <div className='app-academypost__img '
                       onClick={this.handleImgToVideo}>
                    <div className='img-post-blog'>
                      <img src={this.state.srcImage} alt={this.state.title}/>
                    </div>
                  </div>
                  <div className='app-academypost__video' hidden
                       ref={this.youtubeVideo}>
                    <div ref={this.youtubeVideo} className='videoWrapper'
                         dangerouslySetInnerHTML={{__html: this.state.contentAcfExtra}}>
                    </div>
                  </div>
                </React.Fragment>
                : ''}
              {(this.state.type === 'img') ?
                <div className='app-academypost__img'>
                  <div className='img-post-blog'>
                    <img src={this.state.srcImage} alt={this.state.title}/>
                  </div>
                </div>
                : ''}
              {(this.state.type === 'video') ?
                <div className='app-academypost__video'>
                  <div className='videoWrapper'>
                    <video
                      poster={this.state.srcImage}
                      controls
                      src={this.state.srcVideo}
                      crossOrigin='anonymous'
                    />
                  </div>
                </div>
                : ''}
            </div>
          </div>
          <div className='container-app'>
            <div className='container-app-blog'>
              {(this.state.contentAcf === 'download') ?
                <div className='app-academypost__download'>
                  <a href={this.state.contentAcfExtra.link}
                     className='app-academypost__download-link'
                  >
                    <span>{this.state.contentAcfExtra.text}</span>

                    <div className='app-academypost__download-icon'>
                      <span className='title'>Contenido descargable</span>
                      <i className='icon-arrow'></i>
                    </div>
                  </a>
                </div> : ''}

              {(this.state.contentAcf === 'recipe') ?
                <div className='app-academypost__recipe'>
                  <div className='app-academypost__recipe-info'>
                    <div className='app-academypost__recipe-info-item'>
                      <p><i className='icon-cooking'></i> Dificultad</p>
                      <p>{this.state.contentAcfExtra.dificulty}</p>
                    </div>
                    <div className='app-academypost__recipe-info-item'>
                      <p><i className='icon-clock'></i> preparación</p>
                      <p>{this.state.contentAcfExtra.preparation}</p>
                    </div>
                    <div className='app-academypost__recipe-info-item'>
                      <p><i className='icon-kcal'></i> Valor energético</p>
                      <p>{this.state.contentAcfExtra.energy}</p>
                    </div>
                  </div>

                  <div className='app-academypost__recipe-ingredients'>
                    <p>Ingredientes</p>
                    <ul>
                      {this.state.contentAcfExtra.ingredients.map((ingredient, index) => (
                        <li key={index}>{ingredient.text}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                : ''}
              <div className='app-academypost__content'>
                <div className='content'
                     dangerouslySetInnerHTML={{__html: this.state.content}}>
                </div>
                <p className='app-academypost__tags-date'>Publicado
                  el {new Intl.DateTimeFormat('es-ES', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  }).format(this.state.fecha)}</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  getPost = () => {
    const url = process.env.REACT_APP_URL_WORDPRESS;
    const { user, match } = this.state;
    const { appKey } = user;

    axios.get(url + '/wp-json/wp/v2/posts?slug=' + match.params.slugPost, {
      params: {
        key: appKey
      }
    })
      .then(res => {
        const categories = [];
        const tags = [];
        let extraContent = '';

        res.data.forEach(data => {
          let result = [];
          const fecha = new Date(data.date);
          data.categories.forEach(item => {
            categories.push({
              id: item.category_id,
              name: item.category_name,
              link: item.category_link
            })
          });
          data.tags.forEach(item => {
            tags.push({
              id: item.tag_id,
              name: item.tag_name,
              link: item.tag_link
            })
          });

          switch (data.acf.gf_cpt_post_content_type) {
            case 'webinar':
              extraContent = data.acf.gf_cpt_post_webinar_date;
              break;
            case 'magistral':
              extraContent = data.acf.gf_entrada_magistral;
              break;
            case 'download':
              result.push({
                link: data.acf.gf_cpt_post_download_content.url,
                text: data.acf.gf_cpt_post_download_description,
                filename: data.acf.gf_cpt_post_download_content.filename,
                type: data.acf.gf_cpt_post_download_content.subtype
              });
              let [outZeroDownload] = result;
              extraContent = outZeroDownload;
              break;
            case 'recipe':
              let aux = [];
              data.acf.gf_cpt_post_recipe_ingredient_list.forEach(item => {
                aux.push({
                  text: item.gf_cpt_post_recipe_ingredient_item
                })
              })
              result.push({
                ingredients: aux,
                dificulty: data.acf.gf_cpt_post_recipe_level,
                preparation: data.acf.gf_cpt_post_recipe_time,
                energy: data.acf.gf_cpt_post_recipe_kcal
              })
              let [outZeroRecipe] = result;
              extraContent = outZeroRecipe;
              break;
            default:
              extraContent = '';
          }
          if (data.acf.gf_cpt_post_content_type === 'magistral') {
            this.setState({
              title: data.title.rendered,
              srcImage: data.featured_img,
              fecha: fecha,
              content: data.content.rendered,
              contentAcf: data.acf.gf_cpt_post_content_type,
              contentAcfExtra: extraContent,
              categories: categories,
              tags: tags,
              type: 'clase magistral',
              loading: false
            })
          } else if (data.acf.gf_cpt_post_video) {
            this.setState({
              title: data.title.rendered,
              srcImage: data.featured_img,
              srcVideo: data.acf.gf_cpt_post_video.url,
              fecha: fecha,
              content: data.content.rendered,
              contentAcf: data.acf.gf_cpt_post_content_type,
              contentAcfExtra: extraContent,
              categories: categories,
              tags: tags,
              type: 'video',
              loading: false
            })
          } else if (data.featured_img) {
            this.setState({
              title: data.title.rendered,
              srcImage: data.featured_img,
              fecha: fecha,
              content: data.content.rendered,
              contentAcf: data.acf.gf_cpt_post_content_type,
              contentAcfExtra: extraContent,
              categories: categories,
              tags: tags,
              type: 'img',
              loading: false
            })
          }
        });
      }, error => {
        if (error.res.status === 401 || error.res.status === 500) {
          console.error(error);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  handleImgToVideo = (event) => {
    event.target.style.display = 'none';
    this.youtubeVideo.current.style.display = 'block';
  }
}

AcademyPost.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  AcademyPost,
  ['gf_plan_basic', 'gf_plan_premium', 'gf_plan_pro']
);
