import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Graph from '../../components/Graph';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Perimeter from '../../components/Perimeter';

class StatisticsPerimeter extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      data: '',
      options: '',
      send: false,
      dataPerimeters: [],
      resultPerimeters: [],
      noPerimeters: ['gf_perimeter_front', 'gf_perimeter_back', 'gf_perimeter_side'],
      noPerimetersLabels: ['Frontal', 'Espalda', 'Perfil'],
      showForm: true,
      perimeter_filter: '',
      start_date: '',
      end_date: '',
      firstData: false,
      firstLoad: true,
      state: false,
      loading: true,
      statusRepeated: 429,
      statusOk: 200,
      statusError: 503,
      msgStatusText: '',
      msgStatusType: ''
    };

    this.insertPerimeters = this.insertPerimeters.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.authService = new AuthService();
  };

  render() {
    const {
      loading
    } = this.state;


    return loading === true ? this.renderProgress() : this.renderComponent();
  }

  componentDidMount() {
    this.getUserPerimeters();
    this.getPerimeters();
  }

  renderProgress = () => {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Evolución perímetros' from='statistics' user={user} token={token} />
        <Menu from='statistics' user={user} />
        <div className='app-statistics'>
          <div className='app-statistic__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-back'>
                <Link to='/statistics' className='app-back__link'>
                  <i className='icon-arrow'>{''}</i>
                  <p>Volver atrás</p>
                </Link>
              </div>

              <div className='app-statistic__intro-title'>
                <h2 className='title-condensed'>Evolución de los perímetros</h2>
                <p>Introduce los perímetros solicitados y lleva un control semanal a lo largo del tiempo para conocer tu evolución y progreso. Además, podrás filtrar tanto por fecha como por perímetros concretos para una mejor visualización de los datos.</p>
              </div>
            </div>
          </div>

          <div className='container-app container-app-medium'>
            <div className='app-statistic__filter'>
              <div className='app-statistic__filter-item'>
                <p>Fecha Inicio</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>
              <div className='app-statistic__filter-item'>
                <p>Fecha Fin</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>
              <div className='app-statistic__filter-item'>
                <p>Filtrar por perímetro</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>
            </div>

            <div className='placeholder__graphic'>
              <CircularProgress className='loading__progress'/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderComponent() {
    const { user, token, msgStatusText, msgStatusType, send } = this.state;

    return (
      <React.Fragment>
        <Header name='Evolución perímetros' from='statistics' user={user} token={token} />
        <Menu from='statistics' user={user} />
        <div className='app-statistic'>
          <div className='app-statistic__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-back'>
                <Link to='/statistics' className='app-back__link'>
                  <i className='icon-arrow'>{''}</i>
                  <p>Volver atrás</p>
                </Link>
              </div>
              <div className='app-statistic__intro-title'>
                <h2 className='title-condensed'>Evolución de los perímetros</h2>
                <p>Introduce los perímetros solicitados y lleva un control semanal a lo largo del tiempo para conocer tu evolución y progreso. Además, podrás filtrar tanto por fecha como por perímetros concretos para una mejor visualización de los datos.</p>
              </div>
            </div>
          </div>
          <div className='container-app container-app-medium'>
            {(this.state.firstData === true) ?
              <React.Fragment>
                <div className='app-statistic__filter'>
                  <div className='app-statistic__filter-item'>
                    <p>Fecha Inicio</p>
                    <div className='input-black'>
                      <input type='date'
                             name='start_date'
                             onChange={this.handleFilter}
                      />
                    </div>
                  </div>
                  <div className='app-statistic__filter-item'>
                    <p>Fecha Fin</p>
                    <div className='input-black'>
                      <input type='date'
                             name='end_date'
                             onChange={this.handleFilter}
                      />
                    </div>
                  </div>
                  <div className='app-statistic__filter-item'>
                    <p>Filtrar por perímetro</p>
                    <div className='select-black'>
                      <select name='perimeter_filter' onChange={this.handleFilter}>
                        <option value='no'>Ver todos</option>
                        {this.state.dataPerimeters.map((perimeter) => (
                          (!this.state.noPerimeters.includes(perimeter.machineName)) ?
                            <option key={perimeter.machineName}
                                    value={perimeter.machineName}>{perimeter.type}</option>
                            : ''
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='app-statistic__graphic'>
                  {(this.state.data !== '' && this.state.state === true) ?
                    <Graph data={this.state.data} options={this.state.options}/>
                    :
                    <div className='placeholder__empty full'>
                      <p className='placeholder__empty-pretitle'>Oops!</p>
                      <p className='placeholder__empty-title'>La busqueda que
                        has hecho no tiene contenido.</p>
                      <p className='placeholder__empty-text'>Prueba a <span>seleccionar otro perímetro</span> o
                        unas <span> fechas diferentes!</span></p>
                    </div>
                  }
                </div>
              </React.Fragment>
              :
              <div className='placeholder__empty full'>
                <p className='placeholder__empty-pretitle'>Oops!</p>
                <p className='placeholder__empty-title'> Todavía o hay contenido
                  disponible en esta gráfica</p>
                <p className='placeholder__empty-text'>Conforme vayas subiendo
                  información podrás visualizar aquí tu evolución a lo largo del
                  tiempo entrenando.</p>
              </div>
            }

            <div className='app-statistic__form'>
              <form ref={(el) => this.perimeterForm = el}
                    onSubmit={this.insertPerimeters}>

                <div className='app-statistic__form-item'>

                  {this.state.dataPerimeters.map((perimeter) => {
                    if (perimeter.machineName === 'gf_perimeter_chest') {
                      return (
                        <React.Fragment key={perimeter.machineName}>
                          <div className='app-statistic__form-title'>
                            <div className='title-section'>
                              <h2 className='title-caps'><i className='icon-perimeter'>{''}</i> Añadir perímetros</h2>
                              <span className='line'>{''}</span>
                            </div>
                            <p>A la hora de introducir los siguientes perímetros
                              y medidas
                              utiliza <strong>centímetros</strong> como unidad
                              de medida (cm).</p>
                          </div>
                          <div className='app-statistic__form-input'>
                            <Perimeter key={perimeter.id}
                                       data={perimeter}
                                       handleInputChange={this.handleInputChange.bind(this)}
                            />
                          </div>
                        </React.Fragment>
                      );
                    } else if (perimeter.machineName === 'gf_perimeter_front') {
                      return (
                        <React.Fragment key={perimeter.machineName}>
                          <div className='app-statistic__form-title pictures'>
                            <div className='title-section'>
                              <h2 className='title-caps'><i className='icon-upload-img'>{''}</i> Subir fotos</h2>
                              <span className='line'>{''}</span>
                            </div>
                            <p>Sube fotos de tu progreso para poder comparar tu evolución corporal. Los datos no fallan, ¡pero la vista tampoco!</p>
                          </div>
                          <div className='app-statistic__form-input'>
                            <Perimeter key={perimeter.id}
                                       data={perimeter}
                                       handleInputChange={this.handleInputChange.bind(this)}
                            />
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={perimeter.machineName}>
                          <div className='app-statistic__form-input'>
                            <Perimeter key={perimeter.id}
                                       data={perimeter}
                                       handleInputChange={this.handleInputChange.bind(this)}
                            />
                          </div>
                        </React.Fragment>
                      );
                    }
                  })}
                </div>
                <div className='app-statistic__form-btn'>
                  <input type='submit'
                         value={ send === false ? 'Enviar datos' : 'Enviando datos...' }
                         disabled={send}
                  />

                  { msgStatusText !== '' ?
                    <div className={msgStatusType}>
                      <p> {msgStatusText} </p>
                    </div>
                    : ''
                 }
                </div>

              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  getUserPerimeters = (data) => {
    const url = process.env.REACT_APP_URL_API;
    const { user, token } = this.state;
    const { username } = user;
    let weeks = [];
    let perimeters = [];
    let results = [];
    let start_date = '';
    let end_date = '';
    let perimeter_filter = '';
    let tableDataset = [];
    const colors = [
      'yellow',
      'green',
      'black',
      'pink',
      'blue',
      'red',
      'brown',
      'orange',
      'purple'
    ];
    let auxCont = 0;

    if (data && data.start_date !== undefined && data.start_date !== '') {
      start_date = data.start_date;
    } else if (this.state.start_date !== undefined && this.state.start_date !== '') {
      start_date = this.state.start_date;
    } else {
      start_date = 'no';
    }

    if (data && data.end_date !== undefined && data.end_date !== '') {
      end_date = data.end_date;
    } else if (this.state.end_date !== undefined && this.state.end_date !== '') {
      end_date = this.state.end_date;
    } else {
      end_date = 'no';
    }

    if (data && data.perimeter_filter !== undefined && data.perimeter_filter !== '') {
      perimeter_filter = data.perimeter_filter;
    } else if (this.state.perimeter_filter !== undefined && this.state.perimeter_filter !== '') {
      perimeter_filter = this.state.perimeter_filter;
    } else {
      perimeter_filter = 'no';
    }

    axios.get(url + '/get_user_perimeters/u/'+username+'/pr/'+perimeter_filter+'/sd/'+start_date+'/ed/'+end_date, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          for (let key in response.data.perimeters) {
            perimeters.push(response.data.perimeters[key].name);
            results[response.data.perimeters[key].name] = [];
          }
          for (let key in response.data.cms) {
            let arrayAuxPerimeters = [];
            weeks.push(key);
            for (let subkey in response.data.cms[key]) {
              arrayAuxPerimeters.push(subkey);
              results[subkey].push(response.data.cms[key][subkey][0]);
            }
            perimeters.forEach(item => {
              if (!arrayAuxPerimeters.includes(item)) {
                results[item].push(0);
              }
            })
          }

          for (let key in results) {
            tableDataset.push({
              label: key,
              fill: false,
              lineTension: 0.1,
              backgroundColor: colors[auxCont],
              borderColor: colors[auxCont],
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: colors[auxCont],
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: colors[auxCont],
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: results[key]
            })
            auxCont++;
          }

          this.setState({
            data: {
              labels: weeks,
              datasets: tableDataset,
            },
            options: {
              scales: {
                yAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Cms',
                  }
                }],
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Semanas'
                  }
                }]
              }
            },
            state: (response.data.state === 'no_data') ? false : true,
            firstData: (this.state.firstLoad === true && response.data.state === 'no_data') ? false : true,
            firstLoad: false,
            loading: false,
          })
        } else {
          this.setState({
            data: '',
            options: '',
            state: false,
            firstData: (this.state.firstLoad === true) ? false : true,
            firstLoad: false,
            loading: false,
          })
        }
      }, error => {
        console.error(error);
      });
  }

  getPerimeters = () => {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;
    let result = [];

    axios.get(url + '/get_perimeters', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        response.data[0].forEach(item => {
          result.push({
            id: item.id,
            type: item.type,
            machineName: item.machineName,
          });
        });

        this.setState({
          dataPerimeters: result,
          loading: false
        })
      }, error => {
        console.error(error);
      });
  }

  insertPerimeters = (event) => {
    event.preventDefault();
    const url = process.env.REACT_APP_URL_API;
    const { user, token } = this.state;
    const { username } = user;

    this.setState({
      send: true,
      msgStatusText: '',
      msgStatusType: ''
    });


    for (let key in this.state.resultPerimeters) {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('perimetertype', key);
      let especifico = 0;
      if (key === 'gf_perimeter_front' || key === 'gf_perimeter_back' || key === 'gf_perimeter_side') {
        let imageName = key + '_' + username + '_' + Date.now() + '.' + this.state.resultPerimeters[key].name.split('.').pop();
        formData.append('image', this.state.resultPerimeters[key]);
        formData.append('imagename', imageName);
        especifico = 1;

      } else {
        especifico = this.state.resultPerimeters[key];
        formData.append('cms', this.state.resultPerimeters[key]);
      }
      if (especifico !== 0 && especifico !== undefined && especifico !== '') {
        axios.post(url + '/insert_perimeters', formData, {
          headers: {
            Authorization: 'Bearer ' + token,
            'content-type': 'multipart/form-data'
          },
        })
        .then((res) => {
          this.handleResponse(res.status, res.data)
        },
        error => {
          this.handleResponse(error.response.status, error.response.data);
        });
      }
    }
    this.setState({
      resultPerimeters: [],
      showForm: false
    });
    this.perimeterForm.reset();
    this.resetFormLabels();

    setTimeout(() => {
      this.getUserPerimeters();
    }, 2000);
  }

  resetFormLabels = () => {
    let labels = this.state.noPerimetersLabels;
    this.state.noPerimeters.forEach(function (key, i) {
      document.getElementById(key).previousSibling.innerText = labels[i];
    });
  };

  handleResponse = (statusCode, statusMessage) => {
    let msgStatusType = 'message';

    if (
      statusCode === this.state.statusRepeated ||
      statusCode === this.state.statusError
    ) {
      msgStatusType += ' error';
    }

    this.setState({
      msgStatusText: statusMessage,
      msgStatusType: msgStatusType,
      send: false
    });
  }

  handleInputChange = (event) => {
    if (event.target.type !== 'file') {
      let dataPerimeter = this.state.resultPerimeters;
      dataPerimeter[event.target.name] = event.target.value;
      this.setState({resultPerimeters: dataPerimeter})
    } else {
      let files = event.target.files || event.dataTransfer.files;
      let dataPerimeter = this.state.resultPerimeters;
      dataPerimeter[event.target.name] = files[0];
      this.setState({resultPerimeters: dataPerimeter})
    }
  };

  handleFilter = (event) => {
    event.preventDefault();
    let value = '';
    if (event.target.name === 'start_date' || event.target.name === 'end_date') {
      if (event.target.value === '') {
        value = 'no';
      } else {
        value = event.target.value;
      }
    } else {
      value = event.target.value;
    }
    const data = {[event.target.name]: value};
    this.setState({
      [event.target.name]: value,
    })
    this.getUserPerimeters(data);
  }
}

StatisticsPerimeter.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  StatisticsPerimeter,
  ['gf_plan_premium', 'gf_plan_pro']
);
