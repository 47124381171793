import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ROUTE_SESSION} from '../../../constants/routes';
import {
  checkNumber,
  checkProperties,
  getProperties,
  multiple
} from '../../../constants/common';
import Media from '../Media';
import Modal from '../Modal';
import PreviousTrainingSession from "../PreviousTrainingSession";
import PreviousTrainingSessionComment from "../PreviousTrainingSessionComment";

class Content extends Component {
  constructor(props) {
    super(props);

    const {
      number,
      session,
      sessionTrainingCycle,
      sessionTrainingCycleBefore,
      movementOfTraining,
      movement,
      previousTrainingSession,
      handleSubmit
    } = props;

    this.state = {
      number,
      session,
      sessionTrainingCycle,
      sessionTrainingCycleBefore,
      movementOfTraining,
      movement,
      previousTrainingSession,
      handleSubmit,
      practice: null,
      maximumRepetition: 0,
      numberSeries: 0,
      seriesHtml: null,
      seriesValues: [],
      weight: 0,
      realWeight: 0,
      comment: '',
      feedback: 0,
      displaySecondPart: false,
      continueSubmitAction: false,
      sendAction: false,
      modalIsOpen: false,
      lastRepetitionsSeries: [],
      lastRealWeight: 0.0,
      lastComment: ''
    };

    this.handleSubmitFeedback = this.handleSubmitFeedback.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmitFinish = this.handleSubmitFinish.bind(this);
    this.handleChangeSeries = this.handleChangeSeries.bind(this);
    this.handleChangeRealWeight = this.handleChangeRealWeight.bind(this);
    this.handleChangeComment = this.handleChangeComment.bind(this);
    this.checkRequiredInputs = this.checkRequiredInputs.bind(this);
  }

  handleSubmitFeedback = (feedback) => {
    this.setState({
      feedback,
      modalIsOpen: false,
      displaySecondPart: true,
    });
  };

  handleOpenModal = (event) => {
    event.preventDefault();

    const { session } = this.state;
    const numberWeek = session.number_week;
    const numberWeeksMesocycle = session.number_weeks_mesocycle;

    if (numberWeek < numberWeeksMesocycle) {
      this.setState({
        modalIsOpen: true
      });
    } else {
      this.setState({
        displaySecondPart: true,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  handleSubmitFinish = (event) => {
    event.preventDefault();

    const {
      seriesHtml,
      comment,
      realWeight,
      session,
      movementOfTraining,
      practice,
      weight,
      feedback,
      handleSubmit,
    } = this.state;
    const idSession = session.id;
    const idMovementOfTraining = movementOfTraining.id;
    const idPractice = practice.id;
    const seriesValues = getProperties(seriesHtml);

    this.setState({
      continueSubmitAction: false,
      sendAction: true,
    });

    try {
      handleSubmit({
        sid: idSession,
        tid: idMovementOfTraining,
        pid: idPractice,
        weight,
        comment: comment,
        series: seriesValues,
        feedback,
        real_weight: realWeight,
      });
    } catch (e) {
      this.setState({
        practice: null,
        maximumRepetition: 0,
        numberSeries: 0,
        weight: 0,
        real_weight: 0,
        feedback: 0,
        displaySecondPart: false,
        continueSubmitAction: false,
        sendAction: false,
        modalIsOpen: false,
      });
    }
  }

  checkRequiredInputs = () => {
    const { seriesHtml, realWeight } = this.state;

    let okSeries = (seriesHtml !== null && checkProperties(seriesHtml));
    let okWeight = (realWeight !== null && checkNumber(realWeight));

    this.setState({
      continueSubmitAction: okSeries && okWeight,
    });
  }

  handleChangeRealWeight = (event) => {
    const { value } = event.target;

    this.setState({
      realWeight: value.replace(',', '.')
    });
  };

  handleChangeComment = (event) => {
    const { value } = event.target;

    this.setState({
      comment: value
    });
  }

  handleChangeSeries = (event) => {
    event.preventDefault();

    let seriesHtml = document.getElementsByClassName('series');
    let seriesValues = [];

    for (let s in seriesHtml) {
      seriesValues.push(s.value);
    }

    this.setState({
      seriesHtml: seriesHtml,
      seriesValues: seriesValues
    });
  }

  printPracticeName = () => {
    const { practice } = this.state;
    if (practice === null) {
      return '';
    }

    return practice.name;
  }

  printMedia = () => {
    const { practice } = this.state;
    if (practice === null) {
      return '';
    }

    const practiceImage = practice.image;
    const practiceVideo = practice.video;
    const practiceName = practice.name;

    return <Media image={practiceImage}
                  video={practiceVideo}
                  name={practiceName} />
  }

  printSeries = () => {
    const { numberSeries } = this.state;
    let print = [];

    for (let i = 1; i <= numberSeries; i++) {
      print.push(
        <div key={i} className='app-exercise__userform-input'>
          <p>Número de repeticiones que has hecho en la serie {i}</p>
          <div className='input-label'>
            <input className={`series serie-${i}`}
                   type='number'
                   required
                   onChange={this.handleChangeSeries}
            />
            <label>Repeticiones serie {i}</label>
            <i className='icon-mark-full'>{''}</i>
          </div>
        </div>
      );
    }

    return print;
  }

  printRealWeight(weight) {
    return (
      <div className='
        app-exercise__userform
        app-exercise__userform--isolated
        app-exercise__userform--isolated--top
      '>
        <div key="realWeight" className={`app-exercise__userform-input`}>
          <p>Carga real con la que has realizado el ejercicio</p>
          <div className='input-label'>
            <input type='text'
                   required
                   id="realWeight"
                   defaultValue={ weight }
                   onChange={ this.handleChangeRealWeight }
            />
            <label>Carga real</label>
            <i className='icon-mark-full'>{''}</i>
          </div>
          <small>Para introducir decimales utiliza: '.'. Ejemplo: 25.5</small>
        </div>
      </div>
    );
  }

  printComment = () => {
    return (
      <div  className='
        app-exercise__userform
        app-exercise__userform--isolated
        app-exercise__userform--isolated--bottom
      '>
        <div key="comment" className='app-exercise__userform-input '>
          <p>Comentario</p>
          <div className='input-label'>
             <textarea
               placeholder=" "
               id="comment"
               maxLength="500"
               onChange={ this.handleChangeComment }
             />
            <label className="comment">¿Algo que comentar sobre el ejercicio?</label>
          </div>
        </div>
      </div>
    );
  }

  getPreviousPractice = () => {
    const { practice, sessionTrainingCycleBefore } = this.state;
    const { machine_name } = practice;
    const { training_session } = sessionTrainingCycleBefore;

    let lastRealWeight, lastRepetitionsSeries, lastComment;

    for (let i in training_session) {
      let previousPractice = training_session[i].practice;

      if (previousPractice.machine_name === machine_name) {
        lastRealWeight = previousPractice.real_weight;
        lastRepetitionsSeries = previousPractice.repetitions_series;
        lastComment = previousPractice.comment;

        break;
      }
    }

    this.setState({
      lastRepetitionsSeries: lastRepetitionsSeries,
      lastRealWeight: lastRealWeight,
      lastComment: lastComment,
    })
  }

  printPreviousTrainingSessionData = () => {
    const { lastRepetitionsSeries, lastRealWeight } = this.state;

    if (lastRepetitionsSeries.length === 0 && lastRealWeight === 0) {
      return '';
    }

    return <PreviousTrainingSession
      repetitions={lastRepetitionsSeries}
      weight={lastRealWeight}
    />
  }

  printPreviousTrainingSessionComment = () => {
    const { lastComment } = this.state;

    if (lastComment === null || lastComment.length <= 0) {
      return '';
    }

    return <PreviousTrainingSessionComment
      comment={lastComment}
    />
  }

  printModal = () => {
    const { modalIsOpen } = this.state;

    if (modalIsOpen === false) {
      return null;
    }

    return <Modal
      handleCloseModal={this.handleCloseModal}
      handleSubmitFeedback={this.handleSubmitFeedback}
    />
  }

  calculateNumberOfSeries = () => {
    const { session, numberSeries, previousTrainingSession } = this.state;
    const numberWeek = session.number_week;
    const numberWeeksMesocycle = session.number_weeks_mesocycle;

    if (numberWeek < numberWeeksMesocycle) {
      const { rating } = previousTrainingSession;
      let sum = parseInt(numberSeries) + parseInt(rating);
      sum = sum <= 1 ? 1 : sum;
      sum = sum >= 5 ? 5 : sum;

      this.setState({
        numberSeries: sum,
      });
    }
  }

  calculateWeight = () => {
    const { movement } = this.state;
    const movementMachineName = movement.machine_name;
    if (movementMachineName === 'gf_abd') {
      this.setState({
        weight: 0,
        realWeight: 0,
      });
    } else {
      const { movementOfTraining, maximumRepetition } = this.state;
      const { quotient } = movementOfTraining;
      const { progressions } = quotient;
      const { value } = progressions;
      const result = multiple(maximumRepetition, value);

      this.setState({
        weight: result,
        realWeight: result,
      });
    }
  }

  getPractice = () => {
    const {
      number,
      movement,
      sessionTrainingCycle,
    } = this.state;
    const { practices } = movement;
    const trainingSession = sessionTrainingCycle.training_session;
    let currentPractice = null;
    let currentMaximumRepetition = 0;

    for (let i in trainingSession) {
      if (trainingSession.hasOwnProperty(i)) {
        const { practice } = trainingSession[i];
        const currentNumber = trainingSession[i].number;
        const { id } = practice;
        const maximumRepetition = practice.maximum_repetition;
        const maximumRepetitionValue = maximumRepetition.value;

        if (parseInt(number) !== parseInt(currentNumber)) {
          continue;
        }

        for (let j in practices) {
          if (practices.hasOwnProperty(j)) {
            const idAux = practices[j].id;

            if (id === idAux) {
              currentPractice = practices[j];
              currentMaximumRepetition = maximumRepetitionValue;
              break;
            }
          }
        }

        if (currentPractice !== null) {
          break;
        }
      }
    }

    this.setState({
      practice: currentPractice,
      maximumRepetition: currentMaximumRepetition,
    });
  }

  getNumberSeries = () => {
    const {
      number,
      movement,
      session,
      sessionTrainingCycle,
      sessionTrainingCycleBefore,
    } = this.state;
    const numberWeek = session.number_week;
    const numberWeeksMesocycle = session.number_weeks_mesocycle;
    const { practices } = movement;
    let trainingSession;
    let finalNumberSeries;

    if (numberWeek === numberWeeksMesocycle) {
      trainingSession = sessionTrainingCycle.training_session;
    } else {
      trainingSession = sessionTrainingCycleBefore.training_session;
    }

    for (let i in trainingSession) {
      if (trainingSession.hasOwnProperty(i)) {
        const { practice } = trainingSession[i];
        const currentNumber = trainingSession[i].number;
        const { id } = practice;
        const numberSeries = practice.number_series;

        if (parseInt(number) !== parseInt(currentNumber)) {
          continue;
        }

        for (let j in practices) {
          if (practices.hasOwnProperty(j)) {
            const idAux = practices[j].id;

            if (id === idAux) {
              finalNumberSeries = numberSeries;
              break;
            }
          }
        }

        if (finalNumberSeries !== null) {
          break;
        }
      }
    }

    this.setState({
      numberSeries: finalNumberSeries
    });
  }

  componentDidMount() {
    this.getPractice();
    this.getNumberSeries();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { practice, realWeight, seriesValues } = this.state;

    if (practice !== prevState.practice) {
      this.calculateNumberOfSeries();
      this.calculateWeight();
      this.getPreviousPractice();
    }

    if (realWeight !== prevState.realWeight || seriesValues !== prevState.seriesValues) {
      this.checkRequiredInputs();
    }
  }

  render() {
    const {
      number,
      session,
      movement,
      maximumRepetition,
      displaySecondPart,
      numberSeries,
      weight,
      continueSubmitAction,
      sendAction,
    } = this.state;

    // Session assets
    const sessionId = session.id;

    // Movement assets
    const movementName = movement.name;
    const movementMachineName = movement.machine_name;

    return (
      <React.Fragment>
        <div className='app-exercise'>
          <div className='app-exercise__intro'>
            <div className='app-exercise__intro'>
              <div className='container-app container-app-medium'>
                <div className='app-back'>
                  <a href={`${ROUTE_SESSION}/${sessionId}`} className='app-back__link'>
                    <i className='icon-arrow'>{''}</i>
                    <p>Volver atrás</p>
                  </a>
                </div>
                <div className='app-exercise__nav'>
                  <p>Entrenamiento</p>
                  <a href={`${ROUTE_SESSION}/${sessionId}`}>Ver ejercicio anterior</a>
                </div>
                <div className='app-exercise__title'>
                  <h2 className='title-condensed'>{number} {movementName}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className='container-app container-app-medium'>
            <div className='app-exercise__form completed'>
              <div className='app-exercise__form-inputs'>
                <div className={`app-exercise__form-input selected${movementMachineName === 'gf_abd' ? ' abdomen' : ''}`}>
                  <p>{ this.printPracticeName() }</p>
                </div>
                {
                  movementMachineName === 'gf_abd' ?
                    ''
                    :
                    <div className='app-exercise__form-input selected'>
                      <p>10 RM</p>
                      <p className='number'>{ maximumRepetition }</p>
                    </div>
                }
              </div>
              { this.printMedia() }
              <div className='app-exercise__indications'>
                <div className='app-exercise__indications-serie'>
                  <p className='type'>Series a realizar</p>
                  <p className='number'>{ numberSeries }</p>
                </div>
                <div className='app-exercise__indications-weight'>
                  <p className='type'>Carga</p>
                  <p className='number'>{ weight === 0 ? '--' : weight }</p>
                </div>
              </div>
            </div>
            { movementMachineName === 'gf_abd' ?
              <div className='app-exercise__form-title middle'>
                <p className='title-caps'><i className='icon-notification-full'>{''}</i> Indicaciones</p>
                <p>Una vez hayas comenzado el ejercicio debes ir <strong>indicando el número de repeticiones </strong>que te has hecho en cada una de las series.</p>
                <p>Mantén el ejercicio en un rango de 10 a 20 repeticiones.</p>
              </div>
              :
              ''
            }
            {
              displaySecondPart === false ?
                <form className='app-exercise__userform' onSubmit={this.handleOpenModal}>
                  <div className='app-exercise__userform-submit'>
                    <input type='submit' value='Comenzar ejercicio' />
                  </div>
                </form>
                :
                <form className='app-exercise__userform' onSubmit={this.handleSubmitFinish}>
                  { movementMachineName === 'gf_abd' ? '' : this.printPreviousTrainingSessionData() }
                  { this.printPreviousTrainingSessionComment() }
                  { movementMachineName === 'gf_abd' ? '' : this.printRealWeight(weight) }
                  { this.printSeries() }
                  { this.printComment() }
                  <div className='app-exercise__userform-submit'>
                    {
                      continueSubmitAction === false ?
                        <input type='submit' value={sendAction === true ? 'Guardando...' : 'Finalizar ejercicio'} disabled />
                        :
                        <input type='submit' value='Finalizar ejercicio' />
                    }
                  </div>
                </form>
            }
          </div>
        </div>
        { this.printModal() }
      </React.Fragment>
    );
  }
}

Content.propTypes = {
  number: PropTypes.string.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    machine_name: PropTypes.string.isRequired,
    number_week: PropTypes.number.isRequired,
    number_weeks_mesocycle: PropTypes.number.isRequired,
    number_day: PropTypes.number.isRequired,
    training_cycle: PropTypes.number.isRequired,
    training_cycle_day: PropTypes.number.isRequired,
    is_relax: PropTypes.bool.isRequired,
    is_finished: PropTypes.bool.isRequired,
  }).isRequired,
  sessionTrainingCycle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    machine_name: PropTypes.string.isRequired,
    training_cycle: PropTypes.number.isRequired,
    training_cycle_day: PropTypes.number.isRequired,
    training_session: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.number.isRequired,
      practice: PropTypes.shape({
        id: PropTypes.string.isRequired,
        machine_name: PropTypes.string.isRequired,
        maximum_repetition: PropTypes.shape({
          id: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
    })).isRequired,
  }).isRequired,
  sessionTrainingCycleBefore: PropTypes.shape({
    id: PropTypes.string.isRequired,
    machine_name: PropTypes.string.isRequired,
    training_cycle: PropTypes.number.isRequired,
    training_cycle_day: PropTypes.number.isRequired,
    training_session: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.number.isRequired,
      practice: PropTypes.shape({
        id: PropTypes.string.isRequired,
        machine_name: PropTypes.string.isRequired,
        number_series: PropTypes.number.isRequired,
      }).isRequired,
    })).isRequired,
  }).isRequired,
  movementOfTraining: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number_series: PropTypes.number.isRequired,
    quotient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      progressions: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        number_weeks: PropTypes.number.isRequired,
        number_week: PropTypes.number.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  movement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    machine_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    practices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      machine_name: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string,
      video: PropTypes.string,
      maximum_repetition: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    })).isRequired,
  }).isRequired,
  previousTrainingSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
};

export default Content;
