import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const Loading = () => (
  <React.Fragment>
    <div className='app-message__intro'>
      <p className='message-title h2'>Cargando consulta...</p>
      <p className='message-state'>{''}</p>
    </div>
    <div className='app-message__list'>
      <div className='app-message__item'>
        <LinearProgress />
      </div>
    </div>
  </React.Fragment>
);

export default Loading;
