import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Graph from '../../components/Graph';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

class StatisticsEffortControl extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
      data: '',
      options: '',
      start_date: '',
      end_date: '',
      firstData: false,
      firstLoad: true,
      state: false,
      loading: true
    };

    this.handleFilter = this.handleFilter.bind(this);
    this.authService = new AuthService();

  };

  render() {
    const {loading} = this.state;

    return loading === true ? this.renderProgress() : this.renderComponent();
  }

  componentDidMount() {
    this.getEffortControls();
  }

  renderProgress = () => {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Control de esfuerzo' from='statistics' user={user} token={token} />
        <Menu from='statistics' user={user} />
        <div className='app-statistics'>
          <div className='app-statistic__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-back'>
                <Link to='/statistics' className='app-back__link'>
                  <i className='icon-arrow'></i>
                  <p>Volver atrás</p>
                </Link>
              </div>

              <div className='app-statistic__intro-title'>
                <h2 className='title-condensed'>Control de esfuerzo
                  percibido</h2>
                <p>En esta gráfica podrás evaluar tu índice de esfuerzo percibido a lo largo del tiempo para obtener conclusiones de valor sobre tus propios límites de intensidad de entrenamiento en un periodo de tiempo concreto.</p>
              </div>
            </div>
          </div>

          <div className='container-app container-app-medium'>
            <div className='app-statistic__filter'>
              <div className='app-statistic__filter-item'>
                <p>Fecha Inicio</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>
              <div className='app-statistic__filter-item'>
                <p>Fecha Fin</p>
                <div className='input-black placeholder'>
                  <LinearProgress/>
                </div>
              </div>
            </div>

            <div className='placeholder__graphic'>
              <CircularProgress className='loading__progress'/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderComponent() {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Control de esfuerzo' from='statistics' user={user} token={token} />
        <Menu from='statistics' user={user} />
        <div className='app-statistics'>
          <div className='app-statistic__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-back'>
                <Link to='/statistics' className='app-back__link'>
                  <i className='icon-arrow'></i>
                  <p>Volver atrás</p>
                </Link>
              </div>

              <div className='app-statistic__intro-title'>
                <h2 className='title-condensed'>Control de esfuerzo
                  percibido</h2>
                <p>En esta gráfica podrás evaluar tu índice de esfuerzo percibido a lo largo del tiempo para obtener conclusiones de valor sobre tus propios límites de intensidad de entrenamiento en un periodo de tiempo concreto.</p>
              </div>
            </div>
          </div>
          <div className='container-app container-app-medium'>
            {(this.state.firstData === true) ?
              <React.Fragment>
                <div className='app-statistic__filter'>
                  <div className='app-statistic__filter-item'>
                    <p>Fecha Inicio</p>
                    <div className='input-black'>
                      <input type='date' name='start_date' id=''
                             onChange={this.handleFilter}/>
                    </div>
                  </div>
                  <div className='app-statistic__filter-item'>
                    <p>Fecha Fin</p>
                    <div className='input-black'>
                      <input type='date' name='end_date' id=''
                             onChange={this.handleFilter}/>
                    </div>
                  </div>
                </div>

                <div className='app-statistic__graphic'>
                  {(this.state.data !== '' && this.state.state === true) ?
                    <Graph data={this.state.data} options={this.state.options}/>
                    :
                    <div className='placeholder__empty full'>
                      <p className='placeholder__empty-pretitle'>Oops!</p>
                      <p className='placeholder__empty-title'>La busqueda que
                        has hecho no tiene contenido.</p>
                      <p className='placeholder__empty-text'>Prueba a
                        seleccionar unas <span> fechas diferentes!</span></p>
                    </div>
                  }
                </div>
              </React.Fragment>
              :
              <div className='placeholder__empty full'>
                <p className='placeholder__empty-pretitle'>Oops!</p>
                <p className='placeholder__empty-title'> Todavía o hay contenido
                  disponible en esta gráfica</p>
                <p className='placeholder__empty-text'>Conforme vayas avanzando
                  en los entrenamientos podrás visualizar aquí tu progreso.</p>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }

  getEffortControls = (data) => {
    const url = process.env.REACT_APP_URL_API;
    const { user, token } = this.state;
    const { username } = user;
    let days = [];
    let results = [];
    let start_date = '';
    let end_date = '';

    if (data && data.start_date !== undefined && data.start_date !== '') {
      start_date = data.start_date;
    } else if (this.state.start_date !== undefined && this.state.start_date !== '') {
      start_date = this.state.start_date;
    } else {
      start_date = 'no';
    }

    if (data && data.end_date !== undefined && data.end_date !== '') {
      end_date = data.end_date;
    } else if (this.state.end_date !== undefined && this.state.end_date !== '') {
      end_date = this.state.end_date;
    } else {
      end_date = 'no';
    }

    axios.get(url + '/get_effort_control/u/'+username+'/sd/'+start_date+'/ed/'+end_date, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          for (let key in response.data.sessions) {
            days.push(key);
            results.push(response.data.sessions[key][0]);
          }
          this.setState({
            data: {
              labels: days,
              datasets: [
                {
                  label: 'Rating',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: 'green',
                  borderColor: 'green',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: 'green',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: 'green',
                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: results
                }
              ]
            },
            options: {
              scales: {
                yAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Rating',
                  },
                  ticks: {
                    max: 10,
                    min: 0
                  }
                }],
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Semanas',
                  }
                }]
              }
            },
            state: (response.data.state === 'no_data') ? false : true,
            firstData: (this.state.firstLoad === true && response.data.state === 'no_data') ? false : true,
            firstLoad: false,
            loading: false
          })
        } else {
          this.setState({
            data: '',
            options: '',
            state: false,
            firstData: (this.state.firstLoad === true) ? false : true,
            firstLoad: false,
            loading: false,
          })
        }
      }, error => {
        console.error(error);
      });
  }

  handleFilter = (event) => {
    event.preventDefault();
    let value = '';
    if (event.target.name === 'start_date' || event.target.name === 'end_date') {
      if (event.target.value === '') {
        value = 'no';
      } else {
        value = event.target.value;
      }
    } else {
      value = event.target.value;
    }
    const data = {[event.target.name]: value};
    this.setState({
      [event.target.name]: value,
    })
    this.getEffortControls(data);
  }
}

StatisticsEffortControl.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  StatisticsEffortControl,
  ['gf_plan_premium', 'gf_plan_pro']
);
