import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class NotificationNotice extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const {
      token,
    } = props;

    this.state = {
      token,
      loading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/notification/notice/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(() => {
        if (this._isMounted) {
          this.setState({
            loading: true,
          });
        }
      }).catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { loading } = this.state;

    return loading === true ? <span className='has-notification'>{''}</span> : '';
  }
}

NotificationNotice.propTypes = {
  token: PropTypes.string.isRequired,
};

export default NotificationNotice;
