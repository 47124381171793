import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {formatDate} from '../../constants/common';

class MacrocycleList extends Component {
  constructor(props) {
    super(props);

    const {
      token,
    } = props;

    this.state = {
      token,
      macrocycles: null,
    };

    this.printData = this.printData.bind(this);
  }

  printData = () => {
    const { macrocycles } = this.state;
    let print = [];

    for (let i in macrocycles) {
      if (macrocycles.hasOwnProperty(i)) {
        const id = macrocycles[i].id;
        const name = macrocycles[i].macrocycle_name;
        const variantName = macrocycles[i].macrocycle_variant_name;
        const startDate = macrocycles[i].start_date;
        const endDate = macrocycles[i].end_date;
        const active = macrocycles[i].active;

        print.push(
          <div key={id} className='macrocycle__row'>
            <div className='macrocycle__row-item macrocycle__row-item--name'>{ (parseInt(i) + 1) }</div>
            <div className='macrocycle__row-item macrocycle__row-item--machine' title={name}>{ variantName }</div>
            <div className='macrocycle__row-item macrocycle__row-item--start'>{ formatDate(startDate) }</div>
            <div className='macrocycle__row-item macrocycle__row-item--end'>{ endDate === 0 ? '-' : formatDate(endDate) }</div>
            <div className='macrocycle__row-item macrocycle__row-item--active'>{ active === true ? 'Activo' : 'Inactivo' }</div>
          </div>
        );
      }
    }

    return print;
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;

    let config = {
      method: 'get',
      url: `${url}/macrocycle/list/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
        this.setState({
          macrocycles: data.macrocycles,
        });
      }).catch(error => {
        this.setState({
          macrocycles: null,
        });
      });
  }

  render() {
    const { macrocycles } = this.state;

    if (macrocycles === null) {
      return null;
    }

    return (
      <div className='macrocycle__content macrocycle__content--data'>
        <div className='macrocycle__header'>
          <h3 className='macrocycle__title'>Mis macrociclos</h3>
          <p className='macrocycle__text'>En esta sección puedes ver la información de tus macrociclos.</p>
        </div>
        <div className='macrocycle__body macrocycle__body--table'>
          <div className='macrocycle__theader'>
            <div className='macrocycle__theader-item'>#</div>
            <div className='macrocycle__theader-item'>Nombre</div>
            <div className='macrocycle__theader-item'>Inicio</div>
            <div className='macrocycle__theader-item'>Fin</div>
            <div className='macrocycle__theader-item'>Estado</div>
          </div>
          <div className='macrocycle__tbody'>
            { this.printData() }
          </div>
        </div>
      </div>
    );
  }
}

MacrocycleList.propTypes = {
  token: PropTypes.string.isRequired,
};

export default MacrocycleList;
