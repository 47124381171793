import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {ROUTE_TRAINING} from '../../constants/routes';
import Content from './Content/';
import Loading from './Loading/';
import Modal from './Modal';

class SessionList extends Component {
  constructor(props) {
    super(props);

    const { token, history, match } = props;

    this.state = {
      token,
      history,
      match,
      loading: true,
      previousSession: null,
      session: null,
      modalIsOpen: false,
    };
  }

  componentDidMount() {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;
    const { id } = this.state.match.params;

    let config = {
      method: 'get',
      url: `${url}/session/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(data => {
      const previousSession = data.previous_session;
      const { session } = data;

        this.setState({
          loading: false,
          previousSession,
          session,
        });
      }).catch(error => {
        this.setState({
          loading: true,
          previousSession: null,
          session: null,
          modalIsOpen: false,
        });
    });
  }

  handleOpenModal = () => {
    this.setState({
      modalIsOpen: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  submitAction = (config) => {
    const { history } = this.state;

    axios(config)
      .then(response => {
        if (response.status === 201) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(() => {
        history.replace(ROUTE_TRAINING);
      }).catch(error => {
        this.setState({
          loading: true,
          previousSession: null,
          session: null,
          modalIsOpen: false,
        });
      });
  }

  handleSubmitSession = (rating, comment) => {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;
    const { id } = this.state.match.params;

    let config = {
      method: 'post',
      url: `${url}/session/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      params: {
        'id': id,
        'rating': rating,
        'comment': comment
      }
    };

    this.submitAction(config);
  }

  handleSubmitRelax = () => {
    const url = process.env.REACT_APP_URL_API;
    const { token } = this.state;
    const { id } = this.state.match.params;

    let config = {
      method: 'post',
      url: `${url}/session/relax/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      params: {
        'id': id,
      }
    };

    this.submitAction(config);
  }

  printModal = () => {
    const { modalIsOpen } = this.state;

    if (modalIsOpen === false) {
      return null;
    }

    return <Modal
      handleCloseModal={this.handleCloseModal}
      handleSubmitSession={this.handleSubmitSession}
    />
  }

  render() {
    const {
      loading,
      previousSession,
      session,
    } = this.state;

    if (loading === true) {
      return <Loading />
    }

    return (
      <React.Fragment>
        <Content previousSession={previousSession}
                 session={session}
                 handleOpenModal={this.handleOpenModal}
                 handleSubmitRelax={this.handleSubmitRelax}
        />
        { this.printModal() }
      </React.Fragment>
    )
  }
}

SessionList.propTypes = {
  token: PropTypes.string.isRequired,
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SessionList;
