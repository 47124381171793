import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import Header from '../../components/Header';
import NotificationList from '../../components/NotificationList';

class Notification extends Component {
  constructor(props) {
    super(props);

    const {
      history,
      user,
      token,
    } = props;

    this.state = {
      history,
      user,
      token,
    };

    this.authService = new AuthService();
  };

  render() {
    const { history, user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='Notificaciones' from='notification' user={user} token={token} />
        <NotificationList history={history} token={token} />
      </React.Fragment>
    );
  }
}

Notification.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  Notification,
  ['gf_plan_basic', 'gf_plan_premium', 'gf_plan_pro']
);
