import React, {Component} from 'react';
import AuthService from '../../services/AuthService';
import authRequired from '../../components/AuthRequired';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import PropTypes from 'prop-types';

class WithYou extends Component {
  constructor(props) {
    super(props);

    const {
      user,
      token,
    } = props;

    this.state = {
      user,
      token,
    };

    this.authService = new AuthService();
  }

  render() {
    const { user, token } = this.state;

    return (
      <React.Fragment>
        <Header name='En tu móvil' from='withyou' user={user} token={token} />
        <Menu from='withyou' user={user} />
        <div className='app-wy'>
          <div className='app-wy__intro'>
            <div className='container-app container-app-medium'>
              <div className='app-wy__intro-title'>
                <h2 className='title-condensed'>GFITCOACH siempre en tu móvil</h2>
                <p>Disfruta de las comodidades de llevarlo en tu móvil. En tan sólo 3 pasos podrás disfrutar de un acceso directo en tu iPhone o Android.</p>
              </div>
              <div className='app-wy__intro-image'>
                <img src='/assets/img/academy-background-section.png'
                     alt='GFITCOACH'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='container-app container-app-medium'>
          <div className='app-wy__section'>
            <div className='app-wy__section-intro'>
              <img src='/assets/img/ios.svg' alt='iOS' />
            </div>
            <div className='app-wy__section-list'>
              <div className='app-wy__section-item image item-01'>
                <p>1</p>
                <img src='/assets/img/pantalla-ios-1.jpg' alt='Paso 1' />
              </div>
              <div className='app-wy__section-item image item-02'>
                <p>2</p>
                <img src='/assets/img/pantalla-ios-2.jpg' alt='Paso 2' />
              </div>
              <div className='app-wy__section-item image item-03'>
                <p>3</p>
                <img src='/assets/img/pantalla-ios-3.jpg' alt='Paso 3' />
              </div>
              <div className='app-wy__section-item text item-04'>
                <p><span className='number'>1</span>Abre Safari y teclea en el buscador app.gfitcoach.com, pulsa sobre el botón compartir.</p>
              </div>
              <div className='app-wy__section-item text item-05'>
                <p><span className='number'>2</span>En el menú emergente pulsa sobre el botón añadir a pantalla de inicio.</p>
              </div>
              <div className='app-wy__section-item text item-06'>
                <p><span className='number'>3</span>Personaliza el nombre del acceso directo: GFIT COACH. Este nombre será el que se mostrará justo debajo del acceso directo en la pantalla de inicio.</p>
              </div>
            </div>
          </div>
          <div className='app-wy__section'>
            <div className='app-wy__section-intro'>
              <img src='/assets/img/android.svg' alt='Android' />
            </div>
            <div className='app-wy__section-list'>
              <div className='app-wy__section-item image item-01'>
                <p>1</p>
                <img src='/assets/img/pantalla-android-1.jpg' alt='Paso 1' />
              </div>
              <div className='app-wy__section-item image item-02'>
                <p>2</p>
                <img src='/assets/img/pantalla-android-2.jpg' alt='Paso 2' />
              </div>
              <div className='app-wy__section-item image item-03'>
                <p>3</p>
                <img src='/assets/img/pantalla-android-3.jpg' alt='Paso 3' />
              </div>
              <div className='app-wy__section-item text item-04'>
                <p><span className='number'>1</span>Abre el navegador de Chrome y teclea en el buscador app.gfitcoach.com </p>
              </div>
              <div className='app-wy__section-item text item-05'>
                <p><span className='number'>2</span>Pulsa sobre el menú, los tres puntos de la esquina superior derecha.3. En el menú emergente selecciona la opción 'Añadir a pantalla de inicio'.</p>
              </div>
              <div className='app-wy__section-item text item-06'>
                <p><span className='number'>3</span>Personaliza el nombre del acceso directo: GFIT COACH. Este nombre será el que se mostrará justo debajo del acceso directo en la pantalla de inicio.</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

WithYou.propTypes = {
  user: PropTypes.shape({
    appKey: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    cUuid: PropTypes.string.isRequired,
    p: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default authRequired(
  WithYou,
  ['gf_plan_basic', 'gf_plan_premium', 'gf_plan_pro']
);
