import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class MaximumRepetitionsItem extends Component {
  constructor(props) {
    super(props);

    const {
      token,
      id,
      practice,
      value,
      current,
    } = props;

    this.state = {
      token,
      id,
      practice,
      value,
      current,
      realValue: value,
      send: false,
      result: '',
      resultIcon: '',
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  };

  clearAction = () => {
    setTimeout(() => {
      this.setState({
        send: false,
        result: '',
        resultIcon: '',
      });
    }, 2000);
  }

  handleClick = (event) => {
    event.preventDefault();

    this.setState({
      send: true,
      result: 'update',
      resultIcon: 'icon-rm-update',
    });

    const url = process.env.REACT_APP_URL_API;
    const {
      token,
      id,
      value,
      realValue,
    } = this.state;

    if (value >= 500) {
      this.setState({
        value: realValue,
        result: 'warning',
        resultIcon: 'icon-rm-warning',
      });
      this.clearAction();
      return;
    }

    let formData = new FormData();
    formData.append('value', value);

    let config = {
      method: 'post',
      url: `${url}/maximum-repetitions/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: formData,
    };

    axios(config)
      .then(response => {
        if (response.status === 200) {
          return response.data.body;
        }

        throw Error(response.statusText);
      }).then(() => {
        this.setState({
          realValue: value,
          result: 'success',
          resultIcon: 'icon-rm-success',
        });
        this.clearAction();
      }).catch(() => {
        this.setState({
          value: realValue,
          result: 'warning',
          resultIcon: 'icon-rm-warning',
        });
        this.clearAction();
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      practice,
      value,
      current,
      send,
      result,
      resultIcon,
    } = this.state;

    return (
      <div className='maximum-repetitions__item'>
        <div className='maximum-repetitions__item-practice'>
          <label> {practice} </label>
        </div>
        <input className='maximum-repetitions__item-value'
               name='value'
               type='text'
               onChange={this.handleChange}
               value={value}
               disabled={current !== true}
        />
        {
          current === true ?
            <button className='maximum-repetitions__item-action'
                    onClick={ this.handleClick }
                    disabled={ send }
            >
              <i className='icon-rm-save'>{''}</i>
            </button>
            :
            ''
        }
        {
          result !== '' ?
            <div className={`maximum-repetitions__result maximum-repetitions__result--${result}`}>
              {
                result === 'update' ?
                  <div className='loader'>{''}</div>
                  :
                  <i className={resultIcon}>{''}</i>
              }
            </div>
            :
            ''
        }
      </div>
    );
  }
}

MaximumRepetitionsItem.propTypes = {
  token: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  practice: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  current: PropTypes.bool.isRequired,
};

export default MaximumRepetitionsItem;
