import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';

class Modal extends Component {
  constructor(props) {
    super(props);

    const {
      handleCloseModal,
      handleSubmitSession
    } = props;

    this.state = {
      handleCloseModal,
      handleSubmitSession,
      modalDom: document.getElementById('app-modal'),
    };
  }

  handleClickClose = (event) => {
    event.preventDefault();

    const { handleCloseModal, modalDom } = this.state;

    const classList = modalDom.classList;
    if (classList.contains('app-modal')) {
      classList.remove('app-modal');
    }

    handleCloseModal();
  }

  handleClickSubmit = (event) => {
    event.preventDefault();

    const { handleCloseModal, modalDom, handleSubmitSession } = this.state;
    let rating = document.getElementById('rating').value;
    let comment = document.getElementById('comment').value;

    const classList = modalDom.classList;
    if (classList.contains('app-modal')) {
      classList.remove('app-modal');
    }

    handleCloseModal();
    handleSubmitSession(rating, comment);
  }

  render() {
    const { modalDom } = this.state;

    // Css assets
    const classList = modalDom.classList;
    classList.add('app-modal');

    return createPortal(
      <div className='app-modal__content'>
        <div className='app-modal__content-close' onClick={this.handleClickClose}>
          <i className='icon-plus-full'>{''}</i>
        </div>
        <div className='app-modal__content-text'>
          <p className='h2'>Feedback</p>
          <p className='text'>
            ¿Cómo de exigente te ha resultado esta sesión de entrenamiento?. Donde 10 es muy exigente y 0 poco exigente.
          </p>
        </div>
        <div className='app-modal__content-form'>
          <form onSubmit={this.handleClickSubmit}>
            <div className='range-slider'>
              <div className='range-slider__text'>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
                <span>7</span>
                <span>8</span>
                <span>9</span>
                <span>10</span>
              </div>
              <input id='rating'
                     type='range'
                     min='1'
                     max='10'
                     step='1'
                     defaultValue='5'
              />
            </div>
            <textarea id='comment'
                      placeholder='Indica cualquier cosa que consideres relevante'
            />
            <button className='btn' type='submit'>Enviar</button>
          </form>
        </div>
      </div>, modalDom);
  }
}

Modal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmitSession: PropTypes.func.isRequired,
};

export default Modal;
